import { QuantityInpuStyle, QuantityInputProps } from "./QuantityInput.types";
import css from "./QuantityInput.module.scss";
import classNames from "@fitneks-commons/classnames";
import { SyntheticEvent } from "react";

/**
 * Quantity input component
 *
 * @param {QuantityInputProps} props Component props
 * @param {number} props.value - increased/decreased value
 * @param {number} props.step - each increased/decreased value count
 * @param {Style} props.style - button style
 * @param {function} props.onChange - function for adding or subtracting the value
 * @param {boolean} props.disabled - for disabling click of increment/decrement function
 *
 */

const QuantityInput = (props: QuantityInputProps) => {
    const style: QuantityInpuStyle = props.style || "inline";
    const value = props.value || 0;
    const step = props.step || 1;
    const color = props.color || "light";

    const disableClick = (event: SyntheticEvent) => {
        event.preventDefault();
    };

    return (
        <div
            className={classNames(
                props.className,
                css["quantity-wrapper"],
                css[style],
                css[color],
                props.disabled && css["disabled"]
            )}
            role="quantityinput"
            ref={props.htmlRef}
        >
            <div
                className={classNames(css["increase"])}
                onClick={(e) => {
                    props.disabled === true ? disableClick(e) : props.onChange(value + step, true);
                }}
                role="button"
                aria-label="decrease button"
            ></div>

            <input
                value={value}
                className={classNames(css["input"])}
                readOnly
                aria-label="increased decreased input"
                name="quantity"
            />
            <div
                className={classNames(css["decrease"])}
                onClick={(e) => {
                    props.disabled === true ? disableClick(e) : props.onChange(value - step, false);
                }}
                role="button"
                aria-label="decrease button"
            ></div>
        </div>
    );
};

export default QuantityInput;
