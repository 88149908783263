import { DateRange, getStartOfMonth, getStartOfWeek } from "@fitneks-component-library";

export const currentTimezone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone ?? "America/Toronto";
};

export const defaultUTCToTimezone = (date: string, timezone: string | null, options?: Intl.DateTimeFormatOptions) => {
    const timeZone = timezone ?? currentTimezone();
    return new Date(date).toLocaleString("en-US", { timeZone: timeZone, ...options });
};
export const getUTCOffset = (timezone: string | null) => {
    const timeZone = timezone ?? currentTimezone();
    const date = new Date().toLocaleString("en-US", { timeZone: timeZone, timeZoneName: "shortOffset" });
    const offset = date.match(/GMT.*/);
    if (offset && offset[0]) {
        return offset[0].length > 3 ? offset[0] : `${offset[0]}+0`;
    }
    // HST, HAST timezones
    return "GMT-10";
};

export const utcOffsetInSeconds = (timeZone: string) => {
    const offset = getUTCOffset(timeZone).replace("GMT", "");
    const split = offset.split(":");
    let seconds = Number(split[0]) * 60 * 60;
    if (split[1]) {
        seconds = seconds > 0 ? seconds + Number(split[1]) * 60 : seconds - Number(split[1]) * 60;
    }
    return seconds;
};

export function getDateAndTimeParts(date: string, timezone: string | null) {
    const timeZone = timezone ?? currentTimezone();
    const options = {
        year: "numeric",
        month: "short",
        weekday: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    } as Intl.DateTimeFormatOptions;
    let datetime = defaultUTCToTimezone(date, timeZone, options);
    datetime = datetime.replace(" at ", ", ");
    const datetimeArray = datetime.split(",");
    const timePart = datetimeArray[datetimeArray.length - 1] || "";
    const datePart = isDateInThisWeek(new Date(datetime))
        ? `This ${datetimeArray[0]}`
        : datetimeArray.slice(1, 3).join().trim();
    return { datePart, timePart };
}

export function isDateInThisWeek(date: Date) {
    const todayObj = new Date();
    const todayDate = todayObj.getDate();
    const todayDay = todayObj.getDay();

    // get first date of week
    const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay));

    // get last date of week
    const lastDayOfWeek = new Date(firstDayOfWeek);
    lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

    // if date is equal or within the first and last dates of the week
    return date >= firstDayOfWeek && date <= lastDayOfWeek;
}

export const datesDiff = (startDate: Date, endDate: Date) => {
    const ms = endDate.getTime() - startDate.getTime();
    const diff = convertMsToTime(ms);
    return diff.trim();
};

export function convertMsToTime(milliseconds: number) {
    const days = Math.floor(milliseconds / (24 * 60 * 60 * 1000));
    const daysMs = milliseconds % (24 * 60 * 60 * 1000);
    const hours = Math.floor(daysMs / (60 * 60 * 1000));
    const hoursMs = milliseconds % (60 * 60 * 1000);
    const minutes = Math.floor(hoursMs / (60 * 1000));

    let diff = "";
    days > 0 && (diff += `${days} day${days > 1 ? "s" : ""}`);
    hours > 0 && (diff += ` ${hours} hour${hours > 1 ? "s" : ""}`);
    minutes > 0 && (diff += ` ${minutes} min`);

    if (!diff) {
        const seconds = Math.floor(milliseconds / 1000);
        diff = `${seconds} seconds`;
    }

    return diff;
}

export const getLastWeekRange = () => {
    const weekEnd = new Date();
    const weekStart = getStartOfWeek(weekEnd);
    weekStart.setHours(23, 59, 59, 0);
    return [
        {
            after: weekStart.toLocaleString("en-US"),
            before: weekEnd.toLocaleString("en-US"),
        },
    ];
};

export const getLastMonthRange = () => {
    const monthEnd = new Date();
    monthEnd.setHours(23, 59, 59, 0);
    const monthStart = getStartOfMonth(monthEnd);
    return [
        {
            after: monthStart.toLocaleString("en-US"),
            before: monthEnd.toLocaleString("en-US"),
        },
    ];
};

export const formatDateRange = (date: DateRange) => {
    return [
        {
            after: date.startDate.toLocaleString("en-US"),
            before: date.endDate.toLocaleString("en-US"),
        },
    ];
};

export function getPartsInMonth(month: number, year: number) {
    const parts = [],
        lastDate = new Date(year, month, 0), // Subtract 1 here
        numDays = lastDate.getDate();

    const partLength = Math.floor(numDays / 4);
    const remainder = numDays % 4;

    for (let i = 0; i < 4; i++) {
        const start = i * partLength + 1;
        let end = (i + 1) * partLength;

        if (i == 3) {
            // the last part will include the remainder days
            end += remainder;
        }

        const endDate = new Date(year, month - 1, end); // Subtract 1 here
        endDate.setHours(23, 59, 59);

        parts.push({
            start: new Date(year, month - 1, start), // Subtract 1 here
            end: endDate,
        });
    }

    return parts;
}

export function timezoneToDefaultUTC(date: Date, timezone: string | null) {
    const timeZone = timezone ?? currentTimezone();
    const stringOffset = getUTCOffset(timeZone);
    const newDate = new Date(date);
    const offset = +stringOffset.replace("GMT", "");
    newDate.setHours(newDate.getHours() - offset);
    return newDate;
}

export const getDefaultTimezoneName = () => "Africa/Accra";
