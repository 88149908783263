import { DeviceType, MediaQuery } from "@fitneks-commons/checkMatchMedia/types";

/**
 * Helper function to test predefined match queries against current device
 * @param {DeviceType} deviceType Device type to test
 *
 * @return boolean
 */
export function checkMatchMedia(deviceType: DeviceType): boolean {
    let mediaQuery: MediaQuery | null;

    switch (deviceType) {
        case "mobile":
            mediaQuery = MediaQuery.Mobile;
            break;
        case "tablet":
            mediaQuery = MediaQuery.Tablet;
            break;
        case "desktop":
            mediaQuery = MediaQuery.Desktop;
            break;
        case "other":
            mediaQuery = MediaQuery.NotDesktop;
            break;
        case "all":
            mediaQuery = null;
            break;
    }

    if (mediaQuery === null) return true;

    return window.matchMedia(mediaQuery).matches;
}
