/* eslint-disable */
/* tslint:disable */
/* THIS IS A GENERATED FILE, DO NOT EDIT. */
import * as Types from '@fitneks-types/Graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UsersConnectionQueryVariables = Types.Exact<{
  input: Types.Scalars['String'];
}>;


export type UsersConnectionQuery = { readonly withUsernameUser?: { readonly id: string, readonly username: string, readonly displayName: string, readonly gamificationPointsCount: number, readonly OAuthAvatar?: string | null, readonly currentUserIsFollower: boolean, readonly avatarMediaObject?: { readonly id: string, readonly filePath?: string | null } | null } | null };

export type GetConnectionInfoQueryVariables = Types.Exact<{
  user: Types.Scalars['String'];
  followingUser: Types.Scalars['String'];
}>;


export type GetConnectionInfoQuery = { readonly userConnections?: { readonly edges?: ReadonlyArray<{ readonly node?: { readonly id: string } | null } | null> | null } | null };

export type SubscribeMutationVariables = Types.Exact<{
  input: Types.CreateUserConnectionInput;
}>;


export type SubscribeMutation = { readonly createUserConnection?: { readonly userConnection?: { readonly id: string } | null } | null };

export type UnsubscribeMutationVariables = Types.Exact<{
  input: Types.DeleteUserConnectionInput;
}>;


export type UnsubscribeMutation = { readonly deleteUserConnection?: { readonly userConnection?: { readonly id: string } | null } | null };


export const UsersConnectionDocument = gql`
    query UsersConnection($input: String!) {
  withUsernameUser(username: $input) {
    id
    username
    displayName
    gamificationPointsCount
    avatarMediaObject {
      id
      filePath
    }
    OAuthAvatar
    currentUserIsFollower
  }
}
    `;

/**
 * __useUsersConnectionQuery__
 *
 * To run a query within a React component, call `useUsersConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersConnectionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUsersConnectionQuery(baseOptions: Apollo.QueryHookOptions<UsersConnectionQuery, UsersConnectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersConnectionQuery, UsersConnectionQueryVariables>(UsersConnectionDocument, options);
      }
export function useUsersConnectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersConnectionQuery, UsersConnectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersConnectionQuery, UsersConnectionQueryVariables>(UsersConnectionDocument, options);
        }
export type UsersConnectionQueryHookResult = ReturnType<typeof useUsersConnectionQuery>;
export type UsersConnectionLazyQueryHookResult = ReturnType<typeof useUsersConnectionLazyQuery>;
export type UsersConnectionQueryResult = Apollo.QueryResult<UsersConnectionQuery, UsersConnectionQueryVariables>;
export const GetConnectionInfoDocument = gql`
    query GetConnectionInfo($user: String!, $followingUser: String!) {
  userConnections(user: $user, followingUser: $followingUser) {
    edges {
      node {
        id
      }
    }
  }
}
    `;

/**
 * __useGetConnectionInfoQuery__
 *
 * To run a query within a React component, call `useGetConnectionInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConnectionInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConnectionInfoQuery({
 *   variables: {
 *      user: // value for 'user'
 *      followingUser: // value for 'followingUser'
 *   },
 * });
 */
export function useGetConnectionInfoQuery(baseOptions: Apollo.QueryHookOptions<GetConnectionInfoQuery, GetConnectionInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConnectionInfoQuery, GetConnectionInfoQueryVariables>(GetConnectionInfoDocument, options);
      }
export function useGetConnectionInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConnectionInfoQuery, GetConnectionInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConnectionInfoQuery, GetConnectionInfoQueryVariables>(GetConnectionInfoDocument, options);
        }
export type GetConnectionInfoQueryHookResult = ReturnType<typeof useGetConnectionInfoQuery>;
export type GetConnectionInfoLazyQueryHookResult = ReturnType<typeof useGetConnectionInfoLazyQuery>;
export type GetConnectionInfoQueryResult = Apollo.QueryResult<GetConnectionInfoQuery, GetConnectionInfoQueryVariables>;
export const SubscribeDocument = gql`
    mutation subscribe($input: createUserConnectionInput!) {
  createUserConnection(input: $input) {
    userConnection {
      id
    }
  }
}
    `;
export type SubscribeMutationFn = Apollo.MutationFunction<SubscribeMutation, SubscribeMutationVariables>;

/**
 * __useSubscribeMutation__
 *
 * To run a mutation, you first call `useSubscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeMutation, { data, loading, error }] = useSubscribeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubscribeMutation(baseOptions?: Apollo.MutationHookOptions<SubscribeMutation, SubscribeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubscribeMutation, SubscribeMutationVariables>(SubscribeDocument, options);
      }
export type SubscribeMutationHookResult = ReturnType<typeof useSubscribeMutation>;
export type SubscribeMutationResult = Apollo.MutationResult<SubscribeMutation>;
export type SubscribeMutationOptions = Apollo.BaseMutationOptions<SubscribeMutation, SubscribeMutationVariables>;
export const UnsubscribeDocument = gql`
    mutation unsubscribe($input: deleteUserConnectionInput!) {
  deleteUserConnection(input: $input) {
    userConnection {
      id
    }
  }
}
    `;
export type UnsubscribeMutationFn = Apollo.MutationFunction<UnsubscribeMutation, UnsubscribeMutationVariables>;

/**
 * __useUnsubscribeMutation__
 *
 * To run a mutation, you first call `useUnsubscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeMutation, { data, loading, error }] = useUnsubscribeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnsubscribeMutation(baseOptions?: Apollo.MutationHookOptions<UnsubscribeMutation, UnsubscribeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnsubscribeMutation, UnsubscribeMutationVariables>(UnsubscribeDocument, options);
      }
export type UnsubscribeMutationHookResult = ReturnType<typeof useUnsubscribeMutation>;
export type UnsubscribeMutationResult = Apollo.MutationResult<UnsubscribeMutation>;
export type UnsubscribeMutationOptions = Apollo.BaseMutationOptions<UnsubscribeMutation, UnsubscribeMutationVariables>;