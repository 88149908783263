import { useContext, useEffect, useRef } from "react";
import { useLocation } from "react-router";
import { ComponentWithChildren } from "@fitneks-types/ComponentWithChildren";
import AppRoutes from "@fitneks-routes";
import { ThemeContext } from "@fitneks-providers";
import { useAuth } from "@fitneks-commons/hooks";
import { useNavigate } from "react-router-dom";
import { StreamInfoInput } from "@fitneks-pages/VideoStream/VideoStream.types";
import { getLocalStorageItem, removeLocalStorageItem } from "@fitneks-commons/helpers/localstorage";
import { useSaveStreamInfoMutation } from "@fitneks-pages/VideoStream/pages/Learner/LearnerViewStream.generated";
import { useOnStreamLeave } from "@fitneks-pages/VideoStream/pages/Learner/helpers";
import { isLearner } from "@fitneks-commons/helpers/functions/UserRoles";

export default function OnRouteChange(props: ComponentWithChildren) {
    const navigate = useNavigate();
    const location = useLocation();
    const prevLocation = useRef(location);
    const { resetBodyClass } = useContext(ThemeContext);
    const { accessToken, username, onLogout, role, isAuthorized } = useAuth();
    const { onStreamLeave } = useOnStreamLeave();

    const [saveStreamInfo] = useSaveStreamInfoMutation();

    useEffect(() => {
        if (username && !accessToken) {
            localStorage.clear();
            onLogout();
            navigate(AppRoutes.login.route(), { replace: true });
        }

        // On page change scroll to top if not dashboard
        if (
            !location.pathname.includes(AppRoutes.dashboard.path) ||
            !prevLocation.current.pathname.includes(AppRoutes.dashboard.path)
        ) {
            window.scrollTo(0, 0);
        }

        // Remove className from body if not dashboard
        if (!location.pathname.includes(AppRoutes.dashboard.path)) {
            resetBodyClass("page-dashboard");
        }

        // Refresh page to off media devices
        if (
            isAuthorized &&
            location.pathname !== prevLocation.current.pathname &&
            (prevLocation.current.pathname.includes(AppRoutes.doingStream.route()) ||
                prevLocation.current.pathname.includes("/trainer/"))
        ) {
            window.location.href = `${import.meta.env.FITNEKS_APP_URL}${location.pathname}`;
        }

        // LU Stream leave cleanup
        if (isLearner(role) && !location.pathname.includes("/trainer")) {
            for (const key in localStorage) {
                if (key.startsWith("st_")) {
                    const guid = key.replace("st_", "");
                    const STREAM_START = `ss_${guid}`;
                    const QUESTIONS_COUNT = `qc_${guid}`;
                    const input: StreamInfoInput = { streamGuid: guid };
                    const streamStart = getLocalStorageItem(STREAM_START);
                    if (streamStart) {
                        const now = new Date().getTime();
                        input.streamDuration = now - Number(streamStart);
                        removeLocalStorageItem(STREAM_START);
                    }
                    const questionsCount = getLocalStorageItem(QUESTIONS_COUNT);
                    if (questionsCount) {
                        input.questionsCount = Number(questionsCount);
                        removeLocalStorageItem(QUESTIONS_COUNT);
                    }
                    saveStreamInfo({ variables: { input } });
                    onStreamLeave(guid);
                }
            }
        }

        // Save prev location for next render
        prevLocation.current = location;
    }, [location]);

    return <>{props.children}</>;
}
