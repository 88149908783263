/* eslint-disable */
/* tslint:disable */
/* THIS IS A GENERATED FILE, DO NOT EDIT. */
import * as Types from '@fitneks-types/Graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUserProfileQueryVariables = Types.Exact<{
  input: Types.Scalars['String'];
  userID: Types.Scalars['String'];
}>;


export type GetUserProfileQuery = { readonly withUsernameUser?: { readonly id: string, readonly guid: string, readonly username: string, readonly displayName: string, readonly firstName: string, readonly lastName: string, readonly roles: any, readonly about?: string | null, readonly gamificationPointsCount: number, readonly isPublic: boolean, readonly hasLiveStream: boolean, readonly createdAt?: string | null, readonly currentUserIsFollower: boolean, readonly currentUserIsFollowing: boolean, readonly OAuthAvatar?: string | null, readonly gamificationLevel?: { readonly id: string, readonly name: string } | null, readonly avatarMediaObject?: { readonly id: string, readonly filePath?: string | null } | null, readonly userFollowersConnections?: { readonly edges?: ReadonlyArray<{ readonly node?: { readonly id: string, readonly user?: { readonly id: string } | null } | null } | null> | null } | null } | null };

export type GetUserVideoStreamsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  user?: Types.InputMaybe<Types.Scalars['String']>;
  viewer?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetUserVideoStreamsQuery = { readonly videoStreams?: { readonly edges?: ReadonlyArray<{ readonly node?: { readonly id: string, readonly videoStreamType?: { readonly id: string, readonly name: string } | null } | null } | null> | null, readonly pageInfo: { readonly hasNextPage: boolean, readonly endCursor?: string | null } } | null };

export type GetUserPrivateProfileQueryVariables = Types.Exact<{
  input: Types.Scalars['ID'];
}>;


export type GetUserPrivateProfileQuery = { readonly user?: { readonly id: string, readonly trainingTypes?: { readonly edges?: ReadonlyArray<{ readonly node?: { readonly id: string, readonly name: string } | null } | null> | null } | null } | null };

export type AddUserConnectionMutationVariables = Types.Exact<{
  input: Types.CreateUserConnectionInput;
}>;


export type AddUserConnectionMutation = { readonly createUserConnection?: { readonly userConnection?: { readonly id: string } | null } | null };

export type RemoveUserConnectionMutationVariables = Types.Exact<{
  input: Types.DeleteUserConnectionInput;
}>;


export type RemoveUserConnectionMutation = { readonly deleteUserConnection?: { readonly userConnection?: { readonly id: string } | null } | null };


export const GetUserProfileDocument = gql`
    query getUserProfile($input: String!, $userID: String!) {
  withUsernameUser(username: $input) {
    id
    guid
    username
    displayName
    firstName
    lastName
    roles
    about
    gamificationPointsCount
    isPublic
    hasLiveStream
    createdAt
    gamificationLevel {
      id
      name
    }
    currentUserIsFollower
    currentUserIsFollowing
    avatarMediaObject {
      id
      filePath
    }
    OAuthAvatar
    userFollowersConnections(user: $userID) {
      edges {
        node {
          id
          user {
            id
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetUserProfileQuery__
 *
 * To run a query within a React component, call `useGetUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfileQuery({
 *   variables: {
 *      input: // value for 'input'
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useGetUserProfileQuery(baseOptions: Apollo.QueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(GetUserProfileDocument, options);
      }
export function useGetUserProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(GetUserProfileDocument, options);
        }
export type GetUserProfileQueryHookResult = ReturnType<typeof useGetUserProfileQuery>;
export type GetUserProfileLazyQueryHookResult = ReturnType<typeof useGetUserProfileLazyQuery>;
export type GetUserProfileQueryResult = Apollo.QueryResult<GetUserProfileQuery, GetUserProfileQueryVariables>;
export const GetUserVideoStreamsDocument = gql`
    query GetUserVideoStreams($first: Int, $after: String, $user: String, $viewer: String) {
  videoStreams(
    first: $first
    after: $after
    user: $user
    videoStreamViewers_user: $viewer
    isLive: false
    isUpcoming: false
  ) {
    edges {
      node {
        id
        videoStreamType {
          id
          name
        }
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    `;

/**
 * __useGetUserVideoStreamsQuery__
 *
 * To run a query within a React component, call `useGetUserVideoStreamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserVideoStreamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserVideoStreamsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      user: // value for 'user'
 *      viewer: // value for 'viewer'
 *   },
 * });
 */
export function useGetUserVideoStreamsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserVideoStreamsQuery, GetUserVideoStreamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserVideoStreamsQuery, GetUserVideoStreamsQueryVariables>(GetUserVideoStreamsDocument, options);
      }
export function useGetUserVideoStreamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserVideoStreamsQuery, GetUserVideoStreamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserVideoStreamsQuery, GetUserVideoStreamsQueryVariables>(GetUserVideoStreamsDocument, options);
        }
export type GetUserVideoStreamsQueryHookResult = ReturnType<typeof useGetUserVideoStreamsQuery>;
export type GetUserVideoStreamsLazyQueryHookResult = ReturnType<typeof useGetUserVideoStreamsLazyQuery>;
export type GetUserVideoStreamsQueryResult = Apollo.QueryResult<GetUserVideoStreamsQuery, GetUserVideoStreamsQueryVariables>;
export const GetUserPrivateProfileDocument = gql`
    query getUserPrivateProfile($input: ID!) {
  user(id: $input) {
    id
    trainingTypes {
      edges {
        node {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetUserPrivateProfileQuery__
 *
 * To run a query within a React component, call `useGetUserPrivateProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPrivateProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPrivateProfileQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUserPrivateProfileQuery(baseOptions: Apollo.QueryHookOptions<GetUserPrivateProfileQuery, GetUserPrivateProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserPrivateProfileQuery, GetUserPrivateProfileQueryVariables>(GetUserPrivateProfileDocument, options);
      }
export function useGetUserPrivateProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserPrivateProfileQuery, GetUserPrivateProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserPrivateProfileQuery, GetUserPrivateProfileQueryVariables>(GetUserPrivateProfileDocument, options);
        }
export type GetUserPrivateProfileQueryHookResult = ReturnType<typeof useGetUserPrivateProfileQuery>;
export type GetUserPrivateProfileLazyQueryHookResult = ReturnType<typeof useGetUserPrivateProfileLazyQuery>;
export type GetUserPrivateProfileQueryResult = Apollo.QueryResult<GetUserPrivateProfileQuery, GetUserPrivateProfileQueryVariables>;
export const AddUserConnectionDocument = gql`
    mutation addUserConnection($input: createUserConnectionInput!) {
  createUserConnection(input: $input) {
    userConnection {
      id
    }
  }
}
    `;
export type AddUserConnectionMutationFn = Apollo.MutationFunction<AddUserConnectionMutation, AddUserConnectionMutationVariables>;

/**
 * __useAddUserConnectionMutation__
 *
 * To run a mutation, you first call `useAddUserConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserConnectionMutation, { data, loading, error }] = useAddUserConnectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddUserConnectionMutation(baseOptions?: Apollo.MutationHookOptions<AddUserConnectionMutation, AddUserConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserConnectionMutation, AddUserConnectionMutationVariables>(AddUserConnectionDocument, options);
      }
export type AddUserConnectionMutationHookResult = ReturnType<typeof useAddUserConnectionMutation>;
export type AddUserConnectionMutationResult = Apollo.MutationResult<AddUserConnectionMutation>;
export type AddUserConnectionMutationOptions = Apollo.BaseMutationOptions<AddUserConnectionMutation, AddUserConnectionMutationVariables>;
export const RemoveUserConnectionDocument = gql`
    mutation removeUserConnection($input: deleteUserConnectionInput!) {
  deleteUserConnection(input: $input) {
    userConnection {
      id
    }
  }
}
    `;
export type RemoveUserConnectionMutationFn = Apollo.MutationFunction<RemoveUserConnectionMutation, RemoveUserConnectionMutationVariables>;

/**
 * __useRemoveUserConnectionMutation__
 *
 * To run a mutation, you first call `useRemoveUserConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserConnectionMutation, { data, loading, error }] = useRemoveUserConnectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveUserConnectionMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserConnectionMutation, RemoveUserConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUserConnectionMutation, RemoveUserConnectionMutationVariables>(RemoveUserConnectionDocument, options);
      }
export type RemoveUserConnectionMutationHookResult = ReturnType<typeof useRemoveUserConnectionMutation>;
export type RemoveUserConnectionMutationResult = Apollo.MutationResult<RemoveUserConnectionMutation>;
export type RemoveUserConnectionMutationOptions = Apollo.BaseMutationOptions<RemoveUserConnectionMutation, RemoveUserConnectionMutationVariables>;