/* eslint-disable */
/* tslint:disable */
/* THIS IS A GENERATED FILE, DO NOT EDIT. */
import * as Types from '@fitneks-types/Graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EventTrainingEquipmentsQueryVariables = Types.Exact<{
  first: Types.Scalars['Int'];
  videoStream: Types.Scalars['String'];
  after?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type EventTrainingEquipmentsQuery = { readonly trainingEquipments?: { readonly edges?: ReadonlyArray<{ readonly node?: { readonly id: string, readonly name: string, readonly iconMediaObject?: { readonly filePath?: string | null } | null } | null } | null> | null, readonly pageInfo: { readonly hasNextPage: boolean, readonly endCursor?: string | null } } | null };


export const EventTrainingEquipmentsDocument = gql`
    query EventTrainingEquipments($first: Int!, $videoStream: String!, $after: String) {
  trainingEquipments(
    first: $first
    after: $after
    videoStreams_guid: $videoStream
  ) {
    edges {
      node {
        id
        name
        iconMediaObject {
          filePath
        }
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    `;

/**
 * __useEventTrainingEquipmentsQuery__
 *
 * To run a query within a React component, call `useEventTrainingEquipmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventTrainingEquipmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventTrainingEquipmentsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      videoStream: // value for 'videoStream'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useEventTrainingEquipmentsQuery(baseOptions: Apollo.QueryHookOptions<EventTrainingEquipmentsQuery, EventTrainingEquipmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventTrainingEquipmentsQuery, EventTrainingEquipmentsQueryVariables>(EventTrainingEquipmentsDocument, options);
      }
export function useEventTrainingEquipmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventTrainingEquipmentsQuery, EventTrainingEquipmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventTrainingEquipmentsQuery, EventTrainingEquipmentsQueryVariables>(EventTrainingEquipmentsDocument, options);
        }
export type EventTrainingEquipmentsQueryHookResult = ReturnType<typeof useEventTrainingEquipmentsQuery>;
export type EventTrainingEquipmentsLazyQueryHookResult = ReturnType<typeof useEventTrainingEquipmentsLazyQuery>;
export type EventTrainingEquipmentsQueryResult = Apollo.QueryResult<EventTrainingEquipmentsQuery, EventTrainingEquipmentsQueryVariables>;