/* eslint-disable */
/* tslint:disable */
/* THIS IS A GENERATED FILE, DO NOT EDIT. */
import * as Types from '@fitneks-types/Graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetLiveKitTokenQueryVariables = Types.Exact<{
  streamGuid: Types.Scalars['String'];
  username: Types.Scalars['String'];
}>;


export type GetLiveKitTokenQuery = { readonly withStreamLiveKitToken?: { readonly id: string, readonly token: string } | null };


export const GetLiveKitTokenDocument = gql`
    query GetLiveKitToken($streamGuid: String!, $username: String!) {
  withStreamLiveKitToken(streamGuid: $streamGuid, username: $username) {
    id
    token
  }
}
    `;

/**
 * __useGetLiveKitTokenQuery__
 *
 * To run a query within a React component, call `useGetLiveKitTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLiveKitTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLiveKitTokenQuery({
 *   variables: {
 *      streamGuid: // value for 'streamGuid'
 *      username: // value for 'username'
 *   },
 * });
 */
export function useGetLiveKitTokenQuery(baseOptions: Apollo.QueryHookOptions<GetLiveKitTokenQuery, GetLiveKitTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLiveKitTokenQuery, GetLiveKitTokenQueryVariables>(GetLiveKitTokenDocument, options);
      }
export function useGetLiveKitTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLiveKitTokenQuery, GetLiveKitTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLiveKitTokenQuery, GetLiveKitTokenQueryVariables>(GetLiveKitTokenDocument, options);
        }
export type GetLiveKitTokenQueryHookResult = ReturnType<typeof useGetLiveKitTokenQuery>;
export type GetLiveKitTokenLazyQueryHookResult = ReturnType<typeof useGetLiveKitTokenLazyQuery>;
export type GetLiveKitTokenQueryResult = Apollo.QueryResult<GetLiveKitTokenQuery, GetLiveKitTokenQueryVariables>;