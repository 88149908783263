/* eslint-disable */
/* tslint:disable */
/* THIS IS A GENERATED FILE, DO NOT EDIT. */
import * as Types from '@fitneks-types/Graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetGamificationItemsColorsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetGamificationItemsColorsQuery = { readonly gamificationItems?: ReadonlyArray<{ readonly name: string, readonly color: string } | null> | null };


export const GetGamificationItemsColorsDocument = gql`
    query getGamificationItemsColors {
  gamificationItems {
    name
    color
  }
}
    `;

/**
 * __useGetGamificationItemsColorsQuery__
 *
 * To run a query within a React component, call `useGetGamificationItemsColorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGamificationItemsColorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGamificationItemsColorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGamificationItemsColorsQuery(baseOptions?: Apollo.QueryHookOptions<GetGamificationItemsColorsQuery, GetGamificationItemsColorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGamificationItemsColorsQuery, GetGamificationItemsColorsQueryVariables>(GetGamificationItemsColorsDocument, options);
      }
export function useGetGamificationItemsColorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGamificationItemsColorsQuery, GetGamificationItemsColorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGamificationItemsColorsQuery, GetGamificationItemsColorsQueryVariables>(GetGamificationItemsColorsDocument, options);
        }
export type GetGamificationItemsColorsQueryHookResult = ReturnType<typeof useGetGamificationItemsColorsQuery>;
export type GetGamificationItemsColorsLazyQueryHookResult = ReturnType<typeof useGetGamificationItemsColorsLazyQuery>;
export type GetGamificationItemsColorsQueryResult = Apollo.QueryResult<GetGamificationItemsColorsQuery, GetGamificationItemsColorsQueryVariables>;