import { MouseEvent } from "react";
import { BaseComponentProps } from "@fitneks-component-library";

export enum IconName {
    Arrows = "arrows",
    Bell = "bell",
    Bars = "bars",
    Microphone = "microphone",
    Bolt = "bolt",
    Calendar = "calendar",
    Comments = "comments",
    Calisthenics = "calisthenics",
    LVL = "lvl",
    Card = "card",
    Check = "check",
    Cross = "cross",
    Diamond = "diamond",
    Diamonds_3 = "diamonds_3",
    Diamonds_15 = "diamonds_15",
    Diamonds_30 = "diamonds_30",
    Exclamation = "exclamation",
    Info = "info",
    Eye = "eye",
    EyeSlash = "eye-slash",
    F = "F",
    Fitneks = "fitneks",
    FitneksLogo = "fitneks-logo",
    Gift = "gift",
    Help = "help",
    Kettlebell = "kettlebell",
    Logout = "logout",
    NoEquipment = "no-equipment",
    Photo = "photo",
    Plate = "plate",
    Protein = "protein",
    ProteinBar = "protein-bar",
    Pts = "pts",
    PullupBar = "pullup-bar",
    Resistance = "resistance",
    Search = "search",
    Settings = "settings",
    Shake = "shake",
    Share = "share",
    Share_2 = "share_2",
    Star = "star",
    SoundOff = "sound-off",
    SoundOn = "sound-on",
    Video = "video",
    Audio = "audio",
    User = "user",
    User2 = "user2",
    Users = "users",
    Weights = "weights",
    Yoga = "yoga",
    YogaBlock = "yoga-block",
    YogaMat = "yoga-mat",
    GoogleIcon = "google-icon",
    FacebookIcon = "facebook-icon",
    Cash = "cash-icon",
}

export interface IconProps extends BaseComponentProps<SVGSVGElement> {
    name: IconName;
    color?: string;
    width?: number;
    height?: number;
    onClick?: (event: MouseEvent<SVGSVGElement>) => void;
}
