import { TokenResponse } from "@fitneks-types/Graphql.generated";

/**
 * Calls API /auth/refresh endpoint with provided refresh_token
 *
 * @param {string} token Refresh token
 * @throws {Error} If request fails
 * @return Promise containing refreshed tokens
 */
export default async function refreshToken(token: string): Promise<TokenResponse> {
    const response = await fetch(`${import.meta.env.FITNEKS_API_URL}/auth/refresh`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ refresh_token: token }),
    });

    return await response.json();
}
