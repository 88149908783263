import { useContext } from "react";
import { ApolloProvider } from "@apollo/client";
import { AuthContext } from "@fitneks-providers";
import { ComponentWithChildren } from "@fitneks-commons/types/ComponentWithChildren";
import { createApolloClient } from "./client";

export const GraphQLProvider = ({ children }: ComponentWithChildren) => {
    const authState = useContext(AuthContext);
    const client = createApolloClient(authState);
    return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
