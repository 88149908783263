/* eslint-disable */
/* tslint:disable */
/* THIS IS A GENERATED FILE, DO NOT EDIT. */
import * as Types from '@fitneks-types/Graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetProfileStatusQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetProfileStatusQuery = { readonly user?: { readonly id: string, readonly hasLiveStream: boolean, readonly completedAt?: string | null, readonly profileBoosted: boolean, readonly notifyBoosted: boolean } | null };

export type GetUpcomingLiveStreamsQueryVariables = Types.Exact<{
  user: Types.Scalars['String'];
  startDatetime: ReadonlyArray<Types.InputMaybe<Types.VideoStreamFilter_StartDatetime>> | Types.InputMaybe<Types.VideoStreamFilter_StartDatetime>;
}>;


export type GetUpcomingLiveStreamsQuery = { readonly videoStreams?: { readonly edges?: ReadonlyArray<{ readonly node?: { readonly id: string, readonly guid: string } | null } | null> | null } | null };


export const GetProfileStatusDocument = gql`
    query GetProfileStatus($id: ID!) {
  user(id: $id) {
    id
    hasLiveStream
    completedAt
    profileBoosted
    notifyBoosted
  }
}
    `;

/**
 * __useGetProfileStatusQuery__
 *
 * To run a query within a React component, call `useGetProfileStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProfileStatusQuery(baseOptions: Apollo.QueryHookOptions<GetProfileStatusQuery, GetProfileStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProfileStatusQuery, GetProfileStatusQueryVariables>(GetProfileStatusDocument, options);
      }
export function useGetProfileStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfileStatusQuery, GetProfileStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProfileStatusQuery, GetProfileStatusQueryVariables>(GetProfileStatusDocument, options);
        }
export type GetProfileStatusQueryHookResult = ReturnType<typeof useGetProfileStatusQuery>;
export type GetProfileStatusLazyQueryHookResult = ReturnType<typeof useGetProfileStatusLazyQuery>;
export type GetProfileStatusQueryResult = Apollo.QueryResult<GetProfileStatusQuery, GetProfileStatusQueryVariables>;
export const GetUpcomingLiveStreamsDocument = gql`
    query GetUpcomingLiveStreams($user: String!, $startDatetime: [VideoStreamFilter_startDatetime]!) {
  videoStreams(
    user: $user
    startDatetime: $startDatetime
    isUpcoming: true
    first: 1
  ) {
    edges {
      node {
        id
        guid
      }
    }
  }
}
    `;

/**
 * __useGetUpcomingLiveStreamsQuery__
 *
 * To run a query within a React component, call `useGetUpcomingLiveStreamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpcomingLiveStreamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpcomingLiveStreamsQuery({
 *   variables: {
 *      user: // value for 'user'
 *      startDatetime: // value for 'startDatetime'
 *   },
 * });
 */
export function useGetUpcomingLiveStreamsQuery(baseOptions: Apollo.QueryHookOptions<GetUpcomingLiveStreamsQuery, GetUpcomingLiveStreamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUpcomingLiveStreamsQuery, GetUpcomingLiveStreamsQueryVariables>(GetUpcomingLiveStreamsDocument, options);
      }
export function useGetUpcomingLiveStreamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUpcomingLiveStreamsQuery, GetUpcomingLiveStreamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUpcomingLiveStreamsQuery, GetUpcomingLiveStreamsQueryVariables>(GetUpcomingLiveStreamsDocument, options);
        }
export type GetUpcomingLiveStreamsQueryHookResult = ReturnType<typeof useGetUpcomingLiveStreamsQuery>;
export type GetUpcomingLiveStreamsLazyQueryHookResult = ReturnType<typeof useGetUpcomingLiveStreamsLazyQuery>;
export type GetUpcomingLiveStreamsQueryResult = Apollo.QueryResult<GetUpcomingLiveStreamsQuery, GetUpcomingLiveStreamsQueryVariables>;