import css from "./BottomBar.module.scss";
import { Col, Container, Icon, IconName, Modal, Row, Typography } from "@fitneks-component-library";
import classNames from "@fitneks-commons/classnames";
import "@fitneks-commons/styles/display.scss";
import "@fitneks-commons/styles/position.scss";
import { useAuth } from "@fitneks-commons/hooks";
import { Link, useNavigate } from "react-router-dom";
import AppRoutes from "@fitneks-routes";
import { isLearner, isTrainer } from "@fitneks-commons/helpers/functions/UserRoles";
import { useState } from "react";
import { GoLiveBtn, NotificationIcon, SearchForStreams } from "@fitneks-commons/ui";

export default function BottomBar() {
    const { isAuthorized, role } = useAuth();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Container tag={"footer"} bg={"#353C3A"} color={"#fff"} className={classNames(css["footer"], "px-3 py-3")}>
            <Row>
                {isLearner(role) && (
                    <>
                        <Col className={"align-items-center t-center"}>
                            <Link
                                className={classNames(css["bottom-bar-link"], "t-inherit t-underline-none fx-hover")}
                                to={AppRoutes.index.route()}
                            >
                                <Icon name={IconName.F} width={25} height={25} className={"mb-1"} />
                                <Typography align={"center"} size={0.7} className={"mb-0"}>
                                    Live
                                </Typography>
                            </Link>
                        </Col>
                        <Col className={"align-items-center t-center"}>
                            <div className={"cursor-pointer fx-hover"} onClick={() => setIsOpen(true)}>
                                <Icon name={IconName.Search} width={25} height={25} className={"mb-1"} />
                                <Typography align={"center"} size={0.7} className={"mb-0"}>
                                    Search
                                </Typography>
                            </div>
                            <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} title={"Search Modal"}>
                                <Container className={"t-base px-4 py-4"}>
                                    <Typography tag={"h3"} align={"center"} className={"mb-4"} size={1.3}>
                                        Search for Live Sessions
                                    </Typography>
                                    <SearchForStreams onSubmit={() => setIsOpen(false)} />
                                </Container>
                            </Modal>
                        </Col>
                    </>
                )}
                <Col className={"align-items-center t-center"}>
                    {isAuthorized ? (
                        <NotificationIcon />
                    ) : (
                        <>
                            <Icon
                                name={IconName.Bell}
                                width={25}
                                height={25}
                                className={"mb-1 cursor-pointer"}
                                onClick={() => navigate(AppRoutes.login.route())}
                            />
                            <Typography align={"center"} size={0.7} className={"mb-0 mt-1"}>
                                Inbox
                            </Typography>
                        </>
                    )}
                </Col>
                {!isAuthorized && (
                    <Col xs={4} className={"align-items-center t-center"}>
                        <div className={"cursor-pointer fx-hover"} onClick={() => setIsOpen(true)}>
                            <Icon name={IconName.Search} width={25} height={25} className={"mb-1"} />
                            <Typography align={"center"} size={0.7} className={"mb-0"}>
                                Search
                            </Typography>
                        </div>
                        <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} title={"Search Modal"}>
                            <Container className={"t-base px-4 py-4"}>
                                <Typography tag={"h3"} align={"center"} className={"mb-4"} size={1.3}>
                                    Search for Live Sessions
                                </Typography>
                                <SearchForStreams onSubmit={() => setIsOpen(false)} />
                            </Container>
                        </Modal>
                    </Col>
                )}
                {isTrainer(role) && (
                    <Col xs={6} className={"align-items-center t-center"}>
                        {/*TODO must be replaced*/}
                        <div className={classNames(css["trainer-btn"], "mx-auto")}>
                            <GoLiveBtn position={"top"} />
                        </div>
                    </Col>
                )}
                <Col className={"align-items-center t-center"}>
                    <Link
                        className={classNames(css["bottom-bar-link"], "t-inherit t-underline-none fx-hover")}
                        to={isAuthorized ? AppRoutes.dashboard.children.myProfile.route() : AppRoutes.login.route()}
                    >
                        <Icon name={IconName.User} width={22} height={25} className={"mb-1"} />
                        <Typography align={"center"} size={0.7} className={"mb-0"}>
                            Profile
                        </Typography>
                    </Link>
                </Col>
            </Row>
        </Container>
    );
}
