const StreamEventTypes = Object.freeze({
    REMOVE_USER: "ft-remove-user",
    JOIN_STREAM_REQUEST: "ft-join-stream-request",
    GRADE_LEARNER_REQUEST: "ft-grade-learner-request",
    REMOVE_FROM_WAIT_LIST: "ft-remove-from-wait-list",
    LEARNER_GRADED: "ft-learner-graded",
    TOGGLE_PARTICIPANT: "ft-toggle-participant",
    GIFT_SEND: "ft-gift-send",
    STREAM_END: "ft-stream-end",
    LEARNER_SUBSCRIBE: "ft-learner-subscribe",
} as const);

export default StreamEventTypes;
