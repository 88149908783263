import UserRoles from "@fitneks-commons/constants/UserRoles";

export const isLearner = (role: string | null) => {
    if (role === null) {
        return false;
    }
    return UserRoles.learner === role;
};

export const isTrainer = (role: string | null) => {
    if (role === null) {
        return false;
    }
    return UserRoles.trainer === role;
};
