/* eslint-disable */
/* tslint:disable */
/* THIS IS A GENERATED FILE, DO NOT EDIT. */
import * as Types from '@fitneks-types/Graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FriendsListQueryVariables = Types.Exact<{
  input: Types.Scalars['String'];
  first: Types.Scalars['Int'];
  after?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type FriendsListQuery = { readonly userConnections?: { readonly edges?: ReadonlyArray<{ readonly node?: { readonly id: string, readonly user?: { readonly id: string, readonly username: string, readonly firstName: string, readonly lastName: string, readonly displayName: string, readonly gamificationPointsCount: number, readonly OAuthAvatar?: string | null, readonly avatarMediaObject?: { readonly id: string, readonly filePath?: string | null } | null } | null, readonly followingUser?: { readonly id: string, readonly username: string, readonly firstName: string, readonly lastName: string, readonly displayName: string, readonly gamificationPointsCount: number, readonly OAuthAvatar?: string | null, readonly avatarMediaObject?: { readonly id: string, readonly filePath?: string | null } | null } | null } | null } | null> | null, readonly pageInfo: { readonly endCursor?: string | null, readonly hasNextPage: boolean } } | null };

export type InviteFriendsMutationVariables = Types.Exact<{
  input: Types.InviteFriendsUserInput;
}>;


export type InviteFriendsMutation = { readonly inviteFriendsUser?: { readonly user?: { readonly id: string } | null } | null };


export const FriendsListDocument = gql`
    query friendsList($input: String!, $first: Int!, $after: String) {
  userConnections(
    followingUser: $input
    first: $first
    after: $after
    exists: {mutualConnection: true}
  ) {
    edges {
      node {
        id
        user {
          id
          username
          firstName
          lastName
          displayName
          gamificationPointsCount
          avatarMediaObject {
            id
            filePath
          }
          OAuthAvatar
        }
        followingUser {
          id
          username
          firstName
          lastName
          displayName
          gamificationPointsCount
          avatarMediaObject {
            id
            filePath
          }
          OAuthAvatar
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;

/**
 * __useFriendsListQuery__
 *
 * To run a query within a React component, call `useFriendsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFriendsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFriendsListQuery({
 *   variables: {
 *      input: // value for 'input'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useFriendsListQuery(baseOptions: Apollo.QueryHookOptions<FriendsListQuery, FriendsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FriendsListQuery, FriendsListQueryVariables>(FriendsListDocument, options);
      }
export function useFriendsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FriendsListQuery, FriendsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FriendsListQuery, FriendsListQueryVariables>(FriendsListDocument, options);
        }
export type FriendsListQueryHookResult = ReturnType<typeof useFriendsListQuery>;
export type FriendsListLazyQueryHookResult = ReturnType<typeof useFriendsListLazyQuery>;
export type FriendsListQueryResult = Apollo.QueryResult<FriendsListQuery, FriendsListQueryVariables>;
export const InviteFriendsDocument = gql`
    mutation InviteFriends($input: inviteFriendsUserInput!) {
  inviteFriendsUser(input: $input) {
    user {
      id
    }
  }
}
    `;
export type InviteFriendsMutationFn = Apollo.MutationFunction<InviteFriendsMutation, InviteFriendsMutationVariables>;

/**
 * __useInviteFriendsMutation__
 *
 * To run a mutation, you first call `useInviteFriendsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteFriendsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteFriendsMutation, { data, loading, error }] = useInviteFriendsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteFriendsMutation(baseOptions?: Apollo.MutationHookOptions<InviteFriendsMutation, InviteFriendsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteFriendsMutation, InviteFriendsMutationVariables>(InviteFriendsDocument, options);
      }
export type InviteFriendsMutationHookResult = ReturnType<typeof useInviteFriendsMutation>;
export type InviteFriendsMutationResult = Apollo.MutationResult<InviteFriendsMutation>;
export type InviteFriendsMutationOptions = Apollo.BaseMutationOptions<InviteFriendsMutation, InviteFriendsMutationVariables>;