/* eslint-disable */
/* tslint:disable */
/* THIS IS A GENERATED FILE, DO NOT EDIT. */
import * as Types from '@fitneks-types/Graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUserConnectionsQueryVariables = Types.Exact<{
  user: Types.Scalars['String'];
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetUserConnectionsQuery = { readonly userConnections?: { readonly edges?: ReadonlyArray<{ readonly node?: { readonly id: string, readonly followingUser?: { readonly id: string, readonly guid: string, readonly roles: any } | null, readonly mutualConnection?: { readonly id: string } | null } | null } | null> | null, readonly pageInfo: { readonly endCursor?: string | null, readonly hasNextPage: boolean } } | null };

export type GetNotificationsQueryVariables = Types.Exact<{
  user: Types.Scalars['String'];
  first: Types.Scalars['Int'];
  after?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetNotificationsQuery = { readonly notifications?: { readonly edges?: ReadonlyArray<{ readonly node?: { readonly id: string, readonly message: string, readonly isSeen: boolean, readonly createdAt: string, readonly unReedCount: number } | null } | null> | null, readonly pageInfo: { readonly hasNextPage: boolean, readonly endCursor?: string | null } } | null };

export type UpdateNotificationMutationVariables = Types.Exact<{
  input: Types.UpdateNotificationInput;
}>;


export type UpdateNotificationMutation = { readonly updateNotification?: { readonly notification?: { readonly id: string } | null } | null };

export type RemoveNotificationMutationVariables = Types.Exact<{
  input: Types.DeleteNotificationInput;
}>;


export type RemoveNotificationMutation = { readonly deleteNotification?: { readonly notification?: { readonly id: string } | null } | null };


export const GetUserConnectionsDocument = gql`
    query GetUserConnections($user: String!, $first: Int, $after: String) {
  userConnections(user: $user, first: $first, after: $after) {
    edges {
      node {
        id
        followingUser {
          id
          guid
          roles
        }
        mutualConnection {
          id
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetUserConnectionsQuery__
 *
 * To run a query within a React component, call `useGetUserConnectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserConnectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserConnectionsQuery({
 *   variables: {
 *      user: // value for 'user'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetUserConnectionsQuery(baseOptions: Apollo.QueryHookOptions<GetUserConnectionsQuery, GetUserConnectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserConnectionsQuery, GetUserConnectionsQueryVariables>(GetUserConnectionsDocument, options);
      }
export function useGetUserConnectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserConnectionsQuery, GetUserConnectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserConnectionsQuery, GetUserConnectionsQueryVariables>(GetUserConnectionsDocument, options);
        }
export type GetUserConnectionsQueryHookResult = ReturnType<typeof useGetUserConnectionsQuery>;
export type GetUserConnectionsLazyQueryHookResult = ReturnType<typeof useGetUserConnectionsLazyQuery>;
export type GetUserConnectionsQueryResult = Apollo.QueryResult<GetUserConnectionsQuery, GetUserConnectionsQueryVariables>;
export const GetNotificationsDocument = gql`
    query GetNotifications($user: String!, $first: Int!, $after: String) {
  notifications(user: $user, first: $first, after: $after) {
    edges {
      node {
        id
        message
        isSeen
        createdAt
        unReedCount
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    `;

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *      user: // value for 'user'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetNotificationsQuery(baseOptions: Apollo.QueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
      }
export function useGetNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
        }
export type GetNotificationsQueryHookResult = ReturnType<typeof useGetNotificationsQuery>;
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>;
export type GetNotificationsQueryResult = Apollo.QueryResult<GetNotificationsQuery, GetNotificationsQueryVariables>;
export const UpdateNotificationDocument = gql`
    mutation UpdateNotification($input: updateNotificationInput!) {
  updateNotification(input: $input) {
    notification {
      id
    }
  }
}
    `;
export type UpdateNotificationMutationFn = Apollo.MutationFunction<UpdateNotificationMutation, UpdateNotificationMutationVariables>;

/**
 * __useUpdateNotificationMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationMutation, { data, loading, error }] = useUpdateNotificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNotificationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNotificationMutation, UpdateNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNotificationMutation, UpdateNotificationMutationVariables>(UpdateNotificationDocument, options);
      }
export type UpdateNotificationMutationHookResult = ReturnType<typeof useUpdateNotificationMutation>;
export type UpdateNotificationMutationResult = Apollo.MutationResult<UpdateNotificationMutation>;
export type UpdateNotificationMutationOptions = Apollo.BaseMutationOptions<UpdateNotificationMutation, UpdateNotificationMutationVariables>;
export const RemoveNotificationDocument = gql`
    mutation RemoveNotification($input: deleteNotificationInput!) {
  deleteNotification(input: $input) {
    notification {
      id
    }
  }
}
    `;
export type RemoveNotificationMutationFn = Apollo.MutationFunction<RemoveNotificationMutation, RemoveNotificationMutationVariables>;

/**
 * __useRemoveNotificationMutation__
 *
 * To run a mutation, you first call `useRemoveNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeNotificationMutation, { data, loading, error }] = useRemoveNotificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveNotificationMutation(baseOptions?: Apollo.MutationHookOptions<RemoveNotificationMutation, RemoveNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveNotificationMutation, RemoveNotificationMutationVariables>(RemoveNotificationDocument, options);
      }
export type RemoveNotificationMutationHookResult = ReturnType<typeof useRemoveNotificationMutation>;
export type RemoveNotificationMutationResult = Apollo.MutationResult<RemoveNotificationMutation>;
export type RemoveNotificationMutationOptions = Apollo.BaseMutationOptions<RemoveNotificationMutation, RemoveNotificationMutationVariables>;