import css from "./EmptyChart.module.scss";
import { Button, Container, Typography } from "@fitneks-component-library";
import { useLocation, useNavigate } from "react-router-dom";
import AppRoutes from "@fitneks-routes";

export const EmptyChart = () => {
    const location = useLocation();
    const navigate = useNavigate();

    if (!location.pathname.includes(AppRoutes.dashboard.children.myProfile.path)) {
        return <></>;
    }

    return (
        <Container className={css["empty-chart"]}>
            <Typography align={"center"} bold>
                Looks like you don’t have any points!
            </Typography>
            <Container width={184}>
                <Button
                    title={"Join a live stream"}
                    size={"small"}
                    fullWidth
                    onClick={() => navigate(AppRoutes.index.route())}
                />
            </Container>
        </Container>
    );
};
