/* eslint-disable */
/* tslint:disable */
/* THIS IS A GENERATED FILE, DO NOT EDIT. */
import * as Types from '@fitneks-types/Graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TrainingMusclesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type TrainingMusclesQuery = { readonly trainingMuscles?: { readonly edges?: ReadonlyArray<{ readonly node?: { readonly id: string, readonly name: string } | null } | null> | null } | null };

export type ClassEarnedPointsQueryVariables = Types.Exact<{
  first: Types.Scalars['Int'];
  after?: Types.InputMaybe<Types.Scalars['String']>;
  user: Types.Scalars['String'];
  createdAt?: Types.InputMaybe<ReadonlyArray<Types.InputMaybe<Types.TrainingEarnedPointFilter_CreatedAt>> | Types.InputMaybe<Types.TrainingEarnedPointFilter_CreatedAt>>;
}>;


export type ClassEarnedPointsQuery = { readonly trainingEarnedPoints?: { readonly edges?: ReadonlyArray<{ readonly node?: { readonly id: string, readonly points: number, readonly createdAt: string, readonly trainingMuscle?: { readonly id: string, readonly name: string } | null } | null } | null> | null, readonly pageInfo: { readonly hasNextPage: boolean, readonly endCursor?: string | null } } | null };


export const TrainingMusclesDocument = gql`
    query TrainingMuscles {
  trainingMuscles(first: 50) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useTrainingMusclesQuery__
 *
 * To run a query within a React component, call `useTrainingMusclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainingMusclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainingMusclesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTrainingMusclesQuery(baseOptions?: Apollo.QueryHookOptions<TrainingMusclesQuery, TrainingMusclesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrainingMusclesQuery, TrainingMusclesQueryVariables>(TrainingMusclesDocument, options);
      }
export function useTrainingMusclesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrainingMusclesQuery, TrainingMusclesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrainingMusclesQuery, TrainingMusclesQueryVariables>(TrainingMusclesDocument, options);
        }
export type TrainingMusclesQueryHookResult = ReturnType<typeof useTrainingMusclesQuery>;
export type TrainingMusclesLazyQueryHookResult = ReturnType<typeof useTrainingMusclesLazyQuery>;
export type TrainingMusclesQueryResult = Apollo.QueryResult<TrainingMusclesQuery, TrainingMusclesQueryVariables>;
export const ClassEarnedPointsDocument = gql`
    query ClassEarnedPoints($first: Int!, $after: String, $user: String!, $createdAt: [TrainingEarnedPointFilter_createdAt]) {
  trainingEarnedPoints(
    first: $first
    after: $after
    user: $user
    createdAt: $createdAt
  ) {
    edges {
      node {
        id
        trainingMuscle {
          id
          name
        }
        points
        createdAt
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    `;

/**
 * __useClassEarnedPointsQuery__
 *
 * To run a query within a React component, call `useClassEarnedPointsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassEarnedPointsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassEarnedPointsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      user: // value for 'user'
 *      createdAt: // value for 'createdAt'
 *   },
 * });
 */
export function useClassEarnedPointsQuery(baseOptions: Apollo.QueryHookOptions<ClassEarnedPointsQuery, ClassEarnedPointsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClassEarnedPointsQuery, ClassEarnedPointsQueryVariables>(ClassEarnedPointsDocument, options);
      }
export function useClassEarnedPointsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClassEarnedPointsQuery, ClassEarnedPointsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClassEarnedPointsQuery, ClassEarnedPointsQueryVariables>(ClassEarnedPointsDocument, options);
        }
export type ClassEarnedPointsQueryHookResult = ReturnType<typeof useClassEarnedPointsQuery>;
export type ClassEarnedPointsLazyQueryHookResult = ReturnType<typeof useClassEarnedPointsLazyQuery>;
export type ClassEarnedPointsQueryResult = Apollo.QueryResult<ClassEarnedPointsQuery, ClassEarnedPointsQueryVariables>;