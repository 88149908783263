/* eslint-disable */
/* tslint:disable */
/* THIS IS A GENERATED FILE, DO NOT EDIT. */
import * as Types from '@fitneks-types/Graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StreamsByGuidQueryVariables = Types.Exact<{
  guid?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type StreamsByGuidQuery = { readonly videoStreams?: { readonly edges?: ReadonlyArray<{ readonly node?: { readonly id: string, readonly guid: string, readonly title: string, readonly description?: string | null, readonly isLive: boolean, readonly isUpcoming: boolean, readonly videoStreamViewersCount: number, readonly signedUpUsersCount: number, readonly pointsForComplete: number, readonly startDatetime?: string | null, readonly startedDatetime?: string | null, readonly currentUserSignedUp: boolean, readonly videoStreamType?: { readonly id: string, readonly name: string } | null, readonly user?: { readonly id: string, readonly username: string, readonly displayName: string } | null, readonly trainingTypes?: { readonly edges?: ReadonlyArray<{ readonly node?: { readonly id: string, readonly name: string, readonly iconMediaObject?: { readonly filePath?: string | null } | null } | null } | null> | null } | null } | null } | null> | null } | null };


export const StreamsByGuidDocument = gql`
    query StreamsByGuid($guid: String) {
  videoStreams(guid: $guid) {
    edges {
      node {
        id
        guid
        title
        description
        isLive
        isUpcoming
        videoStreamViewersCount
        signedUpUsersCount
        pointsForComplete
        startDatetime
        startedDatetime
        currentUserSignedUp
        videoStreamType {
          id
          name
        }
        user {
          id
          username
          displayName
        }
        trainingTypes {
          edges {
            node {
              id
              name
              iconMediaObject {
                filePath
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useStreamsByGuidQuery__
 *
 * To run a query within a React component, call `useStreamsByGuidQuery` and pass it any options that fit your needs.
 * When your component renders, `useStreamsByGuidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStreamsByGuidQuery({
 *   variables: {
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function useStreamsByGuidQuery(baseOptions?: Apollo.QueryHookOptions<StreamsByGuidQuery, StreamsByGuidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StreamsByGuidQuery, StreamsByGuidQueryVariables>(StreamsByGuidDocument, options);
      }
export function useStreamsByGuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StreamsByGuidQuery, StreamsByGuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StreamsByGuidQuery, StreamsByGuidQueryVariables>(StreamsByGuidDocument, options);
        }
export type StreamsByGuidQueryHookResult = ReturnType<typeof useStreamsByGuidQuery>;
export type StreamsByGuidLazyQueryHookResult = ReturnType<typeof useStreamsByGuidLazyQuery>;
export type StreamsByGuidQueryResult = Apollo.QueryResult<StreamsByGuidQuery, StreamsByGuidQueryVariables>;