import { ModalOverlay, ModalProps } from "./Modal.types";
import css from "./Modal.module.scss";
import classNames from "@fitneks-commons/classnames";
import Portal from "../Portal/Portal";
import { useEffect } from "react";

/**
 * Modal component
 *
 * @param {ModalProps} props Component props
 * @param {boolean} props.isOpen - modal open state
 * @param {string} props.title - modal name
 * @param {number} props.width - modal width 930 by default
 * @param {string} props.overlay - modal overlay style "dark" | "light" | "transparent"
 * @param {function} props.onClose - trigger modal closing
 *
 */

const Modal = (props: ModalProps) => {
    const KEY_NAME_ESC = "Escape";
    const KEY_EVENT_TYPE = "keyup";
    const overlay: ModalOverlay = props.overlay || "dark";
    const width = props.width || "930";
    const closeBtn = props.closeBtn ?? true;
    const closeOnClickOutside = props.closeOnClickOutside ?? true;
    const bgColor = props.bgColor ?? true;

    useEffect(() => {
        return () => {
            afterModalClose();
        };
    }, []);

    useEffect(() => {
        const closeOnEscapeKey = (e: { key: string }) => {
            if (e.key === KEY_NAME_ESC) {
                props.onClose();
            }
        };
        document.body.addEventListener(KEY_EVENT_TYPE, closeOnEscapeKey);
        return () => {
            document.body.removeEventListener(KEY_EVENT_TYPE, closeOnEscapeKey);
        };
    }, [props.onClose]);

    useEffect(() => {
        if (props.isOpen && !document.body.classList.contains(css["is-overflow-hidden"] as string)) {
            document.body.classList.add(css["is-overflow-hidden"] as string);
        } else {
            afterModalClose();
        }
    }, [props.isOpen]);

    function afterModalClose() {
        const childrenCount = document.getElementById("modal-wrapper")?.children?.length ?? 0;
        if (childrenCount === 0 && document.body.classList.contains(css["is-overflow-hidden"] as string)) {
            document.body.classList.remove(css["is-overflow-hidden"] as string);
        }
    }

    if (!props.isOpen) return <></>;

    return (
        <Portal id={"modal-wrapper"}>
            <div
                ref={props.htmlRef}
                className={classNames(css["modal-overlay"], css[overlay], props.className)}
                onClick={() => closeOnClickOutside && props.onClose()}
                role="presentation"
                aria-labelledby={props.title}
            >
                <div
                    className={classNames(
                        css["modal-wrapper"],
                        props.topFixed && css["is-topFixed"],
                        props.bottomFixed && css["is-bottomFixed"]
                    )}
                >
                    {closeBtn && (
                        <span
                            className={classNames(css["modal-close"])}
                            onClick={() => props.onClose()}
                            aria-label="Close"
                        ></span>
                    )}
                    <div
                        className={classNames(
                            css["modal"],
                            props.shadow && css["has-shadow"],
                            props.radius && css["has-radius"],
                            !bgColor && css["transparent"]
                        )}
                        style={{ width: `${width}px` }}
                        onClick={(e) => {
                            // do not close modal if anything inside modal content is clicked
                            e.stopPropagation();
                        }}
                    >
                        {props.children}
                    </div>
                </div>
            </div>
        </Portal>
    );
};

export default Modal;
