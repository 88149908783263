import { useGetGamificationItemsColorsQuery } from "./CssVariables.generated";

export default function CssVariables() {
    const { data, loading } = useGetGamificationItemsColorsQuery();

    let globalCssVariables = "";

    if (!loading && data) {
        globalCssVariables = `
    :root {
        ${data?.gamificationItems
            ?.map((item) => {
                if (item?.color && item?.name) {
                    return `
                 --fx-${item?.name}: ${item?.color};
                `;
                }
                return null;
            })
            .join("")}
    }
     ${data?.gamificationItems
         ?.map((item) => {
             if (item?.color && item?.name) {
                 return `
                 .t-${item?.name}{ color: ${item?.color} };
                `;
             }
             return null;
         })
         .join("")}
    
    `;
    }

    return <style type="text/css">{globalCssVariables}</style>;
}
