import { AuthProvider, GraphQLProvider, PayPalProvider, ThemeProvider } from "@fitneks-providers";
import AppRouter from "./router/AppRouter/AppRouter";
import { GoogleOAuthProvider } from "@react-oauth/google";

const App = () => {
    return (
        <ThemeProvider>
            <AuthProvider>
                <GraphQLProvider>
                    <GoogleOAuthProvider clientId={import.meta.env.FITNEKS_GOOGLE_OAUTH_CLIENT_ID}>
                        <PayPalProvider>
                            <AppRouter />
                        </PayPalProvider>
                    </GoogleOAuthProvider>
                </GraphQLProvider>
            </AuthProvider>
        </ThemeProvider>
    );
};

export default App;
