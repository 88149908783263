import css from "./Footer.module.scss";
import { Container, Typography } from "@fitneks-component-library";
import classNames from "@fitneks-commons/classnames";
import "@fitneks-commons/styles/display.scss";
import "@fitneks-commons/styles/spacing.scss";
import { Link } from "react-router-dom";
import AppRoutes from "@fitneks-routes";

export default function Footer() {
    return (
        <Container tag={"footer"} bg={"#353C3A"} color={"#fff"} className={classNames(css["footer"], "px-4 py-3")}>
            <Typography className={"mb-0"}>© {new Date().getFullYear()} Fitneks Inc. All Rights Reserved</Typography>
            <Typography className={"mb-0"}>
                <Link to={AppRoutes.privacyPolicy.path} className={"t-inherit fx-hover mr-3"}>
                    Privacy Policy
                </Link>
                <Link to={AppRoutes.termsOfUse.path} className={"t-inherit fx-hover"}>
                    Terms Of Service
                </Link>
            </Typography>
        </Container>
    );
}
