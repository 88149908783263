/* eslint-disable */
/* tslint:disable */
/* THIS IS A GENERATED FILE, DO NOT EDIT. */
import * as Types from '@fitneks-types/Graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTrainerStreamQueryVariables = Types.Exact<{
  username: Types.Scalars['String'];
  streamGuid?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetTrainerStreamQuery = { readonly withUsernameVideoStream?: { readonly id: string, readonly guid: string, readonly title: string, readonly private: boolean, readonly isLive: boolean, readonly liveKitSid?: string | null, readonly videoStreamViewersCount: number, readonly participantsCount: number, readonly trainingRate?: number | null, readonly ratePoints: number, readonly token: string, readonly videoStreamType?: { readonly id: string, readonly name: string } | null, readonly user?: { readonly id: string, readonly username: string } | null } | null };

export type LearnerInfoQueryVariables = Types.Exact<{
  username: Types.Scalars['String'];
}>;


export type LearnerInfoQuery = { readonly withUsernameUser?: { readonly id: string, readonly username: string, readonly displayName: string, readonly OAuthAvatar?: string | null, readonly avatarMediaObject?: { readonly id: string, readonly filePath?: string | null } | null } | null };

export type EarnedGiftsQueryVariables = Types.Exact<{
  videoStream: Types.Scalars['String'];
  first: Types.Scalars['Int'];
  after?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type EarnedGiftsQuery = { readonly gamificationItemLogs?: { readonly edges?: ReadonlyArray<{ readonly node?: { readonly id: string, readonly type: string, readonly count: number, readonly datetime: string, readonly gamificationItem?: { readonly id: string, readonly name: string, readonly title: string, readonly color: string, readonly iconMediaObject?: { readonly filePath?: string | null } | null } | null } | null } | null> | null, readonly pageInfo: { readonly endCursor?: string | null, readonly hasNextPage: boolean } } | null };

export type GiftsListQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GiftsListQuery = { readonly gamificationItems?: ReadonlyArray<{ readonly id: string, readonly name: string, readonly enabled: boolean, readonly isRuby: boolean, readonly isProtein: boolean, readonly withdrawPrice: number, readonly iconMediaObject?: { readonly filePath?: string | null } | null } | null> | null };

export type StartVideoStreamMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type StartVideoStreamMutation = { readonly startVideoStream?: { readonly videoStream?: { readonly id: string } | null } | null };

export type JoinVideoStreamMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type JoinVideoStreamMutation = { readonly joinVideoStream?: { readonly videoStream?: { readonly id: string, readonly token: string, readonly liveKitSid?: string | null } | null } | null };

export type EndVideoStreamMutationVariables = Types.Exact<{
  input: Types.EndVideoStreamInput;
}>;


export type EndVideoStreamMutation = { readonly endVideoStream?: { readonly videoStream?: { readonly id: string } | null } | null };

export type LastActivityChangerMutationVariables = Types.Exact<{
  input: Types.LastActivityVideoStreamInput;
}>;


export type LastActivityChangerMutation = { readonly lastActivityVideoStream?: { readonly clientMutationId?: string | null } | null };


export const GetTrainerStreamDocument = gql`
    query getTrainerStream($username: String!, $streamGuid: String) {
  withUsernameVideoStream(username: $username, streamGuid: $streamGuid) {
    id
    guid
    title
    private
    isLive
    liveKitSid
    videoStreamViewersCount
    participantsCount
    trainingRate
    ratePoints
    token
    videoStreamType {
      id
      name
    }
    user {
      id
      username
    }
  }
}
    `;

/**
 * __useGetTrainerStreamQuery__
 *
 * To run a query within a React component, call `useGetTrainerStreamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrainerStreamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrainerStreamQuery({
 *   variables: {
 *      username: // value for 'username'
 *      streamGuid: // value for 'streamGuid'
 *   },
 * });
 */
export function useGetTrainerStreamQuery(baseOptions: Apollo.QueryHookOptions<GetTrainerStreamQuery, GetTrainerStreamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTrainerStreamQuery, GetTrainerStreamQueryVariables>(GetTrainerStreamDocument, options);
      }
export function useGetTrainerStreamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTrainerStreamQuery, GetTrainerStreamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTrainerStreamQuery, GetTrainerStreamQueryVariables>(GetTrainerStreamDocument, options);
        }
export type GetTrainerStreamQueryHookResult = ReturnType<typeof useGetTrainerStreamQuery>;
export type GetTrainerStreamLazyQueryHookResult = ReturnType<typeof useGetTrainerStreamLazyQuery>;
export type GetTrainerStreamQueryResult = Apollo.QueryResult<GetTrainerStreamQuery, GetTrainerStreamQueryVariables>;
export const LearnerInfoDocument = gql`
    query LearnerInfo($username: String!) {
  withUsernameUser(username: $username) {
    id
    username
    displayName
    avatarMediaObject {
      id
      filePath
    }
    OAuthAvatar
  }
}
    `;

/**
 * __useLearnerInfoQuery__
 *
 * To run a query within a React component, call `useLearnerInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useLearnerInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearnerInfoQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useLearnerInfoQuery(baseOptions: Apollo.QueryHookOptions<LearnerInfoQuery, LearnerInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LearnerInfoQuery, LearnerInfoQueryVariables>(LearnerInfoDocument, options);
      }
export function useLearnerInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LearnerInfoQuery, LearnerInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LearnerInfoQuery, LearnerInfoQueryVariables>(LearnerInfoDocument, options);
        }
export type LearnerInfoQueryHookResult = ReturnType<typeof useLearnerInfoQuery>;
export type LearnerInfoLazyQueryHookResult = ReturnType<typeof useLearnerInfoLazyQuery>;
export type LearnerInfoQueryResult = Apollo.QueryResult<LearnerInfoQuery, LearnerInfoQueryVariables>;
export const EarnedGiftsDocument = gql`
    query EarnedGifts($videoStream: String!, $first: Int!, $after: String) {
  gamificationItemLogs(
    videoStream_guid: $videoStream
    first: $first
    after: $after
  ) {
    edges {
      node {
        id
        type
        gamificationItem {
          id
          name
          title
          color
          iconMediaObject {
            filePath
          }
        }
        count
        datetime
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;

/**
 * __useEarnedGiftsQuery__
 *
 * To run a query within a React component, call `useEarnedGiftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEarnedGiftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEarnedGiftsQuery({
 *   variables: {
 *      videoStream: // value for 'videoStream'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useEarnedGiftsQuery(baseOptions: Apollo.QueryHookOptions<EarnedGiftsQuery, EarnedGiftsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EarnedGiftsQuery, EarnedGiftsQueryVariables>(EarnedGiftsDocument, options);
      }
export function useEarnedGiftsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EarnedGiftsQuery, EarnedGiftsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EarnedGiftsQuery, EarnedGiftsQueryVariables>(EarnedGiftsDocument, options);
        }
export type EarnedGiftsQueryHookResult = ReturnType<typeof useEarnedGiftsQuery>;
export type EarnedGiftsLazyQueryHookResult = ReturnType<typeof useEarnedGiftsLazyQuery>;
export type EarnedGiftsQueryResult = Apollo.QueryResult<EarnedGiftsQuery, EarnedGiftsQueryVariables>;
export const GiftsListDocument = gql`
    query GiftsList {
  gamificationItems {
    id
    name
    enabled
    isRuby
    isProtein
    withdrawPrice
    iconMediaObject {
      filePath
    }
  }
}
    `;

/**
 * __useGiftsListQuery__
 *
 * To run a query within a React component, call `useGiftsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGiftsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGiftsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGiftsListQuery(baseOptions?: Apollo.QueryHookOptions<GiftsListQuery, GiftsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GiftsListQuery, GiftsListQueryVariables>(GiftsListDocument, options);
      }
export function useGiftsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GiftsListQuery, GiftsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GiftsListQuery, GiftsListQueryVariables>(GiftsListDocument, options);
        }
export type GiftsListQueryHookResult = ReturnType<typeof useGiftsListQuery>;
export type GiftsListLazyQueryHookResult = ReturnType<typeof useGiftsListLazyQuery>;
export type GiftsListQueryResult = Apollo.QueryResult<GiftsListQuery, GiftsListQueryVariables>;
export const StartVideoStreamDocument = gql`
    mutation StartVideoStream($id: ID!) {
  startVideoStream(input: {id: $id}) {
    videoStream {
      id
    }
  }
}
    `;
export type StartVideoStreamMutationFn = Apollo.MutationFunction<StartVideoStreamMutation, StartVideoStreamMutationVariables>;

/**
 * __useStartVideoStreamMutation__
 *
 * To run a mutation, you first call `useStartVideoStreamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartVideoStreamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startVideoStreamMutation, { data, loading, error }] = useStartVideoStreamMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStartVideoStreamMutation(baseOptions?: Apollo.MutationHookOptions<StartVideoStreamMutation, StartVideoStreamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartVideoStreamMutation, StartVideoStreamMutationVariables>(StartVideoStreamDocument, options);
      }
export type StartVideoStreamMutationHookResult = ReturnType<typeof useStartVideoStreamMutation>;
export type StartVideoStreamMutationResult = Apollo.MutationResult<StartVideoStreamMutation>;
export type StartVideoStreamMutationOptions = Apollo.BaseMutationOptions<StartVideoStreamMutation, StartVideoStreamMutationVariables>;
export const JoinVideoStreamDocument = gql`
    mutation JoinVideoStream($id: ID!) {
  joinVideoStream(input: {id: $id}) {
    videoStream {
      id
      token
      liveKitSid
    }
  }
}
    `;
export type JoinVideoStreamMutationFn = Apollo.MutationFunction<JoinVideoStreamMutation, JoinVideoStreamMutationVariables>;

/**
 * __useJoinVideoStreamMutation__
 *
 * To run a mutation, you first call `useJoinVideoStreamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinVideoStreamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinVideoStreamMutation, { data, loading, error }] = useJoinVideoStreamMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJoinVideoStreamMutation(baseOptions?: Apollo.MutationHookOptions<JoinVideoStreamMutation, JoinVideoStreamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JoinVideoStreamMutation, JoinVideoStreamMutationVariables>(JoinVideoStreamDocument, options);
      }
export type JoinVideoStreamMutationHookResult = ReturnType<typeof useJoinVideoStreamMutation>;
export type JoinVideoStreamMutationResult = Apollo.MutationResult<JoinVideoStreamMutation>;
export type JoinVideoStreamMutationOptions = Apollo.BaseMutationOptions<JoinVideoStreamMutation, JoinVideoStreamMutationVariables>;
export const EndVideoStreamDocument = gql`
    mutation EndVideoStream($input: endVideoStreamInput!) {
  endVideoStream(input: $input) {
    videoStream {
      id
    }
  }
}
    `;
export type EndVideoStreamMutationFn = Apollo.MutationFunction<EndVideoStreamMutation, EndVideoStreamMutationVariables>;

/**
 * __useEndVideoStreamMutation__
 *
 * To run a mutation, you first call `useEndVideoStreamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndVideoStreamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endVideoStreamMutation, { data, loading, error }] = useEndVideoStreamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEndVideoStreamMutation(baseOptions?: Apollo.MutationHookOptions<EndVideoStreamMutation, EndVideoStreamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EndVideoStreamMutation, EndVideoStreamMutationVariables>(EndVideoStreamDocument, options);
      }
export type EndVideoStreamMutationHookResult = ReturnType<typeof useEndVideoStreamMutation>;
export type EndVideoStreamMutationResult = Apollo.MutationResult<EndVideoStreamMutation>;
export type EndVideoStreamMutationOptions = Apollo.BaseMutationOptions<EndVideoStreamMutation, EndVideoStreamMutationVariables>;
export const LastActivityChangerDocument = gql`
    mutation LastActivityChanger($input: lastActivityVideoStreamInput!) {
  lastActivityVideoStream(input: $input) {
    clientMutationId
  }
}
    `;
export type LastActivityChangerMutationFn = Apollo.MutationFunction<LastActivityChangerMutation, LastActivityChangerMutationVariables>;

/**
 * __useLastActivityChangerMutation__
 *
 * To run a mutation, you first call `useLastActivityChangerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLastActivityChangerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lastActivityChangerMutation, { data, loading, error }] = useLastActivityChangerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLastActivityChangerMutation(baseOptions?: Apollo.MutationHookOptions<LastActivityChangerMutation, LastActivityChangerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LastActivityChangerMutation, LastActivityChangerMutationVariables>(LastActivityChangerDocument, options);
      }
export type LastActivityChangerMutationHookResult = ReturnType<typeof useLastActivityChangerMutation>;
export type LastActivityChangerMutationResult = Apollo.MutationResult<LastActivityChangerMutation>;
export type LastActivityChangerMutationOptions = Apollo.BaseMutationOptions<LastActivityChangerMutation, LastActivityChangerMutationVariables>;