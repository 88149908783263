import {Button, Container, ImageMask, QuantityInput, Typography} from "@fitneks-component-library";
import {
    GiftItem,
    GiftList,
    GiftsRubyPrice,
    SendGiftToTrainerElement,
    SendGiftToTrainerProps,
    GiftListDocument,
    useBuyGiftMutation,
    useGiftListLazyQuery,
    useGiftRubyPriceLazyQuery,
    useSendGiftMutation,
} from "@fitneks-commons/ui";
import {forwardRef, useContext, useEffect, useImperativeHandle, useState} from "react";
import { useAuth, useLoginAlert } from "@fitneks-commons/hooks";
import classNames from "@fitneks-commons/classnames";
import css from "./SendGiftToTrainer.module.scss";
import { InsufficientFundsModal } from "@fitneks-exchange/organisms/Calculator/organisms/InsufficientFundsModal/InsufficientFundsModal";
import { useRoomContext } from "@livekit/components-react";
import { getDeviceType } from "@fitneks-commons/getDeviceType";
import {ThemeContext} from "@fitneks-providers";

export const SendGiftToTrainer = forwardRef<SendGiftToTrainerElement, SendGiftToTrainerProps>(
    ({ showModal, onGiftSend, showOnlyGifts, isStreamPage,bottomtext,style,text }, ref) => {
        const room = useRoomContext();
        const { userID, isAuthorized } = useAuth();
        const { LoginAlert, guard } = useLoginAlert();
        const [giftList, setGiftList] = useState<GiftList>();
        const { addNotification } = useContext(ThemeContext);
        const [selectedGift, setSelectedGift] = useState<GiftItem>(null);
        const [giftsRubyPrice, setGiftsRubyPrice] = useState<GiftsRubyPrice>();
        const [giftCount, setGiftCount] = useState(1);
        const [insufficientFundsModal, setInsufficientFundsModal] = useState(false);

        const [getGiftsList, { data, loading }] = useGiftListLazyQuery();
        const [getRubyPrice] = useGiftRubyPriceLazyQuery();
        const [buyGift, { loading: buyGiftLoading }] = useBuyGiftMutation({
            refetchQueries: [
                {
                    query: GiftListDocument,
                    variables: {
                        input: userID,
                    },
                },
            ],
            awaitRefetchQueries: true,
        });
        const [sendGift, { loading: sendLoading, client }] = useSendGiftMutation();

        useImperativeHandle(ref, () => ({
            giftSend: async (chatGift?: boolean) => {
                return await handleSend(chatGift);
            },
            onModalClose: () => {
                resetData();
            },
        }));

        useEffect(() => {
            (async () => {
                if ((typeof showModal === "undefined" || showModal) && userID && !loading && !data) {
                    await getGiftsList({
                        variables: {
                            input: userID,
                        },
                    });
                }
            })();
        }, [showModal]);

        useEffect(() => {
            (async () => {
                if (data) {
                    const newGiftList = data?.gamificationItemLogCounts?.filter(
                        (item) =>
                            item?.gamificationItem?.enabled &&
                            (item?.gamificationItem?.isProtein ||
                                item?.gamificationItem?.isRuby ||
                                item?.gamificationItem?.withdrawPrice > 0)
                    );

                    setGiftList(newGiftList);

                    if (selectedGift || !showOnlyGifts) {
                        const newSelectedGift = newGiftList?.find((el) => {
                            if (!giftList) {
                                return el?.gamificationItem?.isProtein;
                            }
                            return el?.gamificationItem?.id === selectedGift?.gamificationItem?.id;
                        });

                        newSelectedGift && setSelectedGift(newSelectedGift);
                    }

                    if (!giftList) {
                        const giftListNames = newGiftList?.map((el) => el?.gamificationItem?.name ?? "");
                        const response = await getRubyPrice({
                            variables: {
                                input: giftListNames ?? [],
                            },
                        });
                        setGiftsRubyPrice(response?.data?.gamificationItemCosts);
                    }
                }
            })();
        }, [data]);

        const handleGiftSelect = (gift: GiftItem) => {
            setSelectedGift(gift);
            setGiftCount(1);
        };

        const handleQuantityChange = (value: number) => {
            value > 0 && setGiftCount(value);
        };

        const handleSend = async (chatGift?: boolean) => {
            const gift = selectedGift?.gamificationItem?.name;
            let giftSent = false;

            if (typeof selectedGift?.count !== "undefined") {
                const ruby = giftList?.find((el) => el?.gamificationItem?.name === "ruby");

                // Buy more gifts if not enough
                if (giftCount > selectedGift.count) {
                    // If gift type not ruby
                    if (selectedGift?.gamificationItem?.name !== "ruby") {
                        const rubyPrice = giftsRubyPrice?.find(
                            (el) => el?.gamificationItem?.id === selectedGift?.gamificationItem?.id
                        );
                        const totalPrice = Number(rubyPrice?.cost ?? 0) * (giftCount - selectedGift.count);
                        // If not have enough ruby for exchange
                        if (typeof ruby?.count !== "undefined" && totalPrice > ruby?.count) {
                            if (showOnlyGifts) {
                                setSelectedGift(null);
                                setGiftCount(1);
                            }
                            setInsufficientFundsModal(true);
                        } else if (ruby?.gamificationItem?.id && selectedGift.gamificationItem?.id) {
                            await buyGift({
                                variables: {
                                    input: {
                                        user: userID,
                                        baseGamificationItem: ruby.gamificationItem.id,
                                        gamificationItem: selectedGift.gamificationItem.id,
                                        count: giftCount - selectedGift.count,
                                        datetime: new Date().toUTCString(),
                                    },
                                },
                            });
                            onSendGift(chatGift);
                            giftSent = true;
                        }
                    } else {
                        // If gift type ruby and not enough
                        if (showOnlyGifts) {
                            setSelectedGift(null);
                            setGiftCount(1);
                        }
                        setInsufficientFundsModal(true);
                    }
                    return giftSent ? gift : undefined;
                }

                // Have enough gift for send
                onSendGift(chatGift);
                return gift;
            }
            return gift ?? null;
        };

        function onSendGift(chatGift?: boolean) {
            if (chatGift) {
                // TODO: if chatGift is important here, or can be removed
                console.log("chatGift");
            }
            (async () => {
                if (selectedGift) {
                    await sendGift({
                        variables: {
                            input: {
                                id: `api/video_streams/${room.name}`,
                                count: giftCount,
                                gamificationItem: selectedGift?.gamificationItem?.name ?? "",
                            },
                        },
                    });

                    client.cache.updateQuery(
                        {
                            query: GiftListDocument,
                            variables: {
                                input: userID,
                            },
                        },
                        (data) => {
                            const newLogCounts = data?.gamificationItemLogCounts?.map((el: GiftItem) => {
                                if (el?.id === selectedGift?.id) {
                                    return {
                                        ...el,
                                        count: el.count - giftCount,
                                    };
                                }
                                return el;
                            });

                            return {
                                gamificationItemLogCounts: newLogCounts,
                            };
                        }
                    );

                    if (typeof onGiftSend === "function") {
                        onGiftSend(selectedGift, giftCount);
                    }
                    resetData();
                }
            })();
            addNotification({
                // eslint-disable-next-line max-len
                message: `Congratulations you successfully sent ${giftCount} ${selectedGift?.gamificationItem?.title} to your trainer`,
                type: "success",
                close: "timeOut",
                duration: 3000,
            });
        }

        const resetData = () => {
            setGiftCount(1);
            setSelectedGift(giftList?.find((el) => el?.gamificationItem?.name === "protein") ?? null);
        };

        if (!isAuthorized) {
            return <></>;
        }

        const isDesktop = getDeviceType("desktop");
        return (
            <>
                {text && (
                    <Typography className={`d-flex py-1 px-1 mb-0 ${style}`}>
                        {text}
                    </Typography>
                )}
                <Container className={"d-flex py-1 px-1 justify-content-center"}>
                    {giftList?.map((gift) => {
                        return (
                            <Container
                                key={gift?.gamificationItem?.id}
                                className={classNames(
                                    css["gift-item"],
                                    showOnlyGifts && css["show-only-gifts"],
                                    selectedGift?.gamificationItem?.name === gift?.gamificationItem?.name &&
                                    css["is-selected"],
                                    "t-center m-0 mr-1"
                                )}
                            >
                                <Container
                                    className={classNames(
                                        css["gift-item-iconWrapper"],
                                        `t-${gift?.gamificationItem?.name}`,
                                        "d-flex align-items-center justify-content-center mb-2 cursor-pointer"
                                    )}
                                    onClick={() => handleGiftSelect(gift)}
                                >
                                    <ImageMask
                                        src={gift?.gamificationItem?.iconMediaObject?.filePath ?? ""}
                                        size={50}
                                        className={classNames(
                                            css["gift-item-icon"],
                                            `t-${gift?.gamificationItem?.name}`
                                        )}
                                    />
                                </Container>
                                {!showOnlyGifts && (
                                    <QuantityInput
                                        className={classNames(
                                            css["gift-item-quantity"],
                                            `t-${gift?.gamificationItem?.name}`
                                        )}
                                        color={isDesktop ? "light" : "dark"}
                                        value={giftCount}
                                        style={"grid"}
                                        onChange={handleQuantityChange}
                                    />
                                )}
                            </Container>

                        );
                    })}
                </Container>
                {bottomtext && (
                    <Typography className={"d-flex py-1 px-1 justify-content-center"}>
                        {bottomtext}
                    </Typography>
                )}
                {!showOnlyGifts && (
                    <Container className={"px-2 pb-4"} innerWidth={300}>
                        <Button
                            title={sendLoading || buyGiftLoading ? "Sending" : "Send"}
                            size={"small"}
                            fullWidth
                            disabled={sendLoading || buyGiftLoading}
                            onClick={() => guard(handleSend)}
                        />
                    </Container>
                )}

                <LoginAlert />

                <InsufficientFundsModal
                    isOpen={insufficientFundsModal}
                    setIsOpen={(e) => setInsufficientFundsModal(e)}
                    isStreamPage={isStreamPage || false}
                />
            </>
        );
    }
);

SendGiftToTrainer.displayName = "SendGiftToTrainer";
