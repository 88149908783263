/* eslint-disable */
/* tslint:disable */
/* THIS IS A GENERATED FILE, DO NOT EDIT. */
import * as Types from '@fitneks-types/Graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserInfoQueryVariables = Types.Exact<{
  username: Types.Scalars['String'];
}>;


export type UserInfoQuery = { readonly withUsernameUser?: { readonly id: string, readonly username: string, readonly displayName: string, readonly roles: any, readonly gamificationPointsCount: number, readonly followingsCount: number, readonly currentUserIsFollower: boolean, readonly OAuthAvatar?: string | null, readonly avatarMediaObject?: { readonly id: string, readonly filePath?: string | null } | null } | null };

export type FollowUserMutationVariables = Types.Exact<{
  input: Types.CreateUserConnectionInput;
}>;


export type FollowUserMutation = { readonly createUserConnection?: { readonly userConnection?: { readonly id: string } | null } | null };

export type RemoveViewerMutationVariables = Types.Exact<{
  input: Types.BlockUserFromVideoStreamInput;
}>;


export type RemoveViewerMutation = { readonly blockUserFromVideoStream?: { readonly videoStream?: { readonly id: string } | null } | null };


export const UserInfoDocument = gql`
    query UserInfo($username: String!) {
  withUsernameUser(username: $username) {
    id
    username
    displayName
    roles
    gamificationPointsCount
    followingsCount
    currentUserIsFollower
    avatarMediaObject {
      id
      filePath
    }
    OAuthAvatar
  }
}
    `;

/**
 * __useUserInfoQuery__
 *
 * To run a query within a React component, call `useUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserInfoQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useUserInfoQuery(baseOptions: Apollo.QueryHookOptions<UserInfoQuery, UserInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserInfoQuery, UserInfoQueryVariables>(UserInfoDocument, options);
      }
export function useUserInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserInfoQuery, UserInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserInfoQuery, UserInfoQueryVariables>(UserInfoDocument, options);
        }
export type UserInfoQueryHookResult = ReturnType<typeof useUserInfoQuery>;
export type UserInfoLazyQueryHookResult = ReturnType<typeof useUserInfoLazyQuery>;
export type UserInfoQueryResult = Apollo.QueryResult<UserInfoQuery, UserInfoQueryVariables>;
export const FollowUserDocument = gql`
    mutation followUser($input: createUserConnectionInput!) {
  createUserConnection(input: $input) {
    userConnection {
      id
    }
  }
}
    `;
export type FollowUserMutationFn = Apollo.MutationFunction<FollowUserMutation, FollowUserMutationVariables>;

/**
 * __useFollowUserMutation__
 *
 * To run a mutation, you first call `useFollowUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFollowUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [followUserMutation, { data, loading, error }] = useFollowUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFollowUserMutation(baseOptions?: Apollo.MutationHookOptions<FollowUserMutation, FollowUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FollowUserMutation, FollowUserMutationVariables>(FollowUserDocument, options);
      }
export type FollowUserMutationHookResult = ReturnType<typeof useFollowUserMutation>;
export type FollowUserMutationResult = Apollo.MutationResult<FollowUserMutation>;
export type FollowUserMutationOptions = Apollo.BaseMutationOptions<FollowUserMutation, FollowUserMutationVariables>;
export const RemoveViewerDocument = gql`
    mutation removeViewer($input: blockUserFromVideoStreamInput!) {
  blockUserFromVideoStream(input: $input) {
    videoStream {
      id
    }
  }
}
    `;
export type RemoveViewerMutationFn = Apollo.MutationFunction<RemoveViewerMutation, RemoveViewerMutationVariables>;

/**
 * __useRemoveViewerMutation__
 *
 * To run a mutation, you first call `useRemoveViewerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveViewerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeViewerMutation, { data, loading, error }] = useRemoveViewerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveViewerMutation(baseOptions?: Apollo.MutationHookOptions<RemoveViewerMutation, RemoveViewerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveViewerMutation, RemoveViewerMutationVariables>(RemoveViewerDocument, options);
      }
export type RemoveViewerMutationHookResult = ReturnType<typeof useRemoveViewerMutation>;
export type RemoveViewerMutationResult = Apollo.MutationResult<RemoveViewerMutation>;
export type RemoveViewerMutationOptions = Apollo.BaseMutationOptions<RemoveViewerMutation, RemoveViewerMutationVariables>;