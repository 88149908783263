// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import md5 from "md5";
import { useLoginMutation } from "@fitneks-pages/Login/Login.generated";
import { useContext, useEffect, useState } from "react";
import { useAuth } from "@fitneks-commons/hooks";
import { FAILED_LOGIN_MESSAGE } from "@fitneks-pages/Login/Login.types";
import { useRegisterMutation } from "@fitneks-pages/Register/Register.generated";
import { currentTimezone } from "@fitneks-commons/helpers/functions/Date";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
    FacebookAuthCredential,
    FacebookButton,
    RoleSwitcherModal,
    useUploadUserAvatarMutation,
} from "@fitneks-commons/ui";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { CreateUserInput, Query } from "@fitneks-types/Graphql.generated";
import { ThemeContext } from "@fitneks-providers";
import AppRoutes from "@fitneks-routes";

const FacebookAuth = () => {
    const { onLogin } = useAuth();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { addNotification } = useContext(ThemeContext);

    const [uploadUserAvatar] = useUploadUserAvatarMutation();
    const [authorize, { client }] = useLoginMutation({
        onCompleted: (data) => {
            handleLogin(data?.loginUser?.user as Query["user"]);
        },
        onError: (e) => {
            if (e.message === FAILED_LOGIN_MESSAGE) {
                setIsOpen(true);
            } else {
                addNotification({
                    message: "Something went wrong, please try again.",
                    type: "error",
                    close: "timeOut",
                    duration: 3000,
                });
            }
        },
    });
    const [register, { loading: registerLoading }] = useRegisterMutation({
        onCompleted: (data) => {
            navigate(AppRoutes.onboardingWithRedirect.route(AppRoutes.index.route()), { replace: true });
            handleLogin(data?.createUser?.user as Query["user"]);
        },
        onError: () => {
            addNotification({
                message: "Something went wrong, please try again.",
                type: "error",
                close: "timeOut",
                duration: 3000,
            });
        },
    });

    const [authCreds, setAuthCreds] = useState<FacebookAuthCredential>();
    const [isOpen, setIsOpen] = useState(false);
    const [roles, setRoles] = useState<string[]>([]);

    useEffect(() => {
        if (roles.length > 0 && authCreds?.email != undefined) {
            const authPassword = md5(`fit${authCreds?.email}neks`);
            (async () => {
                if (authCreds.picture?.data?.url) {
                    const res = await fetch(authCreds.picture.data.url);
                    const blob = await res.blob();
                    const file = new File([blob], "authAvatar", { type: "image/jpg" });
                    const response = await uploadUserAvatar({
                        variables: { input: { file } },
                    });
                    const avatarId = response.data?.uploadMediaObject?.mediaObject?.id;
                    if (authCreds.email && authCreds.first_name && authCreds.last_name && authCreds.name) {
                        const input: CreateUserInput = {
                            username: authCreds.email.split("@")[0] || "",
                            email: authCreds.email,
                            firstName: authCreds.first_name,
                            lastName: authCreds.last_name,
                            password: authPassword,
                            displayName: authCreds.name,
                            roles: roles,
                            registerCode: searchParams.get("promotion_code"),
                            timezone: currentTimezone(),
                            isPublic: true,
                            warning: false,
                        };
                        if (avatarId) {
                            input.avatarMediaObject = avatarId;
                        }
                        await register({ variables: { input } });
                    }
                }
            })();
        }
    }, [roles]);

    function handleFacebookLogin(credentialResponse: FacebookAuthCredential) {
        if (credentialResponse != null) {
            setAuthCreds(credentialResponse);
            (async () => {
                const authPassword = md5(`fit${credentialResponse?.email}neks`);
                if (credentialResponse.email) {
                    const OAuthAvatar = credentialResponse.picture?.data.url;
                    await authorize({
                        variables: {
                            input: {
                                username: credentialResponse.email,
                                password: authPassword,
                                ...(OAuthAvatar && { OAuthAvatar }),
                            },
                        },
                    });
                }
            })();
        }
    }

    function handleLogin(user: Query["user"]) {
        if (user?.token && user?.refreshToken && user?.id && user?.username && user?.roles && user?.timezone) {
            onLogin(
                user.token,
                user.refreshToken,
                user.id,
                user.username,
                user.roles,
                user.timezone,
                user.guid,
                user.country,
                client
            );
        }
    }

    return (
        <>
            <FacebookLogin
                appId={import.meta.env.FITNEKS_FACEBOOK_OAUTH_CLIENT_ID}
                autoLoad={false}
                fields="name,first_name,last_name,email,picture"
                callback={(e) => handleFacebookLogin(e as FacebookAuthCredential)}
                render={(renderProps) => <FacebookButton onClick={renderProps.onClick} />}
            />
            <RoleSwitcherModal
                isOpen={isOpen}
                setIsOpen={(e) => setIsOpen(e)}
                onRoleSwitch={(e) => setRoles(e)}
                registerLoading={registerLoading}
            />
        </>
    );
};

export default FacebookAuth;
