import { PortalProps } from "./Portal.types";
import { createPortal } from "react-dom";
import { createWrapperAndAppendToBody } from "@fitneks-commons/createWrapperAndAppendToBody";
import { useLayoutEffect, useState } from "react";

const Portal = (props: PortalProps) => {
    const [wrapperElement, setWrapperElement] = useState<HTMLElement | null>(null);

    useLayoutEffect(() => {
        //getting element by ID
        let element = document.getElementById(props.id);
        let systemCreated = false;

        // if element not exist, creating new element with provided ID
        if (!element) {
            systemCreated = true;
            element = createWrapperAndAppendToBody(props.id);
        }
        setWrapperElement(element);

        return () => {
            // delete the programatically created element
            if (systemCreated && element?.parentNode) {
                element.parentNode.removeChild(element);
            }
        };
    }, [props.id]);

    if (wrapperElement === null) return null;

    return createPortal(props.children, wrapperElement);
};

export default Portal;
