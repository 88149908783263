import { useAuth } from "@fitneks-commons/hooks";
import { useGetUserAvatarAndNameQuery } from "@fitneks-pages/Dashboard/organisms/UserAvatarAndName/UserAvatarAndName.generated";
import { Cover, Shimmer, Typography } from "@fitneks-component-library";
import css from "./UserAvatarAndName.module.scss";
import classNames from "@fitneks-commons/classnames";
import { DefaultAvatar } from "@fitneks-commons/constants/DefaultAvatar";
import { useContext } from "react";
import { ThemeContext } from "@fitneks-providers";
import AppRoutes from "@fitneks-routes";

interface UserAvatarAndNameProps {
    orientation: "top" | "bottom" | "left" | "right";
    className?: string;
    avatarSize?: number;
    fontSize?: number;
    gap?: number;
    onlyAvatar?: boolean;
    isBoosted?: boolean;
    isSharing?: boolean;
}

export default function UserAvatarAndName({
    orientation,
    className,
    avatarSize,
    fontSize,
    gap,
    onlyAvatar,
    isBoosted,
    isSharing
}: UserAvatarAndNameProps) {
    const { userID, username } = useAuth();
    const { addNotification } = useContext(ThemeContext);

    if (!userID) {
        return <></>;
    }

    const { data, loading } = useGetUserAvatarAndNameQuery({
        variables: {
            input: userID,
        },
    });

    const copyProfileUrl = () => {
        if (username) {
            const url = import.meta.env.FITNEKS_APP_URL + AppRoutes.userProfile.route(username);
            navigator.clipboard.writeText(url).then(() => {
                addNotification({
                    message: "Profile address copied to clipboard",
                    type: "information",
                    duration: 3000,
                    close: "timeOut",
                });
            });
        }
    };

    return (
        <>
            <div
                className={classNames(css["user-avatar"], css[`orientation-${orientation}`], className)}
                style={{ fontSize: `${fontSize}rem`, gap: `${gap}px` }}
            >
                <Shimmer visible={loading} position={"unset"} circle>
                    <div
                        className={classNames(
                            css["user-avatar-img"],
                            isBoosted && "purple-gradient p-3 rounded",
                            isBoosted && css["is-boosted"]
                        )}
                        style={{ width: `${avatarSize}px` }}
                    >
                        <Cover
                            src={data?.user?.avatarMediaObject?.filePath ?? data?.user?.OAuthAvatar ?? DefaultAvatar}
                            title={data?.user?.displayName ?? "loading..."}
                            rounded
                            className={classNames(isBoosted && "bg-white")}
                        />
                    </div>
                </Shimmer>
                {!onlyAvatar && (
                    <div className={classNames(css["user-avatar-info"], "cursor-pointer")} onClick={copyProfileUrl}>
                        <Shimmer visible={loading} position={"unset"} borderRadius className={"mb-2"}>
                            <Typography tag={"h2"} className={classNames(css["user-avatar-name"], "fw-500")}>
                                {data?.user?.displayName || <div style={{ width: "200px", height: "36px" }}></div>}
                                {isSharing && (
                                    <img src={"/images/share-profile-button.png"} alt={"share"}
                                         className={"ml-2"}
                                         width={"30"}
                                    />
                                )}
                            </Typography>
                        </Shimmer>
                        <Shimmer visible={loading} position={"unset"} borderRadius>
                        <Typography className={classNames(css["user-avatar-username"])}>
                                @{data?.user?.username}
                            </Typography>
                        </Shimmer>
                    </div>
                )}
            </div>
        </>
    );
}
