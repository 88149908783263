import { Checkbox, Col, getEndOfWeek, getStartOfWeek, MuscleGroup, Row, Shimmer } from "@fitneks-component-library";
import classNames from "@fitneks-commons/classnames";
import css from "@fitneks-dashboard-pages/MyProfile/organisms/ProfileLearner/ProfileLearner.module.scss";
import { Bar, Pie } from "react-chartjs-2";
import { BarElement, CategoryScale, Chart as ChartJS, ChartData, ChartOptions, LinearScale, Tooltip } from "chart.js";
import { Fragment, useEffect, useMemo, useState } from "react";
import { ChartProps, DatasetType, EarnedPoint, MuscleGroupProps } from "./EarnedPointsChart.types";
import {
    ClassEarnedPointsDocument,
    ClassEarnedPointsQuery,
    useClassEarnedPointsQuery,
    useTrainingMusclesQuery,
} from "./EarnedPointsChart.generated";
import PerPage from "@fitneks-commons/constants/PerPage";
import { useAuth } from "@fitneks-commons/hooks";
import {
    defaultUTCToTimezone,
    formatDateRange,
    getLastMonthRange,
    getLastWeekRange,
    getPartsInMonth,
} from "@fitneks-commons/helpers/functions/Date";
import { getMuscleColor } from "@fitneks-commons/ui/EarnedPointsChart/helper";
import { EmptyChart } from "./organisms";

const weekRange = getLastWeekRange();
const monthRange = getLastMonthRange();

const EarnedPointsChart = ({ period, userId, type, week, month }: ChartProps) => {
    const PER_PAGE = PerPage.max;
    const { userID, timezone } = useAuth();
    const user = userId ?? userID ?? "";
    const chartType = type ?? "chart";
    const MUSCLE_CHALLENGE = "Chlng";

    const [points, setPoints] = useState<EarnedPoint[]>([]);
    const [muscleGroupProps, setMuscleGroupProps] = useState<MuscleGroupProps>({});
    const [cursor, setCursor] = useState("");
    const [hasNextPage, setHasNextPage] = useState(false);
    const [pointsLoading, setPointsLoading] = useState(true);

    const selectedWeek = week ? formatDateRange(week) : weekRange;
    const selectedMonth = month ? formatDateRange(month) : monthRange;

    const { data: typesData, loading: typesLoading, client } = useTrainingMusclesQuery();
    const {
        data: earnedPointsData,
        loading: earnedPointsLoading,
        fetchMore: earnedPointsFetchMore,
        refetch: earnedPointsRefetch,
    } = useClassEarnedPointsQuery({
        variables: {
            first: PER_PAGE,
            user,
            createdAt: period === "week" ? selectedWeek : selectedMonth,
        },
        notifyOnNetworkStatusChange: true,
    });

    const initPoints: EarnedPoint[] = [
        ...(typesData?.trainingMuscles?.edges?.map((el) => ({
            muscle: el?.node?.name ?? "",
            count: 0,
            checked: true,
        })) ?? []),
        {
            muscle: MUSCLE_CHALLENGE,
            count: 0,
            checked: true,
        },
    ];

    useEffect(() => {
        (async () => {
            const data = getCachedData();
            if (data) {
                updatePointsData(data);
            } else {
                await earnedPointsRefetch();
            }
        })();
    }, [period]);

    useEffect(() => {
        (async () => {
            if (hasNextPage) {
                await earnedPointsFetchMore({
                    variables: {
                        first: PER_PAGE,
                        after: cursor,
                        user,
                        createdAt: period === "week" ? selectedWeek : selectedMonth,
                    },
                });
            }
        })();
    }, [hasNextPage, cursor]);

    useEffect(() => {
        if (!earnedPointsLoading && earnedPointsData) {
            const pageInfo = earnedPointsData?.trainingEarnedPoints?.pageInfo;
            setCursor(pageInfo?.endCursor || "");
            setHasNextPage(pageInfo?.hasNextPage || false);
            if (!pageInfo?.hasNextPage && !typesLoading) {
                updatePointsData(earnedPointsData);
            }
        }
    }, [earnedPointsData, earnedPointsLoading, typesLoading]);

    useEffect(() => {
        if (points?.length) {
            const newMuscleGroupProps: MuscleGroupProps = {};
            points.forEach((point) => {
                const name = point?.muscle?.toLowerCase() ?? "";
                newMuscleGroupProps[name] = point?.checked ? "stat" : 0;
            });
            setMuscleGroupProps(newMuscleGroupProps);
        }
    }, [points]);

    useEffect(() => {
        const data = getCachedData();
        if (!pointsLoading && !data) {
            setPointsLoading(true);
        }
        const timeout = setTimeout(() => {
            if (!earnedPointsLoading && typeof earnedPointsData !== "undefined" && !hasNextPage) {
                setPointsLoading(false);
            }
        }, 500);
        return () => clearTimeout(timeout);
    }, [earnedPointsLoading, earnedPointsData, hasNextPage, period]);

    const getCachedData = () => {
        return client.cache.readQuery<ClassEarnedPointsQuery>({
            query: ClassEarnedPointsDocument,
            variables: {
                first: PER_PAGE,
                user,
                createdAt: period === "week" ? selectedWeek : selectedMonth,
            },
        });
    };

    // region EarnedPointsChart
    ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

    // EarnedPointsChart options
    const options: ChartOptions<"bar"> = {
        responsive: true,
        plugins: {
            legend: {
                position: "top" as const,
            },
        },
        scales: {
            x: { grid: { display: false } },
            y: { grid: { display: false } },
        },
    };

    const barData: ChartData<"bar"> = useMemo(() => {
        if (chartType === "pie" || !typesData || !earnedPointsData) {
            return { datasets: [] };
        }
        const labels =
            period === "month" ? ["W1", "W2", "W3", "W4"] : ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

        const now = new Date();

        const datasets: DatasetType[] =
            points?.map((el) => {
                const data: number[] = [];

                if (el.checked) {
                    if (period === "week") {
                        const endDate = week ? new Date(week.endDate.getTime()) : getEndOfWeek(now);
                        const start = week ? new Date(week.startDate.getTime()) : getStartOfWeek(now);

                        while (start <= endDate && start <= now) {
                            let count = 0;
                            const end = new Date(start.getTime());
                            end.setDate(start.getDate() + 7);

                            earnedPointsData?.trainingEarnedPoints?.edges?.forEach((item) => {
                                if (
                                    item?.node?.createdAt &&
                                    (item?.node?.trainingMuscle?.name === el?.muscle ||
                                        (!item?.node?.trainingMuscle && el.muscle === MUSCLE_CHALLENGE))
                                ) {
                                    const date = new Date(defaultUTCToTimezone(item.node.createdAt, timezone));
                                    if (start.getDate() == date.getDate()) {
                                        count += +item?.node?.points;
                                    }
                                }
                            });
                            data.push(count);
                            start.setDate(start.getDate() + 1);
                        }
                    }

                    if (period === "month") {
                        const weeks = getPartsInMonth(now.getMonth() + 1, now.getFullYear());
                        weeks.forEach((week) => {
                            let count = 0;
                            earnedPointsData?.trainingEarnedPoints?.edges?.forEach((item) => {
                                if (
                                    item?.node?.createdAt &&
                                    (item?.node?.trainingMuscle?.name === el?.muscle ||
                                        (!item?.node?.trainingMuscle && el.muscle === MUSCLE_CHALLENGE))
                                ) {
                                    const date = new Date(defaultUTCToTimezone(item.node.createdAt, timezone));
                                    if (date >= week.start && date <= week.end) {
                                        count += +item?.node?.points;
                                    }
                                }
                            });
                            data.push(count);
                        });
                    }
                }

                return {
                    label: el?.muscle,
                    data,
                    backgroundColor: getMuscleColor(el?.muscle),
                    stack: "Stack 0",
                    borderWidth: 0,
                };
            }) ?? [];

        return { labels, datasets };
    }, [points]);

    const pieData: ChartData<"pie"> = useMemo(() => {
        if (chartType === "chart" || !typesData || !earnedPointsData) {
            return { datasets: [] };
        }
        const labels: string[] = [];
        const data: number[] = [];
        const color: string[] = [];
        points.forEach((el) => {
            data.push(el?.checked ? +el.count : 0);
            color.push(getMuscleColor(el.muscle));
            labels.push(el.muscle);
        });
        return {
            labels,
            datasets: [
                {
                    data,
                    backgroundColor: color,
                    borderColor: color,
                    borderWidth: 1,
                },
            ],
        };
    }, [points]);

    // endregion EarnedPointsChart

    function updatePointsData(data: ClassEarnedPointsQuery) {
        let newPoints = [...initPoints];
        newPoints = newPoints.map((el) => {
            let count = 0;
            data?.trainingEarnedPoints?.edges?.forEach((point) => {
                if (
                    point?.node?.points &&
                    (point?.node?.trainingMuscle?.name === el?.muscle ||
                        (!point?.node?.trainingMuscle && el?.muscle === MUSCLE_CHALLENGE))
                ) {
                    count += point?.node?.points;
                }
            });
            return { ...el, count };
        });
        setPoints(newPoints);
    }

    const onSelectChange = (point: EarnedPoint) => {
        const newPoints = points.map((el) => {
            if (el?.muscle === point?.muscle) {
                return {
                    ...el,
                    checked: !el.checked,
                };
            }
            return el;
        });
        setPoints(newPoints);
    };

    const pointsExist = !!points.filter((el) => el.count).length;

    return (
        <Row gapX={15} gapY={15} className={"clearboth mt-2"}>
            <Col xs={12} md={6}>
                <Row>
                    <Col xs={8} className={classNames(css[""], "align-self-center px-3")}>
                        <MuscleGroup {...(!pointsLoading && muscleGroupProps)} />
                    </Col>

                    <Col xs={4} className={classNames(css[""], "align-self-center")}>
                        {!typesLoading && !pointsLoading
                            ? points.map((point) => {
                                  return (
                                      <Fragment key={`point-${point.muscle}`}>
                                          <Checkbox
                                              className={classNames(
                                                  css["checkbox"],
                                                  css[`checkbox-${point?.muscle?.toLowerCase()}`],
                                                  !!point?.checked && css["is-checked"],
                                                  "mb-3"
                                              )}
                                              name={point?.muscle?.toLowerCase() ?? "name"}
                                              label={`${point?.muscle} (${point?.count})`}
                                              checked={!!point?.checked}
                                              onChange={() => onSelectChange(point)}
                                              size={"small"}
                                          />
                                          <br />
                                      </Fragment>
                                  );
                              })
                            : Array(5)
                                  .fill(undefined)
                                  .map((_, idx) => {
                                      return (
                                          <Shimmer
                                              key={`point-shimmer-${idx}`}
                                              visible={true}
                                              position={"unset"}
                                              borderRadius
                                              className={"mb-3"}
                                          >
                                              <div style={{ height: "25px", width: "100%" }}></div>
                                          </Shimmer>
                                      );
                                  })}
                    </Col>
                </Row>
            </Col>
            <Col xs={12} md={6} className={classNames(css[""], "align-self-center px-2")}>
                <Shimmer visible={typesLoading || pointsLoading} position={"unset"}>
                    {(pointsExist || typesLoading || pointsLoading) && (
                        <>
                            {chartType === "chart" && <Bar options={options} data={barData} />}
                            {chartType === "pie" && <Pie data={pieData} className={"mx-auto"} />}
                        </>
                    )}
                    {!pointsExist && !typesLoading && !pointsLoading && <EmptyChart />}
                </Shimmer>
            </Col>
        </Row>
    );
};

export default EarnedPointsChart;
