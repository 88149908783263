/* eslint-disable */
/* tslint:disable */
/* THIS IS A GENERATED FILE, DO NOT EDIT. */
import * as Types from '@fitneks-types/Graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUserTrainingTypesQueryVariables = Types.Exact<{
  input: Types.Scalars['String'];
  first: Types.Scalars['Int'];
  after?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetUserTrainingTypesQuery = { readonly trainingTypes?: { readonly edges?: ReadonlyArray<{ readonly node?: { readonly id: string, readonly name: string } | null } | null> | null, readonly pageInfo: { readonly endCursor?: string | null, readonly hasNextPage: boolean } } | null };

export type GetTrainingTypesQueryVariables = Types.Exact<{
  first: Types.Scalars['Int'];
  after?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetTrainingTypesQuery = { readonly trainingTypes?: { readonly edges?: ReadonlyArray<{ readonly node?: { readonly id: string, readonly name: string, readonly iconMediaObject?: { readonly filePath?: string | null } | null } | null } | null> | null, readonly pageInfo: { readonly endCursor?: string | null, readonly hasNextPage: boolean } } | null };


export const GetUserTrainingTypesDocument = gql`
    query getUserTrainingTypes($input: String!, $first: Int!, $after: String) {
  trainingTypes(first: $first, after: $after, users: $input) {
    edges {
      node {
        id
        name
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetUserTrainingTypesQuery__
 *
 * To run a query within a React component, call `useGetUserTrainingTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserTrainingTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserTrainingTypesQuery({
 *   variables: {
 *      input: // value for 'input'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetUserTrainingTypesQuery(baseOptions: Apollo.QueryHookOptions<GetUserTrainingTypesQuery, GetUserTrainingTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserTrainingTypesQuery, GetUserTrainingTypesQueryVariables>(GetUserTrainingTypesDocument, options);
      }
export function useGetUserTrainingTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserTrainingTypesQuery, GetUserTrainingTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserTrainingTypesQuery, GetUserTrainingTypesQueryVariables>(GetUserTrainingTypesDocument, options);
        }
export type GetUserTrainingTypesQueryHookResult = ReturnType<typeof useGetUserTrainingTypesQuery>;
export type GetUserTrainingTypesLazyQueryHookResult = ReturnType<typeof useGetUserTrainingTypesLazyQuery>;
export type GetUserTrainingTypesQueryResult = Apollo.QueryResult<GetUserTrainingTypesQuery, GetUserTrainingTypesQueryVariables>;
export const GetTrainingTypesDocument = gql`
    query getTrainingTypes($first: Int!, $after: String) {
  trainingTypes(first: $first, after: $after) {
    edges {
      node {
        id
        name
        iconMediaObject {
          filePath
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetTrainingTypesQuery__
 *
 * To run a query within a React component, call `useGetTrainingTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrainingTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrainingTypesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetTrainingTypesQuery(baseOptions: Apollo.QueryHookOptions<GetTrainingTypesQuery, GetTrainingTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTrainingTypesQuery, GetTrainingTypesQueryVariables>(GetTrainingTypesDocument, options);
      }
export function useGetTrainingTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTrainingTypesQuery, GetTrainingTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTrainingTypesQuery, GetTrainingTypesQueryVariables>(GetTrainingTypesDocument, options);
        }
export type GetTrainingTypesQueryHookResult = ReturnType<typeof useGetTrainingTypesQuery>;
export type GetTrainingTypesLazyQueryHookResult = ReturnType<typeof useGetTrainingTypesLazyQuery>;
export type GetTrainingTypesQueryResult = Apollo.QueryResult<GetTrainingTypesQuery, GetTrainingTypesQueryVariables>;