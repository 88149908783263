/* eslint-disable */
/* tslint:disable */
/* THIS IS A GENERATED FILE, DO NOT EDIT. */
import * as Types from '@fitneks-types/Graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StreamViewersQueryVariables = Types.Exact<{
  first: Types.Scalars['Int'];
  stream: Types.Scalars['String'];
  search: Types.Scalars['String'];
  after?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type StreamViewersQuery = { readonly videoStreamViewers?: { readonly edges?: ReadonlyArray<{ readonly node?: { readonly id: string, readonly user?: { readonly id: string, readonly displayName: string, readonly username: string, readonly OAuthAvatar?: string | null, readonly avatarMediaObject?: { readonly id: string, readonly filePath?: string | null } | null } | null } | null } | null> | null, readonly pageInfo: { readonly hasNextPage: boolean, readonly endCursor?: string | null } } | null };

export type DeleteViewerMutationVariables = Types.Exact<{
  input: Types.BlockUserFromVideoStreamInput;
}>;


export type DeleteViewerMutation = { readonly blockUserFromVideoStream?: { readonly videoStream?: { readonly id: string } | null } | null };


export const StreamViewersDocument = gql`
    query StreamViewers($first: Int!, $stream: String!, $search: String!, $after: String) {
  videoStreamViewers(
    first: $first
    videoStream_guid: $stream
    user_displayName: $search
    after: $after
  ) {
    edges {
      node {
        id
        user {
          id
          displayName
          username
          avatarMediaObject {
            id
            filePath
          }
          OAuthAvatar
        }
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    `;

/**
 * __useStreamViewersQuery__
 *
 * To run a query within a React component, call `useStreamViewersQuery` and pass it any options that fit your needs.
 * When your component renders, `useStreamViewersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStreamViewersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      stream: // value for 'stream'
 *      search: // value for 'search'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useStreamViewersQuery(baseOptions: Apollo.QueryHookOptions<StreamViewersQuery, StreamViewersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StreamViewersQuery, StreamViewersQueryVariables>(StreamViewersDocument, options);
      }
export function useStreamViewersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StreamViewersQuery, StreamViewersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StreamViewersQuery, StreamViewersQueryVariables>(StreamViewersDocument, options);
        }
export type StreamViewersQueryHookResult = ReturnType<typeof useStreamViewersQuery>;
export type StreamViewersLazyQueryHookResult = ReturnType<typeof useStreamViewersLazyQuery>;
export type StreamViewersQueryResult = Apollo.QueryResult<StreamViewersQuery, StreamViewersQueryVariables>;
export const DeleteViewerDocument = gql`
    mutation deleteViewer($input: blockUserFromVideoStreamInput!) {
  blockUserFromVideoStream(input: $input) {
    videoStream {
      id
    }
  }
}
    `;
export type DeleteViewerMutationFn = Apollo.MutationFunction<DeleteViewerMutation, DeleteViewerMutationVariables>;

/**
 * __useDeleteViewerMutation__
 *
 * To run a mutation, you first call `useDeleteViewerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteViewerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteViewerMutation, { data, loading, error }] = useDeleteViewerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteViewerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteViewerMutation, DeleteViewerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteViewerMutation, DeleteViewerMutationVariables>(DeleteViewerDocument, options);
      }
export type DeleteViewerMutationHookResult = ReturnType<typeof useDeleteViewerMutation>;
export type DeleteViewerMutationResult = Apollo.MutationResult<DeleteViewerMutation>;
export type DeleteViewerMutationOptions = Apollo.BaseMutationOptions<DeleteViewerMutation, DeleteViewerMutationVariables>;