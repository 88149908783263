/* eslint-disable */
/* tslint:disable */
/* THIS IS A GENERATED FILE, DO NOT EDIT. */
import * as Types from '@fitneks-types/Graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUserTrainingMusclesQueryVariables = Types.Exact<{
  input: Types.Scalars['String'];
  first: Types.Scalars['Int'];
  after?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetUserTrainingMusclesQuery = { readonly trainingMuscles?: { readonly edges?: ReadonlyArray<{ readonly node?: { readonly id: string, readonly name: string } | null } | null> | null, readonly pageInfo: { readonly endCursor?: string | null, readonly hasNextPage: boolean } } | null };

export type GetTrainingMusclesQueryVariables = Types.Exact<{
  first: Types.Scalars['Int'];
  after?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetTrainingMusclesQuery = { readonly trainingMuscles?: { readonly edges?: ReadonlyArray<{ readonly node?: { readonly id: string, readonly name: string } | null } | null> | null, readonly pageInfo: { readonly endCursor?: string | null, readonly hasNextPage: boolean } } | null };


export const GetUserTrainingMusclesDocument = gql`
    query getUserTrainingMuscles($input: String!, $first: Int!, $after: String) {
  trainingMuscles(first: $first, after: $after, users: $input) {
    edges {
      node {
        id
        name
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetUserTrainingMusclesQuery__
 *
 * To run a query within a React component, call `useGetUserTrainingMusclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserTrainingMusclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserTrainingMusclesQuery({
 *   variables: {
 *      input: // value for 'input'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetUserTrainingMusclesQuery(baseOptions: Apollo.QueryHookOptions<GetUserTrainingMusclesQuery, GetUserTrainingMusclesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserTrainingMusclesQuery, GetUserTrainingMusclesQueryVariables>(GetUserTrainingMusclesDocument, options);
      }
export function useGetUserTrainingMusclesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserTrainingMusclesQuery, GetUserTrainingMusclesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserTrainingMusclesQuery, GetUserTrainingMusclesQueryVariables>(GetUserTrainingMusclesDocument, options);
        }
export type GetUserTrainingMusclesQueryHookResult = ReturnType<typeof useGetUserTrainingMusclesQuery>;
export type GetUserTrainingMusclesLazyQueryHookResult = ReturnType<typeof useGetUserTrainingMusclesLazyQuery>;
export type GetUserTrainingMusclesQueryResult = Apollo.QueryResult<GetUserTrainingMusclesQuery, GetUserTrainingMusclesQueryVariables>;
export const GetTrainingMusclesDocument = gql`
    query getTrainingMuscles($first: Int!, $after: String) {
  trainingMuscles(first: $first, after: $after) {
    edges {
      node {
        id
        name
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetTrainingMusclesQuery__
 *
 * To run a query within a React component, call `useGetTrainingMusclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrainingMusclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrainingMusclesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetTrainingMusclesQuery(baseOptions: Apollo.QueryHookOptions<GetTrainingMusclesQuery, GetTrainingMusclesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTrainingMusclesQuery, GetTrainingMusclesQueryVariables>(GetTrainingMusclesDocument, options);
      }
export function useGetTrainingMusclesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTrainingMusclesQuery, GetTrainingMusclesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTrainingMusclesQuery, GetTrainingMusclesQueryVariables>(GetTrainingMusclesDocument, options);
        }
export type GetTrainingMusclesQueryHookResult = ReturnType<typeof useGetTrainingMusclesQuery>;
export type GetTrainingMusclesLazyQueryHookResult = ReturnType<typeof useGetTrainingMusclesLazyQuery>;
export type GetTrainingMusclesQueryResult = Apollo.QueryResult<GetTrainingMusclesQuery, GetTrainingMusclesQueryVariables>;