import { checkMatchMedia } from "@fitneks-commons/checkMatchMedia";
import { DeviceType } from "@fitneks-commons/checkMatchMedia/types";
import { useEffect, useRef, useState } from "react";
import { install } from "resize-observer";

/**
 * Helper function to test predefined match queries against current device on window Resize
 * @param {DeviceType} deviceType Device type to test
 *
 * @return boolean
 */
export function getDeviceType(device: DeviceType): boolean {
    if (!window.ResizeObserver) install();

    const firstLoad = checkMatchMedia(device);
    const [isDeviceType, setIsDeviceType] = useState(firstLoad);

    const observer = useRef(
        new ResizeObserver(() => {
            setIsDeviceType(checkMatchMedia(device));
        })
    );

    useEffect(() => {
        if (document.body) {
            observer.current.observe(document.body);
        }

        return () => {
            observer.current.unobserve(document.body);
        };
    }, [document.body, observer]);

    return isDeviceType;
}
