import { SyntheticEvent, useState } from "react";
import Icon from "../Icon/Icon";
import { SearchProps, Style } from "./Search.types";
import { IconName } from "@fitneks-component-library";
import classNames from "@fitneks-commons/classnames";
import css from "./Search.module.scss";

/**
 * Search component
 *
 * @param {SearchProps} props Component props
 * @param {Style} props.style - different types of styling for Input - icon inside/outside/with text
 * @param {string} props.placeholder - text that should be shown on placeholder
 * @param {string} props.value - value that input gets
 * @param {number} props.height - height of input
 * @param {number} props.width - width of inout
 * @param {function} props.onChange - sending the value of user's typed input
 * @param {function} props.onSubmit - function for submitting search
 *
 */

const Search = (props: SearchProps) => {
    const [openInput, setOpenInput] = useState(false);
    const style: Style = props.style || "inside";

    const handleKeyDown = (e: { keyCode: number }) => {
        if (e.keyCode === 13 && typeof props.onSubmit != "undefined") {
            props.onSubmit();
        }
    };

    function handleSubmit(event: SyntheticEvent) {
        event.stopPropagation();
        if (style === "with-icon") {
            setOpenInput(true);
        } else {
            typeof props.onSubmit != "undefined" && props.onSubmit();
        }
    }

    return (
        <div
            role="search"
            className={classNames(
                props.className,
                css["search-default"],
                css[style],
                style === "with-icon" && !openInput && css["hide-background"]
            )}
            ref={props.htmlRef}
            style={{ height: `${props.height}px`, width: `${props.width}px` }}
        >
            <div className={classNames(css["search"])}>
                <input
                    type="text"
                    placeholder={props.placeholder}
                    className={classNames(css["search-input"])}
                    onKeyDown={handleKeyDown}
                    autoFocus={openInput && true}
                    value={props.value}
                    onChange={(e) => props.onChange(e.target.value)}
                />
            </div>
            <div className={classNames(css["icon-text-wrapper"])}>
                <span onClick={handleSubmit} className={classNames(css["icon-wrapper"])}>
                    <Icon name={IconName.Search} className={classNames(css["icon"])} width={20} height={20} />
                </span>
                {style === "with-icon" && <span className={classNames(css["search-text"])}> Search </span>}
            </div>
        </div>
    );
};

export default Search;
