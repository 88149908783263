/* eslint-disable */
/* tslint:disable */
/* THIS IS A GENERATED FILE, DO NOT EDIT. */
import * as Types from '@fitneks-types/Graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetLearnerStreamQueryVariables = Types.Exact<{
  username: Types.Scalars['String'];
}>;


export type GetLearnerStreamQuery = { readonly withUsernameVideoStream?: { readonly id: string, readonly guid: string, readonly title: string, readonly description?: string | null, readonly private: boolean, readonly isLive: boolean, readonly liveKitSid?: string | null, readonly videoStreamViewersCount: number, readonly participantsCount: number, readonly trainingRate?: number | null, readonly token: string, readonly videoStreamType?: { readonly id: string, readonly name: string } | null, readonly user?: { readonly id: string, readonly username: string, readonly displayName: string, readonly followersCount: number, readonly currentUserIsFollower: boolean, readonly OAuthAvatar?: string | null, readonly avatarMediaObject?: { readonly id: string, readonly filePath?: string | null } | null } | null } | null };

export type JoinTrainerStreamMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type JoinTrainerStreamMutation = { readonly joinVideoStream?: { readonly videoStream?: { readonly id: string, readonly token: string, readonly guid: string, readonly liveKitSid?: string | null } | null } | null };

export type LeaveStreamMutationVariables = Types.Exact<{
  input: Types.OffFromVideoStreamInput;
}>;


export type LeaveStreamMutation = { readonly offFromVideoStream?: { readonly videoStream?: { readonly id: string } | null } | null };

export type SaveStreamInfoMutationVariables = Types.Exact<{
  input: Types.SaveVideoStreamInfoInput;
}>;


export type SaveStreamInfoMutation = { readonly saveVideoStreamInfo?: { readonly videoStreamInfo?: { readonly id: string } | null } | null };

export type GetParticipantsQueryVariables = Types.Exact<{
  streamGuid?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetParticipantsQuery = { readonly getParticipantsVideoStream?: { readonly participantsCount: number } | null };


export const GetLearnerStreamDocument = gql`
    query getLearnerStream($username: String!) {
  withUsernameVideoStream(username: $username) {
    id
    guid
    title
    description
    private
    isLive
    liveKitSid
    videoStreamViewersCount
    participantsCount
    trainingRate
    token
    videoStreamType {
      id
      name
    }
    user {
      id
      username
      displayName
      followersCount
      currentUserIsFollower
      avatarMediaObject {
        id
        filePath
      }
      OAuthAvatar
    }
  }
}
    `;

/**
 * __useGetLearnerStreamQuery__
 *
 * To run a query within a React component, call `useGetLearnerStreamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLearnerStreamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLearnerStreamQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useGetLearnerStreamQuery(baseOptions: Apollo.QueryHookOptions<GetLearnerStreamQuery, GetLearnerStreamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLearnerStreamQuery, GetLearnerStreamQueryVariables>(GetLearnerStreamDocument, options);
      }
export function useGetLearnerStreamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLearnerStreamQuery, GetLearnerStreamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLearnerStreamQuery, GetLearnerStreamQueryVariables>(GetLearnerStreamDocument, options);
        }
export type GetLearnerStreamQueryHookResult = ReturnType<typeof useGetLearnerStreamQuery>;
export type GetLearnerStreamLazyQueryHookResult = ReturnType<typeof useGetLearnerStreamLazyQuery>;
export type GetLearnerStreamQueryResult = Apollo.QueryResult<GetLearnerStreamQuery, GetLearnerStreamQueryVariables>;
export const JoinTrainerStreamDocument = gql`
    mutation JoinTrainerStream($id: ID!) {
  joinVideoStream(input: {id: $id}) {
    videoStream {
      id
      token
      guid
      liveKitSid
    }
  }
}
    `;
export type JoinTrainerStreamMutationFn = Apollo.MutationFunction<JoinTrainerStreamMutation, JoinTrainerStreamMutationVariables>;

/**
 * __useJoinTrainerStreamMutation__
 *
 * To run a mutation, you first call `useJoinTrainerStreamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinTrainerStreamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinTrainerStreamMutation, { data, loading, error }] = useJoinTrainerStreamMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJoinTrainerStreamMutation(baseOptions?: Apollo.MutationHookOptions<JoinTrainerStreamMutation, JoinTrainerStreamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JoinTrainerStreamMutation, JoinTrainerStreamMutationVariables>(JoinTrainerStreamDocument, options);
      }
export type JoinTrainerStreamMutationHookResult = ReturnType<typeof useJoinTrainerStreamMutation>;
export type JoinTrainerStreamMutationResult = Apollo.MutationResult<JoinTrainerStreamMutation>;
export type JoinTrainerStreamMutationOptions = Apollo.BaseMutationOptions<JoinTrainerStreamMutation, JoinTrainerStreamMutationVariables>;
export const LeaveStreamDocument = gql`
    mutation LeaveStream($input: offFromVideoStreamInput!) {
  offFromVideoStream(input: $input) {
    videoStream {
      id
    }
  }
}
    `;
export type LeaveStreamMutationFn = Apollo.MutationFunction<LeaveStreamMutation, LeaveStreamMutationVariables>;

/**
 * __useLeaveStreamMutation__
 *
 * To run a mutation, you first call `useLeaveStreamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveStreamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveStreamMutation, { data, loading, error }] = useLeaveStreamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLeaveStreamMutation(baseOptions?: Apollo.MutationHookOptions<LeaveStreamMutation, LeaveStreamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LeaveStreamMutation, LeaveStreamMutationVariables>(LeaveStreamDocument, options);
      }
export type LeaveStreamMutationHookResult = ReturnType<typeof useLeaveStreamMutation>;
export type LeaveStreamMutationResult = Apollo.MutationResult<LeaveStreamMutation>;
export type LeaveStreamMutationOptions = Apollo.BaseMutationOptions<LeaveStreamMutation, LeaveStreamMutationVariables>;
export const SaveStreamInfoDocument = gql`
    mutation SaveStreamInfo($input: saveVideoStreamInfoInput!) {
  saveVideoStreamInfo(input: $input) {
    videoStreamInfo {
      id
    }
  }
}
    `;
export type SaveStreamInfoMutationFn = Apollo.MutationFunction<SaveStreamInfoMutation, SaveStreamInfoMutationVariables>;

/**
 * __useSaveStreamInfoMutation__
 *
 * To run a mutation, you first call `useSaveStreamInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveStreamInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveStreamInfoMutation, { data, loading, error }] = useSaveStreamInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveStreamInfoMutation(baseOptions?: Apollo.MutationHookOptions<SaveStreamInfoMutation, SaveStreamInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveStreamInfoMutation, SaveStreamInfoMutationVariables>(SaveStreamInfoDocument, options);
      }
export type SaveStreamInfoMutationHookResult = ReturnType<typeof useSaveStreamInfoMutation>;
export type SaveStreamInfoMutationResult = Apollo.MutationResult<SaveStreamInfoMutation>;
export type SaveStreamInfoMutationOptions = Apollo.BaseMutationOptions<SaveStreamInfoMutation, SaveStreamInfoMutationVariables>;
export const GetParticipantsDocument = gql`
    query getParticipants($streamGuid: String) {
  getParticipantsVideoStream(streamGuid: $streamGuid) {
    participantsCount
  }
}
    `;

/**
 * __useGetParticipantsQuery__
 *
 * To run a query within a React component, call `useGetParticipantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParticipantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParticipantsQuery({
 *   variables: {
 *      streamGuid: // value for 'streamGuid'
 *   },
 * });
 */
export function useGetParticipantsQuery(baseOptions?: Apollo.QueryHookOptions<GetParticipantsQuery, GetParticipantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetParticipantsQuery, GetParticipantsQueryVariables>(GetParticipantsDocument, options);
      }
export function useGetParticipantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetParticipantsQuery, GetParticipantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetParticipantsQuery, GetParticipantsQueryVariables>(GetParticipantsDocument, options);
        }
export type GetParticipantsQueryHookResult = ReturnType<typeof useGetParticipantsQuery>;
export type GetParticipantsLazyQueryHookResult = ReturnType<typeof useGetParticipantsLazyQuery>;
export type GetParticipantsQueryResult = Apollo.QueryResult<GetParticipantsQuery, GetParticipantsQueryVariables>;