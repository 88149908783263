import { CloseOption, NotificationProps, NotificationType } from "./Notification.types";
import { Icon } from "@fitneks-component-library";
import { IconName } from "@fitneks-component-library";
import css from "./Notification.module.scss";
import classNames from "@fitneks-commons/classnames";

/**
 * Notification component
 *
 * @param {NotificationProps} props Component props
 * @param {string} props.message -  message that should be displayed on notification box
 * @param {string} props.tile -  title that should be displayed on notification box
 * @param {number} props.duration -  timeout duration during the closing process
 * @param {NotificationType} props.type - type of notification (e.g. warning)
 * @param {CloseOption} props.close - closing options for notification box
 * @param {number} props.id - id of current notification box
 * @param {function} props.onClose -  closing function
 *
 */

const Notification = (props: NotificationProps) => {
    const type: NotificationType = props.type || "information";
    const close: CloseOption = props.close || "closingIcon";
    const withIcon = close === "timeOut" || close === "closingIcon";

    const Close = () => {
        props.onClose();
    };

    if (close === "timeOut") {
        setTimeout(() => {
            Close();
        }, props.duration || 1000);
    }

    return (
        <div className={classNames(props.className, css["wrapper"])} role={"alert"}>
            <div className={classNames(css["notification"], css[type])}>
                {withIcon && (
                    <span className={css["close-btn"]} onClick={Close} aria-label={"Close Notification"}>
                        <Icon name={IconName.Cross} width={20} height={20} />
                    </span>
                )}
                {props.title && <h5 className={classNames(css["title"])}>{props.title}</h5>}
                <div className={classNames(css["message"])}>{props.message}</div>
                {close === "confirmationBtn" && (
                    <button className={classNames(css["close-txt"])} onClick={Close}>
                        OK
                    </button>
                )}
            </div>
        </div>
    );
};

export default Notification;
