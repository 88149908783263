import { useEffect } from "react";

export function useHandleClickOutside(callback?: CallableFunction) {
    useEffect(() => {
        const handleClickOutside = () => {
            if (typeof callback === "function") {
                callback();
            }
        };
        document.body.addEventListener("click", handleClickOutside);
        return () => {
            document.body.removeEventListener("click", handleClickOutside);
        };
    }, []);
}
