/* eslint-disable */
/* tslint:disable */
/* THIS IS A GENERATED FILE, DO NOT EDIT. */
import * as Types from '@fitneks-types/Graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EventStreamQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type EventStreamQuery = { readonly videoStream?: { readonly id: string, readonly guid: string, readonly currentUserSignedUp: boolean } | null };

export type ReceivedGiftsQueryVariables = Types.Exact<{
  videoStream: Types.Scalars['String'];
  first: Types.Scalars['Int'];
  after?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type ReceivedGiftsQuery = { readonly gamificationItemLogs?: { readonly edges?: ReadonlyArray<{ readonly node?: { readonly id: string, readonly type: string, readonly count: number, readonly datetime: string, readonly gamificationItem?: { readonly id: string, readonly name: string, readonly title: string, readonly color: string, readonly iconMediaObject?: { readonly filePath?: string | null } | null } | null } | null } | null> | null, readonly pageInfo: { readonly endCursor?: string | null, readonly hasNextPage: boolean } } | null };

export type SentGiftsQueryVariables = Types.Exact<{
  from: Types.Scalars['String'];
  videoStream: Types.Scalars['String'];
  first: Types.Scalars['Int'];
  after?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type SentGiftsQuery = { readonly gamificationItemLogs?: { readonly edges?: ReadonlyArray<{ readonly node?: { readonly id: string, readonly type: string, readonly count: number, readonly datetime: string, readonly gamificationItem?: { readonly id: string, readonly name: string, readonly title: string, readonly color: string, readonly iconMediaObject?: { readonly filePath?: string | null } | null } | null } | null } | null> | null, readonly pageInfo: { readonly endCursor?: string | null, readonly hasNextPage: boolean } } | null };

export type GiftItemsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GiftItemsQuery = { readonly gamificationItems?: ReadonlyArray<{ readonly id: string, readonly name: string, readonly enabled: boolean, readonly isRuby: boolean, readonly isProtein: boolean, readonly withdrawPrice: number, readonly iconMediaObject?: { readonly filePath?: string | null } | null } | null> | null };

export type StreamAdditionalInfoQueryVariables = Types.Exact<{
  streamGuid: Types.Scalars['String'];
  user: Types.Scalars['String'];
}>;


export type StreamAdditionalInfoQuery = { readonly withUsernameVideoStreamInfo?: { readonly id: string, readonly streamDuration?: number | null, readonly questionsCount?: number | null, readonly learnerPoints: number, readonly trainerPoints?: number | null } | null };

export type AddToCalendarMutationVariables = Types.Exact<{
  input: Types.SignUpVideoStreamInput;
}>;


export type AddToCalendarMutation = { readonly signUpVideoStream?: { readonly videoStream?: { readonly id: string } | null } | null };


export const EventStreamDocument = gql`
    query EventStream($id: ID!) {
  videoStream(id: $id) {
    id
    guid
    currentUserSignedUp
  }
}
    `;

/**
 * __useEventStreamQuery__
 *
 * To run a query within a React component, call `useEventStreamQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventStreamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventStreamQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventStreamQuery(baseOptions: Apollo.QueryHookOptions<EventStreamQuery, EventStreamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventStreamQuery, EventStreamQueryVariables>(EventStreamDocument, options);
      }
export function useEventStreamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventStreamQuery, EventStreamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventStreamQuery, EventStreamQueryVariables>(EventStreamDocument, options);
        }
export type EventStreamQueryHookResult = ReturnType<typeof useEventStreamQuery>;
export type EventStreamLazyQueryHookResult = ReturnType<typeof useEventStreamLazyQuery>;
export type EventStreamQueryResult = Apollo.QueryResult<EventStreamQuery, EventStreamQueryVariables>;
export const ReceivedGiftsDocument = gql`
    query ReceivedGifts($videoStream: String!, $first: Int!, $after: String) {
  gamificationItemLogs(
    videoStream_guid: $videoStream
    first: $first
    after: $after
  ) {
    edges {
      node {
        id
        type
        gamificationItem {
          id
          name
          title
          color
          iconMediaObject {
            filePath
          }
        }
        count
        datetime
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;

/**
 * __useReceivedGiftsQuery__
 *
 * To run a query within a React component, call `useReceivedGiftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReceivedGiftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReceivedGiftsQuery({
 *   variables: {
 *      videoStream: // value for 'videoStream'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useReceivedGiftsQuery(baseOptions: Apollo.QueryHookOptions<ReceivedGiftsQuery, ReceivedGiftsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReceivedGiftsQuery, ReceivedGiftsQueryVariables>(ReceivedGiftsDocument, options);
      }
export function useReceivedGiftsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReceivedGiftsQuery, ReceivedGiftsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReceivedGiftsQuery, ReceivedGiftsQueryVariables>(ReceivedGiftsDocument, options);
        }
export type ReceivedGiftsQueryHookResult = ReturnType<typeof useReceivedGiftsQuery>;
export type ReceivedGiftsLazyQueryHookResult = ReturnType<typeof useReceivedGiftsLazyQuery>;
export type ReceivedGiftsQueryResult = Apollo.QueryResult<ReceivedGiftsQuery, ReceivedGiftsQueryVariables>;
export const SentGiftsDocument = gql`
    query SentGifts($from: String!, $videoStream: String!, $first: Int!, $after: String) {
  gamificationItemLogs(
    from: $from
    videoStream_guid: $videoStream
    first: $first
    after: $after
  ) {
    edges {
      node {
        id
        type
        gamificationItem {
          id
          name
          title
          color
          iconMediaObject {
            filePath
          }
        }
        count
        datetime
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;

/**
 * __useSentGiftsQuery__
 *
 * To run a query within a React component, call `useSentGiftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSentGiftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSentGiftsQuery({
 *   variables: {
 *      from: // value for 'from'
 *      videoStream: // value for 'videoStream'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useSentGiftsQuery(baseOptions: Apollo.QueryHookOptions<SentGiftsQuery, SentGiftsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SentGiftsQuery, SentGiftsQueryVariables>(SentGiftsDocument, options);
      }
export function useSentGiftsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SentGiftsQuery, SentGiftsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SentGiftsQuery, SentGiftsQueryVariables>(SentGiftsDocument, options);
        }
export type SentGiftsQueryHookResult = ReturnType<typeof useSentGiftsQuery>;
export type SentGiftsLazyQueryHookResult = ReturnType<typeof useSentGiftsLazyQuery>;
export type SentGiftsQueryResult = Apollo.QueryResult<SentGiftsQuery, SentGiftsQueryVariables>;
export const GiftItemsDocument = gql`
    query GiftItems {
  gamificationItems {
    id
    name
    enabled
    isRuby
    isProtein
    withdrawPrice
    iconMediaObject {
      filePath
    }
  }
}
    `;

/**
 * __useGiftItemsQuery__
 *
 * To run a query within a React component, call `useGiftItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGiftItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGiftItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGiftItemsQuery(baseOptions?: Apollo.QueryHookOptions<GiftItemsQuery, GiftItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GiftItemsQuery, GiftItemsQueryVariables>(GiftItemsDocument, options);
      }
export function useGiftItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GiftItemsQuery, GiftItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GiftItemsQuery, GiftItemsQueryVariables>(GiftItemsDocument, options);
        }
export type GiftItemsQueryHookResult = ReturnType<typeof useGiftItemsQuery>;
export type GiftItemsLazyQueryHookResult = ReturnType<typeof useGiftItemsLazyQuery>;
export type GiftItemsQueryResult = Apollo.QueryResult<GiftItemsQuery, GiftItemsQueryVariables>;
export const StreamAdditionalInfoDocument = gql`
    query StreamAdditionalInfo($streamGuid: String!, $user: String!) {
  withUsernameVideoStreamInfo(videoStream: $streamGuid, username: $user) {
    id
    streamDuration
    questionsCount
    learnerPoints
    trainerPoints
  }
}
    `;

/**
 * __useStreamAdditionalInfoQuery__
 *
 * To run a query within a React component, call `useStreamAdditionalInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useStreamAdditionalInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStreamAdditionalInfoQuery({
 *   variables: {
 *      streamGuid: // value for 'streamGuid'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useStreamAdditionalInfoQuery(baseOptions: Apollo.QueryHookOptions<StreamAdditionalInfoQuery, StreamAdditionalInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StreamAdditionalInfoQuery, StreamAdditionalInfoQueryVariables>(StreamAdditionalInfoDocument, options);
      }
export function useStreamAdditionalInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StreamAdditionalInfoQuery, StreamAdditionalInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StreamAdditionalInfoQuery, StreamAdditionalInfoQueryVariables>(StreamAdditionalInfoDocument, options);
        }
export type StreamAdditionalInfoQueryHookResult = ReturnType<typeof useStreamAdditionalInfoQuery>;
export type StreamAdditionalInfoLazyQueryHookResult = ReturnType<typeof useStreamAdditionalInfoLazyQuery>;
export type StreamAdditionalInfoQueryResult = Apollo.QueryResult<StreamAdditionalInfoQuery, StreamAdditionalInfoQueryVariables>;
export const AddToCalendarDocument = gql`
    mutation AddToCalendar($input: signUpVideoStreamInput!) {
  signUpVideoStream(input: $input) {
    videoStream {
      id
    }
  }
}
    `;
export type AddToCalendarMutationFn = Apollo.MutationFunction<AddToCalendarMutation, AddToCalendarMutationVariables>;

/**
 * __useAddToCalendarMutation__
 *
 * To run a mutation, you first call `useAddToCalendarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToCalendarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToCalendarMutation, { data, loading, error }] = useAddToCalendarMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddToCalendarMutation(baseOptions?: Apollo.MutationHookOptions<AddToCalendarMutation, AddToCalendarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddToCalendarMutation, AddToCalendarMutationVariables>(AddToCalendarDocument, options);
      }
export type AddToCalendarMutationHookResult = ReturnType<typeof useAddToCalendarMutation>;
export type AddToCalendarMutationResult = Apollo.MutationResult<AddToCalendarMutation>;
export type AddToCalendarMutationOptions = Apollo.BaseMutationOptions<AddToCalendarMutation, AddToCalendarMutationVariables>;