// TODO add styles
import classNames from "@fitneks-commons/classnames";
import css from "./PageLoading.module.scss";
import { PageLoadingProps } from "@fitneks-commons/ui";

const PageLoading = ({ width, height, spinnerSize }: PageLoadingProps) => {
    const size = spinnerSize || 50;
    return (
        <div
            style={{ width: width, height: height }}
            className={classNames(css["suspense-container"], !width && !height && css["suspense-full"])}
        >
            <div className={classNames(css["spinner-container"])}>
                <div
                    className={classNames(css["loading-spinner"])}
                    style={{ width: `${size}px`, height: `${size}px` }}
                ></div>
            </div>
        </div>
    );
};

export default PageLoading;
