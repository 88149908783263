/* eslint-disable */
/* tslint:disable */
/* THIS IS A GENERATED FILE, DO NOT EDIT. */
import * as Types from '@fitneks-types/Graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RegisterMutationVariables = Types.Exact<{
  input: Types.CreateUserInput;
}>;


export type RegisterMutation = { readonly createUser?: { readonly user?: { readonly id: string, readonly guid: string, readonly username: string, readonly roles: any, readonly token?: string | null, readonly refreshToken?: string | null, readonly timezone: string, readonly warning: boolean } | null } | null };

export type SendVerificationLinkMutationVariables = Types.Exact<{
  input: Types.SendVerificationLinkUserInput;
}>;


export type SendVerificationLinkMutation = { readonly sendVerificationLinkUser?: { readonly user?: { readonly id: string } | null } | null };


export const RegisterDocument = gql`
    mutation Register($input: createUserInput!) {
  createUser(input: $input) {
    user {
      id
      guid
      username
      roles
      token
      refreshToken
      timezone
      warning
    }
  }
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const SendVerificationLinkDocument = gql`
    mutation SendVerificationLink($input: sendVerificationLinkUserInput!) {
  sendVerificationLinkUser(input: $input) {
    user {
      id
    }
  }
}
    `;
export type SendVerificationLinkMutationFn = Apollo.MutationFunction<SendVerificationLinkMutation, SendVerificationLinkMutationVariables>;

/**
 * __useSendVerificationLinkMutation__
 *
 * To run a mutation, you first call `useSendVerificationLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendVerificationLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendVerificationLinkMutation, { data, loading, error }] = useSendVerificationLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendVerificationLinkMutation(baseOptions?: Apollo.MutationHookOptions<SendVerificationLinkMutation, SendVerificationLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendVerificationLinkMutation, SendVerificationLinkMutationVariables>(SendVerificationLinkDocument, options);
      }
export type SendVerificationLinkMutationHookResult = ReturnType<typeof useSendVerificationLinkMutation>;
export type SendVerificationLinkMutationResult = Apollo.MutationResult<SendVerificationLinkMutation>;
export type SendVerificationLinkMutationOptions = Apollo.BaseMutationOptions<SendVerificationLinkMutation, SendVerificationLinkMutationVariables>;