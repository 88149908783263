import { useContext } from "react";
import { Outlet } from "react-router-dom";
import { ThemeContext } from "@fitneks-providers";
import { Device } from "@fitneks-component-library";
import CssVariables from "../CssVariables/CssVariables";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import BottomBar from "../BottomBar/BottomBar";
import Notifications from "../Notifications/Notifications";
import OnRouteChange from "../OnRouteChange/OnRouteChange";
import { useAuth } from "@fitneks-commons/hooks";
import { decodeJWT } from "@fitneks-commons/helpers/functions/Decode";

export default function RootLayout() {
    const { page } = useContext(ThemeContext);
    const { accessToken, onLogout } = useAuth();

    function checkToken() {
        const jwt = decodeJWT(accessToken);
        if (jwt) {
            const now = new Date();
            const expireIn = new Date(jwt.payload.exp * 1000);
            if (expireIn < now) {
                localStorage.clear();
                onLogout();
            }
        }
    }
    checkToken();

    return (
        <>
            <CssVariables />
            <Navbar />
            <OnRouteChange>
                <main>
                    <Outlet />
                </main>
            </OnRouteChange>
            <Device type={"desktop"}>
                <Footer />
            </Device>
            <Device type={"other"}>
                {/*Show Bottom bar if not Stream page */}
                {page !== "stream" && <BottomBar />}
            </Device>
            <Notifications />
        </>
    );
}
