import { RefObject, useEffect, useState } from "react";
import { Position, ShimmerProps, Size, SizeType } from "./Shimmer.types";
import classNames from "@fitneks-commons/classnames";
import css from "./Shimmer.module.scss";

function isSize(input: Size | number | undefined): input is Size {
    return input instanceof Size;
}

/**
 * Shimmer component
 *
 * @param {ShimmerProps} props Component props
 * @param {boolean} props.circle - style for round components
 * @param {boolean} props.borderRadius - apple round border
 * @param {Size | number} props.propSize -  the size of shimmer
 * @param {boolean} props.isVisible -  checking the visibility for shimmer
 *
 */

const Shimmer = (props: ShimmerProps) => {
    const [height, setHeight] = useState<number | null>(null);
    const [width, setWidth] = useState<number | null>(null);
    const currentRef = (props.htmlRef as RefObject<HTMLElement> | null)?.current;
    const position: Position = props.position || "left";

    const size = props.propSize;
    let widthValue: number | SizeType | undefined;
    let heightValue: number | SizeType | undefined;

    if (isSize(size)) {
        widthValue = size.width;
        heightValue = size.height;
    } else if (typeof size === "number") {
        widthValue = size;
        heightValue = size;
    }

    useEffect(() => {
        if (currentRef != null) {
            if (typeof widthValue === "undefined") {
                setWidth(currentRef.offsetWidth);
            }
            if (typeof heightValue === "undefined") {
                setHeight(currentRef.offsetHeight);
            }
        }
    }, [currentRef, width, height]);

    const fixedSizeObj: Record<string, number | SizeType> = {};

    if (widthValue) {
        fixedSizeObj["width"] = widthValue;
    } else if (width) {
        fixedSizeObj["width"] = width;
    }

    if (heightValue) {
        fixedSizeObj["height"] = heightValue;
    } else if (height) {
        fixedSizeObj["height"] = height;
    }

    if (!props.visible) {
        return <>{props.children}</>;
    }

    return (
        <div
            role={"shimmer"}
            className={classNames(
                props.className,
                css["shimmer"],
                props.circle && css["circle"],
                props.borderRadius && css["with-border"],
                position && css[position],
                widthValue === SizeType.MatchParent && css["match-parent-width"],
                heightValue === SizeType.MatchParent && css["match-parent-height"],
                widthValue === SizeType.WrapContent && css["wrap-content"]
            )}
            style={{
                ...fixedSizeObj,
            }}
        >
            <div className={classNames(props.visible ? css["hide-child"] : css["show-child"])}>{props.children}</div>
        </div>
    );
};
export default Shimmer;
