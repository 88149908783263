import { ButtonProps, ButtonSize, ButtonStyle, ButtonType } from "./Button.types";
import css from "./Button.module.scss";
import classNames from "@fitneks-commons/classnames";
import { Icon } from "@fitneks-component-library";
import { MouseEventHandler } from "react";

/**
 * Button component
 *
 * @param {ButtonProps} props Component props
 * @param {string} props.title - button name
 * @param {ButtonStyle} props.style - button style primary, outline, rounded
 * @param {boolean} props.fullWidth -  set button full width
 * @param {ButtonType} props.type -  button | submit | reset
 * @param {ButtonSize} props.size -  "small" | "medium" | "large"
 * @param {string} props.startIcon -  button start icon
 * @param {string} props.endIcon -  button end icon
 * @param {boolean} props.isActive -  set active
 * @param {boolean} props.disabled -  set disabled
 *
 */

const Button = (props: ButtonProps) => {
    const style: ButtonStyle = props.style || "primary";
    const type: ButtonType = props.type || "button";
    const size: ButtonSize = props.size || "medium";

    const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
        if (typeof props.onClick != "undefined") {
            props.onClick(event);
        }
    };

    return (
        <button
            ref={props.htmlRef}
            className={classNames(
                css["button"],
                css[style],
                props.fullWidth && css["is-fullWidth"],
                props.isActive && css["is-active"],
                size != "medium" && css[size],
                props.className
            )}
            type={type}
            disabled={props.disabled}
            aria-label={props.title}
            onClick={handleClick}
        >
            {props.startIcon && <Icon name={props.startIcon} width={20} />}
            {props.title}
            {props.endIcon && <Icon name={props.endIcon} width={20} />}
        </button>
    );
};

export default Button;
