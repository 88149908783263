import { createContext } from "react";
import { ApolloClient } from "@apollo/client";

export interface AuthContextProps {
    isAuthorized: boolean;
    accessToken: string | null;
    refreshToken: string | null;
    userID: string | null;
    username: string | null;
    role: string | null;
    timezone: string | null;
    userGUID: string | null;
    country: string | null | undefined;
    onLogin: (
        accessToken: string,
        refreshToken: string,
        userID?: string,
        username?: string,
        roles?: [],
        timezone?: string,
        userGUID?: string,
        country?: string | null,
        client?: ApolloClient<object>
    ) => void;
    onLogout: () => void;
    updateEditableData: (data: AuthEditableData[]) => void;
}

export const AuthContext = createContext<AuthContextProps>({
    isAuthorized: false,
    accessToken: null,
    refreshToken: null,
    userID: null,
    username: null,
    role: null,
    timezone: null,
    userGUID: null,
    country: null,
    /* c8 ignore next 7 */
    onLogin() {
        /* Stub! */
    },
    onLogout(): void {
        /* Stub! */
    },
    /* c8 ignore next 7 */
    updateEditableData(): void {
        /* Stub! */
    },
});

export interface AuthEditableData {
    key: AuthEditableKey;
    value: string;
}

export enum AuthEditableKey {
    USERNAME = "username",
    TIMEZONE = "timezone",
    COUNTRY = "country",
}
