import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "@fitneks-commons/hooks";
import AppRoutes from "@fitneks-routes";

const ProtectedRoute = () => {
    const { isAuthorized } = useAuth();
    const location = useLocation();
    const redirectPath = AppRoutes.loginWithRedirect.route(location.pathname);

    if (!isAuthorized) {
        return <Navigate to={redirectPath} replace />;
    }

    return <Outlet />;
};

export default ProtectedRoute;
