/* eslint-disable */
/* tslint:disable */
/* THIS IS A GENERATED FILE, DO NOT EDIT. */
import * as Types from '@fitneks-types/Graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddInWaitListMutationVariables = Types.Exact<{
  input: Types.CreateLiveWaitListInput;
}>;


export type AddInWaitListMutation = { readonly createLiveWaitList?: { readonly liveWaitList?: { readonly id: string } | null } | null };

export type StopShareStreamMutationVariables = Types.Exact<{
  input: Types.ToggleParticipantVideoStreamInput;
}>;


export type StopShareStreamMutation = { readonly toggleParticipantVideoStream?: { readonly videoStream?: { readonly id: string } | null } | null };

export type InLiveWaitListQueryVariables = Types.Exact<{
  user: Types.Scalars['String'];
  videoStream: Types.Scalars['String'];
}>;


export type InLiveWaitListQuery = { readonly liveWaitLists?: { readonly edges?: ReadonlyArray<{ readonly node?: { readonly id: string } | null } | null> | null } | null };

export type GradedInfoQueryVariables = Types.Exact<{
  user: Types.Scalars['String'];
  videoStream?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GradedInfoQuery = { readonly trainingEarnedPoints?: { readonly edges?: ReadonlyArray<{ readonly node?: { readonly id: string } | null } | null> | null } | null };

export type EarnedPointsInfoQueryVariables = Types.Exact<{
  user?: Types.InputMaybe<Types.Scalars['String']>;
  streamGuid?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type EarnedPointsInfoQuery = { readonly trainingEarnedPoints?: { readonly edges?: ReadonlyArray<{ readonly node?: { readonly id: string, readonly points: number } | null } | null> | null } | null };


export const AddInWaitListDocument = gql`
    mutation AddInWaitList($input: createLiveWaitListInput!) {
  createLiveWaitList(input: $input) {
    liveWaitList {
      id
    }
  }
}
    `;
export type AddInWaitListMutationFn = Apollo.MutationFunction<AddInWaitListMutation, AddInWaitListMutationVariables>;

/**
 * __useAddInWaitListMutation__
 *
 * To run a mutation, you first call `useAddInWaitListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInWaitListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInWaitListMutation, { data, loading, error }] = useAddInWaitListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddInWaitListMutation(baseOptions?: Apollo.MutationHookOptions<AddInWaitListMutation, AddInWaitListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddInWaitListMutation, AddInWaitListMutationVariables>(AddInWaitListDocument, options);
      }
export type AddInWaitListMutationHookResult = ReturnType<typeof useAddInWaitListMutation>;
export type AddInWaitListMutationResult = Apollo.MutationResult<AddInWaitListMutation>;
export type AddInWaitListMutationOptions = Apollo.BaseMutationOptions<AddInWaitListMutation, AddInWaitListMutationVariables>;
export const StopShareStreamDocument = gql`
    mutation StopShareStream($input: toggleParticipantVideoStreamInput!) {
  toggleParticipantVideoStream(input: $input) {
    videoStream {
      id
    }
  }
}
    `;
export type StopShareStreamMutationFn = Apollo.MutationFunction<StopShareStreamMutation, StopShareStreamMutationVariables>;

/**
 * __useStopShareStreamMutation__
 *
 * To run a mutation, you first call `useStopShareStreamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopShareStreamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopShareStreamMutation, { data, loading, error }] = useStopShareStreamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStopShareStreamMutation(baseOptions?: Apollo.MutationHookOptions<StopShareStreamMutation, StopShareStreamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StopShareStreamMutation, StopShareStreamMutationVariables>(StopShareStreamDocument, options);
      }
export type StopShareStreamMutationHookResult = ReturnType<typeof useStopShareStreamMutation>;
export type StopShareStreamMutationResult = Apollo.MutationResult<StopShareStreamMutation>;
export type StopShareStreamMutationOptions = Apollo.BaseMutationOptions<StopShareStreamMutation, StopShareStreamMutationVariables>;
export const InLiveWaitListDocument = gql`
    query InLiveWaitList($user: String!, $videoStream: String!) {
  liveWaitLists(user: $user, videoStream_guid: $videoStream) {
    edges {
      node {
        id
      }
    }
  }
}
    `;

/**
 * __useInLiveWaitListQuery__
 *
 * To run a query within a React component, call `useInLiveWaitListQuery` and pass it any options that fit your needs.
 * When your component renders, `useInLiveWaitListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInLiveWaitListQuery({
 *   variables: {
 *      user: // value for 'user'
 *      videoStream: // value for 'videoStream'
 *   },
 * });
 */
export function useInLiveWaitListQuery(baseOptions: Apollo.QueryHookOptions<InLiveWaitListQuery, InLiveWaitListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InLiveWaitListQuery, InLiveWaitListQueryVariables>(InLiveWaitListDocument, options);
      }
export function useInLiveWaitListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InLiveWaitListQuery, InLiveWaitListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InLiveWaitListQuery, InLiveWaitListQueryVariables>(InLiveWaitListDocument, options);
        }
export type InLiveWaitListQueryHookResult = ReturnType<typeof useInLiveWaitListQuery>;
export type InLiveWaitListLazyQueryHookResult = ReturnType<typeof useInLiveWaitListLazyQuery>;
export type InLiveWaitListQueryResult = Apollo.QueryResult<InLiveWaitListQuery, InLiveWaitListQueryVariables>;
export const GradedInfoDocument = gql`
    query GradedInfo($user: String!, $videoStream: String) {
  trainingEarnedPoints(user: $user, videoStream_guid: $videoStream) {
    edges {
      node {
        id
      }
    }
  }
}
    `;

/**
 * __useGradedInfoQuery__
 *
 * To run a query within a React component, call `useGradedInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGradedInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGradedInfoQuery({
 *   variables: {
 *      user: // value for 'user'
 *      videoStream: // value for 'videoStream'
 *   },
 * });
 */
export function useGradedInfoQuery(baseOptions: Apollo.QueryHookOptions<GradedInfoQuery, GradedInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GradedInfoQuery, GradedInfoQueryVariables>(GradedInfoDocument, options);
      }
export function useGradedInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GradedInfoQuery, GradedInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GradedInfoQuery, GradedInfoQueryVariables>(GradedInfoDocument, options);
        }
export type GradedInfoQueryHookResult = ReturnType<typeof useGradedInfoQuery>;
export type GradedInfoLazyQueryHookResult = ReturnType<typeof useGradedInfoLazyQuery>;
export type GradedInfoQueryResult = Apollo.QueryResult<GradedInfoQuery, GradedInfoQueryVariables>;
export const EarnedPointsInfoDocument = gql`
    query EarnedPointsInfo($user: String, $streamGuid: String) {
  trainingEarnedPoints(user: $user, videoStream_guid: $streamGuid, first: 10) {
    edges {
      node {
        id
        points
      }
    }
  }
}
    `;

/**
 * __useEarnedPointsInfoQuery__
 *
 * To run a query within a React component, call `useEarnedPointsInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useEarnedPointsInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEarnedPointsInfoQuery({
 *   variables: {
 *      user: // value for 'user'
 *      streamGuid: // value for 'streamGuid'
 *   },
 * });
 */
export function useEarnedPointsInfoQuery(baseOptions?: Apollo.QueryHookOptions<EarnedPointsInfoQuery, EarnedPointsInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EarnedPointsInfoQuery, EarnedPointsInfoQueryVariables>(EarnedPointsInfoDocument, options);
      }
export function useEarnedPointsInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EarnedPointsInfoQuery, EarnedPointsInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EarnedPointsInfoQuery, EarnedPointsInfoQueryVariables>(EarnedPointsInfoDocument, options);
        }
export type EarnedPointsInfoQueryHookResult = ReturnType<typeof useEarnedPointsInfoQuery>;
export type EarnedPointsInfoLazyQueryHookResult = ReturnType<typeof useEarnedPointsInfoLazyQuery>;
export type EarnedPointsInfoQueryResult = Apollo.QueryResult<EarnedPointsInfoQuery, EarnedPointsInfoQueryVariables>;