import { DeviceProps } from "./Device.types";
import { getDeviceType } from "@fitneks-commons/getDeviceType";

/**
 * Device component
 *
 * @param {DeviceProps} props Component props
 * @param {boolean} props.type - device type "mobile" | "tablet" | "desktop" | "other" | "all"
 *
 */

const Device = (props: DeviceProps) => {
    if (!getDeviceType(props.type)) return <></>;

    return <>{props.children}</>;
};

export default Device;
