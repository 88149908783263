export enum ErrorMessage {
    USERNAME = "Please enter your username or email.",
    PASSWORD = "Please enter your password.",
}

export enum InputStatus {
    INITIAL = "initial",
    INVALID = "inValid",
}

export const InitialField = {
    message: "",
    status: InputStatus.INITIAL,
};

export const FAILED_LOGIN_MESSAGE = "Wrong username/email or password";
export const NOT_VERIFIED = "Please verify your account";
