import React, { useEffect, useState } from "react";
import {
    getLocalStorageItem,
    removeLocalStorageItem,
    setLocalStorageItem,
} from "@fitneks-commons/helpers/localstorage";

/**
 * React hook for easy localStorage interaction from components.
 *
 * @param {string} key localStorage item key.
 * @param {string} defaultValue Optional default value to return retrieved if item is null.
 */
export const useLocalStorage = (
    key: string,
    defaultValue: string | null = null
): [string | null, React.Dispatch<React.SetStateAction<string | null>>] => {
    const [value, setValue] = useState(() => getLocalStorageItem(key, defaultValue));

    useEffect(() => {
        if (value) {
            setLocalStorageItem(key, value);
        } else {
            removeLocalStorageItem(key);
        }
    }, [key, value]);

    return [value, setValue];
};
