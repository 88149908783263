/* eslint-disable */
/* tslint:disable */
/* THIS IS A GENERATED FILE, DO NOT EDIT. */
import * as Types from '@fitneks-types/Graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUserFollowingsQueryVariables = Types.Exact<{
  input: Types.Scalars['String'];
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetUserFollowingsQuery = { readonly userConnections?: { readonly edges?: ReadonlyArray<{ readonly node?: { readonly id: string, readonly followingUser?: { readonly id: string, readonly username: string, readonly firstName: string, readonly lastName: string, readonly displayName: string, readonly gamificationPointsCount: number, readonly OAuthAvatar?: string | null, readonly avatarMediaObject?: { readonly id: string, readonly filePath?: string | null } | null } | null, readonly user?: { readonly id: string, readonly username: string, readonly firstName: string, readonly lastName: string, readonly displayName: string, readonly gamificationPointsCount: number, readonly OAuthAvatar?: string | null, readonly avatarMediaObject?: { readonly id: string, readonly filePath?: string | null } | null } | null } | null } | null> | null, readonly pageInfo: { readonly endCursor?: string | null, readonly hasNextPage: boolean } } | null };

export type GetUserFollowersQueryVariables = Types.Exact<{
  input: Types.Scalars['String'];
  first: Types.Scalars['Int'];
  after?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetUserFollowersQuery = { readonly userConnections?: { readonly edges?: ReadonlyArray<{ readonly node?: { readonly id: string, readonly user?: { readonly id: string, readonly username: string, readonly firstName: string, readonly lastName: string, readonly displayName: string, readonly gamificationPointsCount: number, readonly OAuthAvatar?: string | null, readonly avatarMediaObject?: { readonly id: string, readonly filePath?: string | null } | null } | null, readonly followingUser?: { readonly id: string, readonly username: string, readonly firstName: string, readonly lastName: string, readonly displayName: string, readonly gamificationPointsCount: number, readonly OAuthAvatar?: string | null, readonly avatarMediaObject?: { readonly id: string, readonly filePath?: string | null } | null } | null } | null } | null> | null, readonly pageInfo: { readonly endCursor?: string | null, readonly hasNextPage: boolean } } | null };

export type RemoveConnectionMutationVariables = Types.Exact<{
  input: Types.DeleteUserConnectionInput;
}>;


export type RemoveConnectionMutation = { readonly deleteUserConnection?: { readonly userConnection?: { readonly id: string } | null } | null };


export const GetUserFollowingsDocument = gql`
    query getUserFollowings($input: String!, $first: Int, $after: String) {
  userConnections(user: $input, first: $first, after: $after) {
    edges {
      node {
        id
        followingUser {
          id
          username
          firstName
          lastName
          displayName
          gamificationPointsCount
          avatarMediaObject {
            id
            filePath
          }
          OAuthAvatar
        }
        user {
          id
          username
          firstName
          lastName
          displayName
          gamificationPointsCount
          avatarMediaObject {
            id
            filePath
          }
          OAuthAvatar
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetUserFollowingsQuery__
 *
 * To run a query within a React component, call `useGetUserFollowingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFollowingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFollowingsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetUserFollowingsQuery(baseOptions: Apollo.QueryHookOptions<GetUserFollowingsQuery, GetUserFollowingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserFollowingsQuery, GetUserFollowingsQueryVariables>(GetUserFollowingsDocument, options);
      }
export function useGetUserFollowingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserFollowingsQuery, GetUserFollowingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserFollowingsQuery, GetUserFollowingsQueryVariables>(GetUserFollowingsDocument, options);
        }
export type GetUserFollowingsQueryHookResult = ReturnType<typeof useGetUserFollowingsQuery>;
export type GetUserFollowingsLazyQueryHookResult = ReturnType<typeof useGetUserFollowingsLazyQuery>;
export type GetUserFollowingsQueryResult = Apollo.QueryResult<GetUserFollowingsQuery, GetUserFollowingsQueryVariables>;
export const GetUserFollowersDocument = gql`
    query getUserFollowers($input: String!, $first: Int!, $after: String) {
  userConnections(followingUser: $input, first: $first, after: $after) {
    edges {
      node {
        id
        user {
          id
          username
          firstName
          lastName
          displayName
          gamificationPointsCount
          avatarMediaObject {
            id
            filePath
          }
          OAuthAvatar
        }
        followingUser {
          id
          username
          firstName
          lastName
          displayName
          gamificationPointsCount
          avatarMediaObject {
            id
            filePath
          }
          OAuthAvatar
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetUserFollowersQuery__
 *
 * To run a query within a React component, call `useGetUserFollowersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFollowersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFollowersQuery({
 *   variables: {
 *      input: // value for 'input'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetUserFollowersQuery(baseOptions: Apollo.QueryHookOptions<GetUserFollowersQuery, GetUserFollowersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserFollowersQuery, GetUserFollowersQueryVariables>(GetUserFollowersDocument, options);
      }
export function useGetUserFollowersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserFollowersQuery, GetUserFollowersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserFollowersQuery, GetUserFollowersQueryVariables>(GetUserFollowersDocument, options);
        }
export type GetUserFollowersQueryHookResult = ReturnType<typeof useGetUserFollowersQuery>;
export type GetUserFollowersLazyQueryHookResult = ReturnType<typeof useGetUserFollowersLazyQuery>;
export type GetUserFollowersQueryResult = Apollo.QueryResult<GetUserFollowersQuery, GetUserFollowersQueryVariables>;
export const RemoveConnectionDocument = gql`
    mutation removeConnection($input: deleteUserConnectionInput!) {
  deleteUserConnection(input: $input) {
    userConnection {
      id
    }
  }
}
    `;
export type RemoveConnectionMutationFn = Apollo.MutationFunction<RemoveConnectionMutation, RemoveConnectionMutationVariables>;

/**
 * __useRemoveConnectionMutation__
 *
 * To run a mutation, you first call `useRemoveConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeConnectionMutation, { data, loading, error }] = useRemoveConnectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveConnectionMutation(baseOptions?: Apollo.MutationHookOptions<RemoveConnectionMutation, RemoveConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveConnectionMutation, RemoveConnectionMutationVariables>(RemoveConnectionDocument, options);
      }
export type RemoveConnectionMutationHookResult = ReturnType<typeof useRemoveConnectionMutation>;
export type RemoveConnectionMutationResult = Apollo.MutationResult<RemoveConnectionMutation>;
export type RemoveConnectionMutationOptions = Apollo.BaseMutationOptions<RemoveConnectionMutation, RemoveConnectionMutationVariables>;