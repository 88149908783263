import { MuscleGroupProps } from "./MuscleGroup.types";
import css from "./MuscleGroup.module.scss";
import classNames from "@fitneks-commons/classnames";

const MuscleGroup = (props: MuscleGroupProps) => {
    return (
        <div
            ref={props.htmlRef}
            className={classNames(
                css["muscleGroup"],
                !!props.arms && css[`arms-${props.arms}`],
                !!props.legs && css[`legs-${props.legs}`],
                !!props.back && css[`back-${props.back}`],
                !!props.chest && css[`chest-${props.chest}`],
                !!props.abs && css[`abs-${props.abs}`],
                !!props.full && css[`full-${props.full}`],
                props.className
            )}
            role="graphics-document"
        >
            <svg
                className={classNames(css["muscleGroup-svg"])}
                xmlns="http://www.w3.org/2000/svg"
                width="327.318"
                height="364.8"
                viewBox="0 0 327.318 364.8"
                aria-label={"Body muscle group"}
            >
                <g className={classNames(css["body"])} transform="translate(-107.809 -54.894)">
                    <g className={classNames(css["arms"])}>
                        <path
                            id="Path_24"
                            data-name="Path 24"
                            d="M171.132,283.8c2.171,2.916,2.187,6.86,1.982,10.489q-.262,4.652-.8,9.282a35.216,35.216,
                            0,0,1-2.744,11.251c-1.459,3.039-3.918,5.826-7.19,6.638a2.67,2.67,0,0,1-1.5.031,2.933,2.933,
                            0,0,1-1.006-.693,13.139,13.139,0,0,1-4.142-7.075,15.135,15.135,0,0,1,.335-5.932,51.355,
                            51.355,0,0,1,5.59-13.453C163.929,290.234,166.693,285.983,171.132,283.8Z"
                            transform="translate(-29.168 -139.816)"
                        />
                        <path
                            id="Path_25"
                            data-name="Path 25"
                            d="M152.792,294.382a49.44,49.44,0,0,0,2.189,11.26,7.65,7.65,0,0,1,.364,1.43,6.432,
                            6.432,0,0,1-.141,1.9c-.746,4.036-2.231,7.918-2.823,11.98a15.677,15.677,0,0,1-2.6-7.083,
                            31.7,31.7,0,0,1,.092-7.6A42.093,42.093,0,0,1,152.792,294.382Z"
                            transform="translate(-25.517 -146.276)"
                        />
                        <path
                            id="Path_26"
                            data-name="Path 26"
                            d="M135.149,358.032c5.022,9.822,5.2,21.356,4.633,32.373a33.8,33.8,0,0,1-1.447,9.467,
                            30.172,30.172,0,0,1-1.99,4.306c-2.681,4.984-5.93,9.859-10.615,13.035a7.117,7.117,0,0,
                            1-.2-4.352,263.317,263.317,0,0,0,2.989-27.528,33.487,33.487,0,0,1,1.051-7.983c1.087-3.55,
                            3.41-6.654,4.268-10.265C134.519,364.229,134.242,361.208,135.149,358.032Z"
                            transform="translate(-10.679 -185.153)"
                        />
                        <path
                            id="Path_27"
                            data-name="Path 27"
                            d="M173.19,376.789a13.368,13.368,0,0,1-8.93,4.351c-.088,4.438.7,8.841,1.079,13.264s.325,
                            9.011-1.357,13.12a51.852,51.852,0,0,0,5.174-10.548C171.244,390.327,170.626,382.935,
                            173.19,376.789Z"
                            transform="translate(-34.31 -196.61)"
                        />
                        <path
                            id="Path_70"
                            data-name="Path 70"
                            d="M162.135,260.113c-.588-6.389-1.729-12.813-.937-19.18s3.95-12.881,9.715-15.7a8.805,
                            8.805,0,0,1,3.224-.946,12.826,12.826,0,0,1,3.164.307c6.231,1.131,12.5,2.273,18.436,
                            4.482-5.056.787-10.246,1.665-14.627,4.307s-7.824,7.486-7.294,12.576A37.773,37.773,
                            0,0,0,162.135,260.113Z"
                            transform="translate(-32.444 -103.456)"
                        />
                        <path
                            id="Path_96"
                            data-name="Path 96"
                            d="M367.481,260.112c.588-6.389,1.729-12.813.937-19.18s-3.95-12.881-9.716-15.7a8.805,
                            8.805,0,0,0-3.224-.947,12.842,12.842,0,0,0-3.164.307c-6.231,1.131-12.5,2.273-18.436,
                            4.483,5.056.787,10.245,1.665,14.627,4.307s7.824,7.487,7.294,12.576A37.769,37.769,
                            0,0,1,367.481,260.112Z"
                            transform="translate(-138.081 -103.455)"
                        />
                        <path
                            id="Path_71"
                            data-name="Path 71"
                            d="M385.438,283.8c-2.171,2.915-2.187,6.86-1.982,10.489q.262,4.652.8,9.282A35.213,
                            35.213,0,0,0,387,314.827c1.459,3.039,3.918,5.827,7.19,6.639a2.666,2.666,0,0,0,
                            1.5.03,2.937,2.937,0,0,0,1.006-.693,13.143,13.143,0,0,0,4.142-7.075,15.128,
                            15.128,0,0,0-.335-5.932,51.334,51.334,0,0,0-5.589-13.453C392.641,290.233,
                            389.877,285.983,385.438,283.8Z"
                            transform="translate(-168.311 -139.816)"
                        />
                        <path
                            id="Path_72"
                            data-name="Path 72"
                            d="M422.372,294.382a49.437,49.437,0,0,1-2.189,11.26,7.683,7.683,0,0,0-.364,1.43,6.441,
                            6.441,0,0,0,.141,1.9c.745,4.036,2.23,7.918,2.823,11.98a15.679,15.679,0,0,0,2.6-7.082,
                            31.711,31.711,0,0,0-.092-7.6A42.09,42.09,0,0,0,422.372,294.382Z"
                            transform="translate(-190.557 -146.276)"
                        />
                        <path
                            id="Path_73"
                            data-name="Path 73"
                            d="M426.006,358.032c-5.022,9.822-5.2,21.355-4.633,32.372a33.8,33.8,0,0,0,1.447,
                            9.468,30.171,30.171,0,0,0,1.99,4.306c2.681,4.984,5.93,9.859,10.614,13.035a7.113,
                            7.113,0,0,0,.2-4.352,263.222,263.222,0,0,1-2.99-27.529,33.485,33.485,0,0,
                            0-1.051-7.983c-1.087-3.55-3.409-6.654-4.268-10.266C426.636,364.229,426.913,
                            361.208,426.006,358.032Z"
                            transform="translate(-191.386 -185.153)"
                        />
                        <path
                            id="Path_74"
                            data-name="Path 74"
                            d="M396.6,376.788a13.368,13.368,0,0,0,8.93,4.351c.088,4.438-.7,8.841-1.079,
                            13.264s-.325,9.011,1.357,13.12a51.841,51.841,0,0,1-5.174-10.548C398.548,
                            390.326,399.166,382.934,396.6,376.788Z"
                            transform="translate(-176.392 -196.609)"
                        />
                        <path
                            id="Path_62"
                            data-name="Path 62"
                            d="M648.547,221.591a21,21,0,0,1,4,5.864,4.838,4.838,0,0,1,.568,2.245,5.469,
                            5.469,0,0,1-.915,2.35l-2.992,5.142a15.072,15.072,0,0,1-7.1,7.04l-9.61,5.72q.33-6.646,
                            1.2-13.25a19.763,19.763,0,0,1,1.346-5.633c1.676-3.7,5.3-6.086,8.785-8.172C645.349,
                            221.985,647.656,220.67,648.547,221.591Z"
                            transform="translate(-320.473 -101.625)"
                        />
                        <path
                            id="Path_63"
                            data-name="Path 63"
                            d="M639.236,282.619l-8.034,5.436a5.925,5.925,0,0,0-1.832,1.657,5.814,5.814,0,0,0-.652,
                            2.108q-.937,5.456-1.5,10.967a19.2,19.2,0,0,0,7.908-9.058C636.631,290.134,637.04,286.068,
                            639.236,282.619Z"
                            transform="translate(-317.248 -139.092)"
                        />
                        <path
                            id="Path_64"
                            data-name="Path 64"
                            d="M653.686,277.139a9.154,9.154,0,0,0-4.342,4.738,46.322,46.322,0,0,0-1.992,6.254,43.858,
                            43.858,0,0,1-2.947,7.435,5,5,0,0,0-.658,1.832,4.751,4.751,0,0,0,.918,2.662,11.425,11.425,
                            0,0,0,2.514,3.269,3.881,3.881,0,0,0,3.875.734,3.928,3.928,0,0,0,2.043-2.9,13.443,13.443,
                            0,0,0,0-3.676,56.48,56.48,0,0,1,1.435-18.046,2.573,2.573,0,0,0-.034-1.959C654.139,276.91,
                            653.037,276.908,653.686,277.139Z"
                            transform="translate(-327.341 -135.657)"
                        />
                        <path
                            id="Path_65"
                            data-name="Path 65"
                            d="M640.522,336.332c-1.23,4.138-1.7,8.509-3.384,12.486a4.125,4.125,0,0,1-1.419,1.985,
                            5.141,5.141,0,0,1-2.283.543c-2.373.236-4.958.87-7-.355a5.564,5.564,0,0,1-2.26-2.919,
                            12.015,12.015,0,0,1-.6-3.7,57.93,57.93,0,0,1,.458-7.941,5.018,5.018,0,0,1,.3-1.472,
                            4.927,4.927,0,0,1,.91-1.284q1.89-2.128,3.881-4.163a.7.7,0,0,1,
                            .479-.276c.271.008.426.3.528.553a24.789,24.789,0,0,0,3.711,6.263,4.827,
                            4.827,0,0,0,2,1.644,3.607,3.607,0,0,0,2.258-.04A8.261,8.261,0,0,0,640.522,336.332Z"
                            transform="translate(-315.013 -167.564)"
                        />
                        <path
                            id="Path_66"
                            data-name="Path 66"
                            d="M621.807,379.387a3.124,3.124,0,0,0-.356,2.518,13.5,13.5,0,0,0,.976,2.429,19.741,
                            19.741,0,0,1,1.3,12.2,26.6,26.6,0,0,1,5.881-8.4c1.752-1.682,3.785-3.189,
                            4.864-5.365a29.945,29.945,0,0,1-3.964.8,10.743,10.743,0,0,1-5.253-.338A5.574,
                            5.574,0,0,1,621.807,379.387Z"
                            transform="translate(-313.66 -198.197)"
                        />
                        <path
                            id="Path_67"
                            data-name="Path 67"
                            d="M599.534,434.925c3.347-3.907,5.394-8.751,7.054-13.62s2.994-9.869,5.162-14.535a36.388,
                            36.388,0,0,1,8.916-12.177c.534,7-2.037,13.879-5.282,20.1s-7.214,12.1-9.839,18.608c-1,
                            2.486-1.781,5.391-.527,7.76l-4.661-4.352C599.792,436.188,599.126,435.4,599.534,434.925Z"
                            transform="translate(-300.263 -207.484)"
                        />
                        <path
                            id="Path_68"
                            data-name="Path 68"
                            d="M606.895,386.843a17.98,17.98,0,0,1,2.584,8.075,34.986,34.986,0,0,1-.444,
                            8.527A66.744,66.744,0,0,1,603.2,422.13a35.944,35.944,0,0,1-3.59,6.08l2.253-22.371a92.831,
                            92.831,0,0,1,2.3-14.771,10.192,10.192,0,0,1,1.883-4.124.978.978,0,0,1,.594-.393C606.879,
                            386.527,607.148,386.758,606.895,386.843Z"
                            transform="translate(-300.387 -202.571)"
                        />
                        <path
                            id="Path_112"
                            data-name="Path 112"
                            d="M845.47,229.7a5.465,5.465,0,0,0,.914,2.35l2.993,5.142a15.075,15.075,0,0,0,7.1,
                            7.04l9.61,5.72q-.33-6.645-1.2-13.249a19.777,19.777,0,0,
                            0-1.346-5.634c-1.676-3.7-5.3-6.085-8.785-8.172-1.525-.912-3.833-2.227-4.723-1.306a21.014,
                            21.014,0,0,0-4,5.864A4.838,4.838,0,0,0,845.47,229.7Z"
                            transform="translate(-450.552 -101.625)"
                        />
                        <path
                            id="Path_113"
                            data-name="Path 113"
                            d="M880.873,288.055l-8.033-5.436c2.2,3.449,2.605,7.514,4.112,11.111a19.2,19.2,0,0,0,
                            7.908,9.057q-.565-5.507-1.5-10.967a5.817,5.817,0,0,0-.652-2.108A5.925,5.925,0,0,0,
                            880.873,288.055Z"
                            transform="translate(-467.273 -139.091)"
                        />
                        <path
                            id="Path_114"
                            data-name="Path 114"
                            d="M860.634,301.161a3.927,3.927,0,0,0,2.043,2.9,3.88,3.88,0,0,0,3.875-.734,11.43,11.43,
                            0,0,0,2.515-3.269,4.752,4.752,0,0,0,.918-2.662,5,5,0,0,0-.658-1.832,43.859,43.859,0,0,
                            1-2.947-7.435,46.331,46.331,0,0,0-1.992-6.254,9.151,9.151,0,0,
                            0-4.341-4.738c.649-.232-.453-.229-.808.341a2.573,2.573,0,0,0-.034,
                            1.959,56.488,56.488,0,0,1,1.434,18.046A13.441,13.441,0,0,0,860.634,301.161Z"
                            transform="translate(-458.835 -135.656)"
                        />
                        <path
                            id="Path_115"
                            data-name="Path 115"
                            d="M870.878,351.344c2.373.237,4.958.87,7-.355a5.566,5.566,0,0,0,2.26-2.919,12.006,
                            12.006,0,0,0,.6-3.7,58,58,0,0,0-.458-7.94,5.049,5.049,0,0,0-.3-1.473,4.923,4.923,
                            0,0,0-.91-1.284q-1.89-2.129-3.881-4.163a.7.7,0,0,
                            0-.479-.276c-.271.008-.425.3-.528.553a24.787,24.787,0,0,1-3.711,6.263,4.824,4.824,0,0,
                            1-2,1.644,3.6,3.6,0,0,1-2.258-.04,8.261,8.261,0,0,1-2.421-1.321c1.231,4.138,1.7,8.509,
                            3.384,12.485A4.123,4.123,0,0,0,868.6,350.8,5.133,5.133,0,0,0,870.878,351.344Z"
                            transform="translate(-461.747 -167.563)"
                        />
                        <path
                            id="Path_116"
                            data-name="Path 116"
                            d="M880.725,388.13a26.6,26.6,0,0,1,5.881,8.4,19.743,19.743,0,0,1,1.3-12.2,13.491,13.491,
                            0,0,0,.976-2.428,3.122,3.122,0,0,0-.356-2.518,5.572,5.572,0,0,1-3.448,3.84,10.745,
                            10.745,0,0,1-5.253.338,29.955,29.955,0,0,1-3.964-.8C876.94,384.941,878.974,386.448,
                            880.725,388.13Z"
                            transform="translate(-469.118 -198.196)"
                        />
                        <path
                            id="Path_117"
                            data-name="Path 117"
                            d="M892.452,441.065l4.661-4.352c.564-.527,
                            1.23-1.314.822-1.79-3.347-3.907-5.394-8.751-7.054-13.62s-2.994-9.869-5.162-14.535a36.388,
                            36.388,0,0,0-8.916-12.177c-.535,7,2.037,13.878,5.282,20.1s7.214,12.1,9.839,18.609C892.927,
                            435.79,893.7,438.7,892.452,441.065Z"
                            transform="translate(-469.65 -207.484)"
                        />
                        <path
                            id="Path_118"
                            data-name="Path 118"
                            d="M908.4,386.841a17.976,17.976,0,0,0-2.584,8.075,34.968,34.968,0,0,0,.444,8.527,66.743,
                            66.743,0,0,0,5.833,18.685,35.956,35.956,0,0,0,3.59,6.08l-2.254-22.371a92.826,92.826,0,0,
                            0-2.3-14.771,10.185,10.185,0,0,0-1.883-4.124.978.978,0,0,0-.594-.394C908.417,386.525,
                            908.147,386.757,908.4,386.841Z"
                            transform="translate(-487.353 -202.57)"
                        />
                    </g>
                    <g className={classNames(css["legs"])}>
                        <path
                            id="Path_40"
                            data-name="Path 40"
                            d="M234.242,498.123c1.341,19.49,12.972,36.764,17.082,55.863l1.5-29.228a11.532,11.532,
                            0,0,0-.306-4.227c-.731-2.205-2.684-3.731-4.455-5.235A59.585,59.585,0,0,1,234.242,498.123Z"
                            transform="translate(-77.224 -270.719)"
                        />
                        <path
                            id="Path_41"
                            data-name="Path 41"
                            d="M203.908,470.481a198.517,198.517,0,0,0-3.721,63.086c1.067,10.5,2.975,20.942,3.128,
                            31.5-2.987-7.006-6-14.087-7.33-21.586a91.143,91.143,0,0,
                            1-1.06-15.152q-.09-10.028.238-20.053c.422-12.9,
                            1.489-26.24,7.6-37.609.2-.367.45-.769.859-.845S204.466,470.306,203.908,470.481Z"
                            transform="translate(-53.198 -253.427)"
                        />
                        <path
                            id="Path_42"
                            data-name="Path 42"
                            d="M252.328,606.431c4.082,7.72,5.563,16.619,5.407,25.351s-1.878,17.358-3.9,
                            25.855c.42-5.482-3.513-10.353-4.32-15.791-.678-4.567.89-9.123,1.857-13.639a53.226,
                            53.226,0,0,0-.152-22.883C252.055,605.678,252.71,606.425,252.328,606.431Z"
                            transform="translate(-86.454 -336.197)"
                        />
                        <path
                            id="Path_43"
                            data-name="Path 43"
                            d="M216.324,575.331c4.18-10.195,10.411-19.931,11.112-30.927a54.663,54.663,0,0,
                            0-.335-8.915c-.371-3.7-.852-7.4-1.581-11.05-.966-4.835-2.365-9.571-3.634-14.337a274.47,
                            274.47,0,0,1-5.927-28.1c-3.453,19.128-4.421,38.8-1.612,58.035.71,4.865,1.662,9.707,1.935,
                            14.616a106.494,106.494,0,0,1-.218,11.82l-.6,10.8C215.969,577.276,215.781,576.655,216.324,
                            575.331Z"
                            transform="translate(-64.07 -260.872)"
                        />
                        <path
                            id="Path_44"
                            data-name="Path 44"
                            d="M230.233,696.635c-3.764,6.312-7.645,13.021-7.688,20.37a37.152,37.152,0,0,0,
                            1.6,9.732c2.614,9.787,6.1,19.479,6.583,29.6a181.371,181.371,0,0,1,1.345-27.321,85.824,
                            85.824,0,0,0,1.084-12.279,55.466,55.466,0,0,0-1.247-9.815A75.8,75.8,0,0,1,230.233,696.635Z"
                            transform="translate(-70.08 -391.968)"
                        />
                        <path
                            id="Path_45"
                            data-name="Path 45"
                            d="M247.961,806.091a44.023,44.023,0,0,1-.494-19.98c1.8-8.647,6.192-16.663,
                            7.326-25.422a35.428,35.428,0,0,0-7.633-26.658c2.742,8.091-.112,16.853-1.136,25.334-.535,
                            4.431-.559,8.906-.9,13.355-.443,5.771-1.418,11.511-1.409,17.3S244.806,801.786,247.961,
                            806.091Z"
                            transform="translate(-83.01 -414.809)"
                        />
                        <path
                            id="Path_46"
                            data-name="Path 46"
                            d="M225.086,812.321c-10.689-7.063-16.2-20.033-17.638-32.763s.613-25.556,1.927-38.3l2.579,
                            10.027c3.233,12.566,6.482,25.231,7.219,38.185a114.862,114.862,0,0,0,.871,12.1C220.7,
                            805.568,222.085,809.553,225.086,812.321Z"
                            transform="translate(-60.56 -419.224)"
                        />
                        <path
                            id="Path_88"
                            data-name="Path 88"
                            d="M320.755,498.123c-1.341,19.49-12.971,36.764-17.082,55.863l-1.5-29.227a11.53,11.53,0,0,
                            1,.306-4.227c.731-2.205,2.684-3.732,4.455-5.235A59.577,59.577,0,0,0,320.755,498.123Z"
                            transform="translate(-118.682 -270.719)"
                        />
                        <path
                            id="Path_89"
                            data-name="Path 89"
                            d="M365.79,470.481a198.519,198.519,0,0,1,3.721,63.086c-1.067,10.5-2.975,20.942-3.128,31.5,
                            2.987-7.006,6-14.087,7.33-21.586a91.141,91.141,0,0,0,
                            1.061-15.152q.09-10.027-.238-20.053c-.422-12.9-1.489-26.24-7.6-37.609-.2-.367-.45-.
                            769-.859-.845S365.232,470.306,365.79,470.481Z"
                            transform="translate(-157.41 -253.427)"
                        />
                        <path
                            id="Path_90"
                            data-name="Path 90"
                            d="M318.744,606.43c-4.082,7.72-5.563,16.619-5.407,25.351s1.878,
                            17.358,3.9,25.855c-.42-5.482,3.513-10.353,
                            4.32-15.791.678-4.568-.889-9.124-1.856-13.639a53.226,53.226,0,0,1,.151-22.883C319.017,
                            605.678,318.362,606.425,318.744,606.43Z"
                            transform="translate(-125.528 -336.197)"
                        />
                        <path
                            id="Path_91"
                            data-name="Path 91"
                            d="M344.7,575.33c-4.18-10.195-10.412-19.931-11.112-30.927a54.671,54.671,0,0,1,
                            .335-8.915c.371-3.7.852-7.4,1.581-11.05.966-4.836,2.365-9.572,3.634-14.337A274.272,
                            274.272,0,0,0,345.06,482c3.453,19.129,4.421,38.8,1.612,58.036-.71,4.865-1.662,
                            9.707-1.935,14.617a106.591,106.591,0,0,0,.218,11.819l.6,10.8C345.05,577.276,345.238,
                            576.654,344.7,575.33Z"
                            transform="translate(-137.858 -260.871)"
                        />
                        <path
                            id="Path_92"
                            data-name="Path 92"
                            d="M337.347,696.635c3.764,6.312,7.645,13.021,7.688,20.37a37.155,37.155,0,0,1-1.6,
                            9.732c-2.614,9.787-6.1,19.479-6.583,29.6a181.367,181.367,0,0,0-1.345-27.321,85.885,
                            85.885,0,0,1-1.085-12.279,55.5,55.5,0,0,1,1.247-9.815A75.735,75.735,0,0,0,337.347,
                            696.635Z"
                            transform="translate(-138.41 -391.968)"
                        />
                        <path
                            id="Path_93"
                            data-name="Path 93"
                            d="M318.459,806.091a44.023,44.023,0,0,0,
                            .493-19.98c-1.8-8.647-6.192-16.663-7.325-25.422a35.428,35.428,0,0,1,7.633-26.658c-2.742,
                            8.09.112,16.853,1.136,25.334.534,4.43.559,8.906.9,13.355.443,5.771,1.418,11.512,1.409,
                            17.3S321.613,801.785,318.459,806.091Z"
                            transform="translate(-124.319 -414.809)"
                        />
                        <path
                            id="Path_94"
                            data-name="Path 94"
                            d="M330.71,812.321c10.689-7.063,16.2-20.033,
                            17.638-32.763s-.613-25.556-1.927-38.3l-2.58,10.026c-3.233,12.567-6.481,25.231-7.219,
                            38.186a114.738,114.738,0,0,1-.871,12.1C335.1,805.568,333.71,809.553,330.71,812.321Z"
                            transform="translate(-136.146 -419.224)"
                        />
                        <path
                            id="Path_48"
                            data-name="Path 48"
                            d="M705.616,731.021c-6.4,9.924-6.206,22.647-4.7,34.358.606,4.726,1.486,9.654,4.43,
                            13.4a1.633,1.633,0,0,0,1.031.736c.708.066,1.177-.7,1.425-1.37a38.65,38.65,0,0,0,
                            1.775-8.359c.578-4.244,1.156-8.487,1.658-12.74.51-4.32.928-8.8-.4-12.939C709.426,
                            739.747,706.167,736.056,705.616,731.021Z"
                            transform="translate(-361.729 -412.971)"
                        />
                        <path
                            id="Path_49"
                            data-name="Path 49"
                            d="M679.834,727.96c2.405,1.415,2.787,4.7,2.7,7.487-.331,10.615-3.515,21.226-1.835,
                            31.712.267,1.662.643,3.427-.01,4.978a8.053,8.053,0,0,1-2.5,2.859c-1.633,1.356-3.413,
                            2.773-5.53,2.935a.924.924,0,0,1-.752-.186,1,1,0,0,1-.205-.6,97.806,97.806,0,0,1-.62-9.9,
                            65.571,65.571,0,0,1,.6-10.324,98.64,98.64,0,0,1,2.653-11.529l4.136-15.17A3.5,3.5,0,0,1,
                            679.834,727.96Z"
                            transform="translate(-344.036 -411.101)"
                        />
                        <path
                            id="Path_50"
                            data-name="Path 50"
                            d="M673.221,851.937a219.534,219.534,0,0,1,.228,27.215l.245,6.528c1.256-2.5,3.055-5.354,
                            5.851-5.38a3.935,3.935,0,0,1,3.672,2.635c.694-2.492.39-5.134.332-7.72a39.932,39.932,0,0,
                            1,6.579-22.761,16.492,16.492,0,0,1-6.468-7.515A18.8,18.8,0,0,1,673.221,851.937Z"
                            transform="translate(-345.348 -482.551)"
                        />
                        <path
                            id="Path_52"
                            data-name="Path 52"
                            d="M700.732,712.51l-2.689-4.238a2.386,2.386,0,0,1-.089-2.92l3.1-6.922,2.166,7.164a7.414,
                            7.414,0,0,1,.471,3.2A5.532,5.532,0,0,1,700.732,712.51Z"
                            transform="translate(-360.184 -393.065)"
                        />
                        <path
                            id="Path_53"
                            data-name="Path 53"
                            d="M684.659,571.622c.637-13.066,1.7-26.111,
                            3.008-39.074-4.7-5.252-9.141-10.955-11.369-17.639-1.945-5.834-2.093-12.092-2.224-18.241
                            -4.89,14.863-3.708,30.985-1.731,46.507s4.7,31.2,2.713,46.72A74.633,74.633,0,0,1,684.659,
                            571.622Z"
                            transform="translate(-343.765 -269.831)"
                        />
                        <path
                            id="Path_54"
                            data-name="Path 54"
                            d="M716.7,570.093a18.378,18.378,0,0,0,14.634-10.143c-.36,11.78-.728,23.623-3.005,
                            35.186-1.322,6.711-3.284,13.31-4.021,20.11-.636,5.868-.432,12.111-3.325,17.261l-.342,
                            1.75c-.061-3.43-2.531-6.258-4.133-9.292-4.459-8.443-2.217-18.644-1.632-28.175C715.415,
                            587.921,714.451,578.739,716.7,570.093Z"
                            transform="translate(-370.126 -308.483)"
                        />
                        <path
                            id="Path_55"
                            data-name="Path 55"
                            d="M683.566,473.768a25.85,25.85,0,0,1,7.27-10.459,62.425,
                            62.425,0,0,1,10.532-7.36l-6.591-.992a11.98,11.98,0,0,0-4.067-.143,8.861,8.861,0,0,
                            0-5.444,4.257A17.634,17.634,0,0,0,683.566,473.768Z"
                            transform="translate(-351.128 -244.191)"
                        />
                        <path
                            id="Path_56"
                            data-name="Path 56"
                            d="M706.6,462.858A47.266,47.266,0,0,0,689.018,478.3a10.689,10.689,0,0,0-2.09,10.2,
                            51.024,51.024,0,0,0,4.32,12c1.341,2.633,3.118,5.323,5.9,6.323,4.111,1.48,8.547-1.356,
                            11.367-4.692a29.091,29.091,0,0,0,6.724-20.554,6.67,6.67,0,0,0-.766-3.042,18.755,18.755,
                            0,0,0-1.861-2.109c-2.677-3.19-1.655-8.192-3.791-11.767A4.667,4.667,0,0,0,706.6,462.858Z"
                            transform="translate(-353.428 -249.177)"
                        />
                        <path
                            id="Path_98"
                            data-name="Path 98"
                            d="M807.269,778.778c2.945-3.745,3.824-8.674,4.43-13.4,1.5-11.711,
                            1.694-24.434-4.7-34.358-.552,5.035-3.81,8.726-5.212,13.085-1.332,4.142-.914,8.62-.4,
                            12.94.5,4.253,1.08,8.5,1.658,12.74a38.658,38.658,0,0,0,1.775,8.359c.249.666.717,1.436,
                            1.425,1.37A1.635,1.635,0,0,0,807.269,778.778Z"
                            transform="translate(-423.327 -412.97)"
                        />
                        <path
                            id="Path_99"
                            data-name="Path 99"
                            d="M832.247,772.136a8.062,8.062,0,0,0,2.5,2.859c1.633,1.355,3.414,2.773,5.53,
                            2.935a.927.927,0,0,0,.753-.186,1,1,0,0,0,.205-.6,97.787,97.787,0,0,0,.62-9.9,
                            65.568,65.568,0,0,0-.6-10.324,98.728,98.728,0,0,
                            0-2.652-11.529q-2.069-7.585-4.136-15.17a3.505,3.505,0,0,0-1.361-2.266c-2.405,
                            1.415-2.787,4.7-2.7,7.487.332,10.615,3.515,21.226,1.835,31.712C831.971,768.82,
                            831.594,770.584,832.247,772.136Z"
                            transform="translate(-441.345 -411.1)"
                        />
                        <path
                            id="Path_100"
                            data-name="Path 100"
                            d="M820.856,875.215c-.057,2.586-.362,5.228.333,7.719a3.935,3.935,0,0,1,3.672-2.635c2.8.026,
                            4.595,2.882,5.851,5.38l.245-6.528a219.583,219.583,0,0,1,.228-27.215,18.8,18.8,0,0,
                            1-10.439-7,16.491,16.491,0,0,1-6.468,7.515A39.931,39.931,0,0,1,820.856,875.215Z"
                            transform="translate(-431.504 -482.55)"
                        />
                        <path
                            id="Path_102"
                            data-name="Path 102"
                            d="M823.221,705.351l-3.1-6.922q-1.083,3.582-2.166,7.164a7.41,7.41,0,0,0-.471,3.2,
                            5.532,5.532,0,0,0,2.959,3.718l2.689-4.238a2.388,2.388,0,0,0,.089-2.92Z"
                            transform="translate(-433.436 -393.064)"
                        />
                        <path
                            id="Path_103"
                            data-name="Path 103"
                            d="M830.129,496.668c-.13,6.149-.279,12.406-2.223,18.241-2.227,6.684-6.673,12.387-11.369,
                            17.639,1.3,12.963,2.371,26.008,3.008,39.074a74.647,74.647,0,0,1,9.6,
                            18.273c-1.985-15.52.736-31.2,2.713-46.72S835.02,511.531,830.129,496.668Z"
                            transform="translate(-432.883 -269.831)"
                        />
                        <path
                            id="Path_104"
                            data-name="Path 104"
                            d="M788.543,596.789c-.544-8.87.419-18.051-1.825-26.7a18.378,18.378,0,0,
                            1-14.634-10.143c.361,11.78.728,23.623,3.006,35.186,1.321,6.711,3.284,13.31,
                            4.021,20.11.636,5.868.432,12.111,3.325,17.261l.342,1.75c.061-3.43,2.531-6.259,
                            4.133-9.292C791.369,616.521,789.128,606.32,788.543,596.789Z"
                            transform="translate(-405.732 -308.482)"
                        />
                        <path
                            id="Path_105"
                            data-name="Path 105"
                            d="M810.781,463.308a25.847,25.847,0,0,1,7.27,10.459,17.635,17.635,0,0,0-1.7-14.7,
                            8.859,8.859,0,0,0-5.444-4.257,11.971,11.971,0,0,0-4.067.143l-6.591.991A62.407,
                            62.407,0,0,1,810.781,463.308Z"
                            transform="translate(-422.935 -244.19)"
                        />
                        <path
                            id="Path_106"
                            data-name="Path 106"
                            d="M788.518,506.827c2.78-1,4.556-3.691,5.9-6.324a51.022,51.022,0,0,0,4.32-12,
                            10.687,10.687,0,0,0-2.09-10.2,47.271,47.271,0,0,0-17.578-15.446,4.667,4.667,0,
                            0,0-2.222,1.806c-2.135,3.575-1.114,8.577-3.791,11.767a18.764,18.764,0,0,0-1.861,
                            2.109,6.66,6.66,0,0,0-.766,3.041,29.091,29.091,0,0,0,6.724,20.554C779.971,505.471,
                            784.407,508.307,788.518,506.827Z"
                            transform="translate(-404.679 -249.176)"
                        />
                    </g>
                    <g className={classNames(css["back"])}>
                        <path
                            id="Path_57"
                            data-name="Path 57"
                            d="M734.516,402.893q-.319-17.578.808-35.142a58.064,58.064,0,0,0,0-11.967,17.893,
                            17.893,0,0,0-5.025-10.634c-5.519,11.375-11.106,22.963-13.04,35.458a5.8,5.8,0,0,0,
                            .251,3.609c1.2,2.257,4.418,2.086,6.8,3.017,2.65,1.036,4.383,3.633,5.38,6.3s1.428,
                            5.515,2.432,8.178C732.578,402.931,733.511,404.311,734.516,402.893Z"
                            transform="translate(-372.145 -177.286)"
                        />
                        <path
                            id="Path_58"
                            data-name="Path 58"
                            d="M691.353,404.008l.065,16.849,4.461-1.7a4.972,4.972,0,0,0,3.871-4.888l1.137-5.9a2.049,
                            2.049,0,0,0-.235-1.832,2.206,2.206,0,0,0-1.191-.478,12.335,12.335,0,0,1-8.378-6.346C690.8,
                            401.607,690.995,403.572,691.353,404.008Z"
                            transform="translate(-356.175 -210.612)"
                        />
                        <path
                            id="Path_59"
                            data-name="Path 59"
                            d="M679.4,209.111c5.646,7.612,9.839,16.18,14.6,24.373,5.022,8.638,10.692,16.88,16.355,
                            25.112q1.45-19.974.932-40.026a12.083,12.083,0,0,0-.359-3.129,12.286,12.286,0,0,
                            0-1.659-3.169l-4.467-6.821a6.06,6.06,0,0,0-2.517-2.539c-1.708-.693-3.589.274-5.227,
                            1.118A47.651,47.651,0,0,1,679.4,209.111Z"
                            transform="translate(-349.124 -90.263)"
                        />
                        <path
                            id="Path_60"
                            data-name="Path 60"
                            d="M700.959,284.723l-9.641,19.6a9.052,9.052,0,0,1-2.6,3.613c-2.244,
                            1.578-5.563.707-7.417-1.315s-2.517-4.864-2.74-7.6-.077-5.51
                            -.624-8.2c-.957-4.7-3.942-8.738-5.346-13.323-2.49-8.124.218-16.85,
                            3.112-24.9l2.865-3.91c2.847,1.946,4.563,5.129,
                            6.044,8.244,3.573,7.514,6.518,15.538,12.075,21.731C698.612,280.8,701.782,283.05,700.959,
                            284.723Z"
                            transform="translate(-344.365 -118.365)"
                        />
                        <path
                            id="Path_107"
                            data-name="Path 107"
                            d="M769.714,401.709c1-2.662,1.435-5.513,2.432-8.178s2.73-5.261,5.38-6.3c2.38-.931,5.6-.76,
                            6.8-3.017a5.8,5.8,0,0,0,.251-3.609c-1.934-12.495-7.521-24.083-13.04-35.458a17.893,17.893,0,
                            0,0-5.025,10.633,58.038,58.038,0,0,0,0,11.967q1.126,17.544.808,35.142C768.321,404.31,
                            769.254,402.93,769.714,401.709Z"
                            transform="translate(-402.132 -177.285)"
                        />
                        <path
                            id="Path_108"
                            data-name="Path 108"
                            d="M815.432,414.263a4.974,4.974,0,0,0,3.871,4.888l4.461,
                            1.7q.033-8.424.066-16.849c.358-.436.554-2.4.269-4.295a12.335,12.335,0,0,1-8.378,6.346,
                            2.206,2.206,0,0,0-1.191.478,2.047,2.047,0,0,0-.235,1.832Z"
                            transform="translate(-431.454 -210.611)"
                        />
                        <path
                            id="Path_109"
                            data-name="Path 109"
                            d="M778.39,202.911a6.067,6.067,0,0,0-2.517,2.54l-4.467,6.821a12.305,12.305,0,0,
                            0-1.659,3.169,12.118,12.118,0,0,0-.359,3.129q-.517,20.02.933,40.026c5.663-8.232,
                            11.333-16.474,16.355-25.112,4.764-8.193,8.957-16.761,14.6-24.373a47.648,47.648,0,0,
                            1-17.661-5.081C781.978,203.185,780.1,202.218,778.39,202.911Z"
                            transform="translate(-404.002 -90.262)"
                        />
                        <path
                            id="Path_110"
                            data-name="Path 110"
                            d="M803.374,306.618c1.854-2.022,2.518-4.865,2.74-7.6s.076-5.51.624-8.2c.957-4.7,
                            3.942-8.738,5.347-13.323,2.489-8.124-.218-16.85-3.112-24.9l-2.864-3.91c-2.848,
                            1.946-4.563,5.129-6.045,8.244-3.573,7.515-6.518,15.538-12.075,21.731-1.924,
                            2.145-5.094,4.39-4.272,6.063l9.641,19.6a9.046,9.046,0,0,0,2.6,3.613C798.2,309.51,
                            801.52,308.64,803.374,306.618Z"
                            transform="translate(-412.755 -118.365)"
                        />
                    </g>
                    <g className={classNames(css["chest"])}>
                        <path
                            id="Path_31"
                            data-name="Path 31"
                            d="M198.28,254.565A8.987,8.987,0,0,1,200.4,248.3a17.09,17.09,0,0,1,5.3-4.152c6.482-3.507,
                            13.985-4.632,21.345-5.008,1.765-.09,3.674-.1,5.115.923,2.144,1.522,2.329,4.579,2.308,
                            7.208q-.062,7.7-.124,15.392a15.3,15.3,0,0,1-1.018,6.549,10.017,10.017,0,0,1-5.776,
                            4.811,24.9,24.9,0,0,1-7.594,1.148,33.028,33.028,0,0,1-9.263-.7,10.38,10.38,0,0,
                            1-5.241-2.833c-2.656-2.939-2.359-7.418-3.621-11.173A17.522,17.522,0,0,0,198.28,254.565Z"
                            transform="translate(-55.243 -112.511)"
                        />
                        <path
                            id="Path_79"
                            data-name="Path 79"
                            d="M329.129,254.565a8.986,8.986,0,0,0-2.118-6.267,17.1,17.1,0,0,
                            0-5.3-4.152c-6.483-3.507-13.985-4.632-21.345-5.008-1.765-.09-3.674-.1-5.115.923-2.144,
                            1.522-2.329,4.579-2.308,7.208q.062,7.7.124,15.392a15.3,15.3,0,0,0,1.018,6.549,10.02,
                            10.02,0,0,0,5.776,4.811,24.912,24.912,0,0,0,7.594,1.148,33.011,33.011,0,0,0,9.262-.7,
                            10.375,10.375,0,0,0,5.242-2.832c2.656-2.939,2.36-7.418,3.622-11.173A17.521,17.521,0,0,
                            1,329.129,254.565Z"
                            transform="translate(-113.076 -112.51)"
                        />
                    </g>
                    <g className={classNames(css["abs"])}>
                        <path
                            id="Path_33"
                            data-name="Path 33"
                            d="M266.719,335.459c-.288,2.256.725,4.808-.561,6.685-1,1.456-2.941,1.818-4.658,
                            2.22a28.762,28.762,0,0,0-7.954,3.176,1.009,1.009,0,0,
                            1-.744.206c-.362-.1-.478-.547-.532-.919-.426-2.956-.75-6.185.8-8.735a9.981,
                            9.981,0,0,1,5.085-3.812,19.272,19.272,0,0,1,3.919-1.058,5.643,5.643,0,0,1,
                            3.137.186A2.4,2.4,0,0,1,266.719,335.459Z"
                            transform="translate(-88.02 -169.919)"
                        />
                        <path
                            id="Path_34"
                            data-name="Path 34"
                            d="M268.034,365.868c-3.211-.844-6.548.61-9.448,2.226a7.62,7.62,0,0,0-2.168,1.591,
                            8.058,8.058,0,0,0-1.424,3.4,6.937,6.937,0,0,0-.3,2.626,2.361,2.361,0,0,0,1.531,
                            1.995,3.485,3.485,0,0,0,1.845-.166l6.84-1.895a5.045,5.045,0,0,0,2.4-1.155,3.938,
                            3.938,0,0,0,.8-1.425A11.532,11.532,0,0,0,268.034,365.868Z"
                            transform="translate(-89.692 -189.788)"
                        />
                        <path
                            id="Path_35"
                            data-name="Path 35"
                            d="M270.916,395.994a46.247,46.247,0,0,1,.287,6.255,2.425,2.425,0,0,1-.318,1.381,2.6,
                            2.6,0,0,1-1.668.8,33.232,33.232,0,0,0-7.054,2.355c-.418.194-.925.393-1.312.145a1.322,
                            1.322,0,0,1-.444-1.058,16.151,16.151,0,0,1,.2-4.1,6.452,6.452,0,0,1,.723-2.187,6.663,
                            6.663,0,0,1,3.316-2.5,18.57,18.57,0,0,1,4.213-1.2A3.994,3.994,0,0,1,270.916,395.994Z"
                            transform="translate(-93.174 -208.229)"
                        />
                        <path
                            id="Path_36"
                            data-name="Path 36"
                            d="M270.834,422.3l-8.063,2.358a4.108,4.108,0,0,0-1.82.882,3.905,3.905,0,0,0-.871,
                            2.328c-.985,7.609.446,15.317,2.255,22.773,1.49,6.14,3.262,12.275,6.277,17.828.412.759,
                            1.122,1.625,1.948,1.37.735-.227.921-1.166.952-1.934.14-3.46-.26-6.916-.449-10.373a84.664,
                            84.664,0,0,1,.2-12.042c.266-3.024.7-6.033.918-9.06.27-3.682.234-7.379.2-11.07a5.136,5.136,
                            0,0,0-.258-1.9,1.747,1.747,0,0,0-1.415-1.153C270.064,422.276,269.413,422.9,270.834,422.3Z"
                            transform="translate(-92.809 -224.409)"
                        />
                        <path
                            id="Path_37"
                            data-name="Path 37"
                            d="M231.568,354.156a35.29,35.29,0,0,0-7.455,8.041c-1.379,2.054-2.575,4.4-2.443,6.868a9.259,
                            9.259,0,0,0,2.262,5.263,27.557,27.557,0,0,0,4.286,3.93,28.953,28.953,0,0,0,4.964,
                            3.41c.34.17.785.314,1.068.06a.883.883,0,0,0,
                            .233-.507c.342-1.785-.6-3.527-1.245-5.227-2.562-6.75-.546-14.307-1.213-21.5C231.7,
                            354.376,231.3,354.558,231.568,354.156Z"
                            transform="translate(-69.539 -182.786)"
                        />
                        <path
                            id="Path_38"
                            data-name="Path 38"
                            d="M224.351,329.312a27.828,27.828,0,0,1-17.453-5.025c-.487,1.836.239,3.746.857,
                            5.541A52.2,52.2,0,0,1,210.5,343.65a8.578,8.578,0,0,0,.834,3.968,38.152,38.152,
                            0,0,1,11.261-14.751C223.7,331.984,225,330.688,224.351,329.312Z"
                            transform="translate(-60.425 -164.542)"
                        />
                        <path
                            id="Path_39"
                            data-name="Path 39"
                            d="M234.1,418.4a28.881,28.881,0,0,1-12.313-8.256q-.236,6.386-.29,12.777a36.414,36.414,0,0,
                            0,.4,6.893,31.2,31.2,0,0,0,4.442,10.394,86.926,86.926,0,0,0,8.957,12.172,16.818,16.818,0,
                            0,0,5.956,4.822,115.59,115.59,0,0,1-5.493-19.408c-1-5.265-1.639-10.688-.762-15.975a5.093,
                            5.093,0,0,0,.074-2.154C234.879,418.962,234.231,418.324,234.1,418.4Z"
                            transform="translate(-69.435 -216.98)"
                        />
                        <path
                            id="Path_81"
                            data-name="Path 81"
                            d="M294.124,335.458c.288,2.257-.725,4.809.561,6.685,1,1.455,2.941,1.817,4.658,2.22a28.764,
                            28.764,0,0,1,7.954,3.176,1.01,1.01,0,0,0,
                            .745.205c.362-.1.478-.546.532-.919.426-2.956.75-6.185-.8-8.735a9.98,9.98,0,0,
                            0-5.086-3.812,19.238,19.238,0,0,0-3.919-1.058,5.642,5.642,0,0,0-3.137.186A2.4,2.4,0,0,0,
                            294.124,335.458Z"
                            transform="translate(-113.733 -169.919)"
                        />
                        <path
                            id="Path_82"
                            data-name="Path 82"
                            d="M294.249,365.868c3.211-.843,6.548.611,9.448,2.226a7.623,7.623,0,0,1,2.168,1.591,
                            8.066,8.066,0,0,1,1.424,3.4,6.941,6.941,0,0,1,.3,2.626,2.361,2.361,0,0,1-1.531,1.994,
                            3.482,3.482,0,0,1-1.845-.166l-6.84-1.895a5.041,5.041,0,0,1-2.4-1.155,3.935,3.935,0,0,
                            1-.8-1.425A11.532,11.532,0,0,1,294.249,365.868Z"
                            transform="translate(-113.501 -189.788)"
                        />
                        <path
                            id="Path_83"
                            data-name="Path 83"
                            d="M296.288,395.993a46.2,46.2,0,0,0-.287,6.255,2.426,2.426,0,0,0,.318,1.38,2.6,2.6,0,0,0,
                            1.668.8,33.243,33.243,0,0,1,7.054,2.356c.418.194.924.393,1.312.144a1.321,1.321,0,0,0,
                            .444-1.057,16.148,16.148,0,0,0-.2-4.1,6.446,6.446,0,0,0-.723-2.187,6.666,6.666,0,0,
                            0-3.316-2.5,18.609,18.609,0,0,0-4.213-1.2A4,4,0,0,0,296.288,395.993Z"
                            transform="translate(-114.939 -208.228)"
                        />
                        <path
                            id="Path_84"
                            data-name="Path 84"
                            d="M293.565,422.3l8.063,2.358a4.106,4.106,0,0,1,1.82.882,3.909,3.909,0,0,1,.871,
                            2.328c.985,7.609-.446,15.317-2.255,22.773-1.49,6.141-3.262,12.275-6.278,
                            17.828-.412.759-1.122,1.625-1.948,
                            1.37-.734-.227-.92-1.166-.952-1.934-.14-3.46.26-6.915.449-10.372a84.713,84.713,0,0,
                            0-.2-12.042c-.266-3.024-.7-6.033-.918-9.06-.27-3.682-.234-7.378-.2-11.07a5.136,5.136,
                            0,0,1,.258-1.9,1.746,1.746,0,0,1,1.415-1.153C294.335,422.276,294.985,422.9,293.565,422.3Z"
                            transform="translate(-112.499 -224.409)"
                        />
                        <path
                            id="Path_85"
                            data-name="Path 85"
                            d="M332.438,354.156a35.282,35.282,0,0,1,7.455,8.041c1.379,2.054,2.575,4.4,2.443,
                            6.868a9.258,9.258,0,0,1-2.262,5.263,27.6,27.6,0,0,1-4.287,3.93,28.948,28.948,0,0,
                            1-4.963,3.41c-.34.169-.785.314-1.068.06a.882.882,0,0,1-.233-.507c-.342-1.785.6-3.527,
                            1.245-5.227,2.562-6.75.546-14.307,1.214-21.5C332.3,354.376,332.7,354.558,332.438,354.156Z"
                            transform="translate(-135.377 -182.786)"
                        />
                        <path
                            id="Path_86"
                            data-name="Path 86"
                            d="M331.978,329.312a27.83,27.83,0,0,0,17.453-5.025c.487,1.836-.239,3.745-.857,
                            5.541a52.233,52.233,0,0,0-2.742,13.822,8.569,8.569,0,0,1-.834,3.968,38.149,38.149,0,
                            0,0-11.261-14.751C332.627,331.984,331.325,330.688,331.978,329.312Z"
                            transform="translate(-136.813 -164.542)"
                        />
                        <path
                            id="Path_87"
                            data-name="Path 87"
                            d="M319.135,418.4a28.888,28.888,0,0,0,12.313-8.256q.236,6.386.29,12.777a36.381,36.381,
                            0,0,1-.4,6.893A31.206,31.206,0,0,1,326.9,440.2a86.9,86.9,0,0,1-8.957,12.171,16.824,
                            16.824,0,0,1-5.957,4.822,115.6,115.6,0,0,0,5.493-19.408c1-5.265,
                            1.639-10.688.762-15.975a5.093,5.093,0,0,1-.074-2.154C318.356,418.961,319,418.324,319.135,
                            418.4Z"
                            transform="translate(-124.709 -216.98)"
                        />
                    </g>
                    <path
                        id="Path_28"
                        data-name="Path 28"
                        d="M123.523,493.668c-.627,1.942-1.532,3.787-2.124,5.74a9.785,9.785,0,0,0-.181,6.017,12.4,
                        12.4,0,0,0,2.021,3.347,5.771,5.771,0,0,1,.934,1.513,4.756,4.756,0,0,1,.221,1.465,30.954,
                        30.954,0,0,1-1.127,7.857,4.3,4.3,0,0,1-.59,1.57,1.3,1.3,0,0,1-2.433-.417,4.605,4.605,0,0,
                        1-.072-1.513,17.936,17.936,0,0,0-.525-6.267,1,1,0,0,0-.51-.715,1.03,1.03,0,0,0-.923.32,
                        27.265,27.265,0,0,0-3.655,3.546,3.138,3.138,0,0,0-.856,3.892,34.185,34.185,0,0,0,3.493,
                        6.832,4.089,4.089,0,0,1,.885,2.021c.024.745-.6,1.56-1.332,1.423a1.877,1.877,0,0,
                        1-.95-.662c-3.044-3.263-6.127-6.712-7.476-10.966a9.689,9.689,0,0,1-.513-3.023,12.832,
                        12.832,0,0,1,.609-3.331q.986-3.471,2.3-6.837a8.226,8.226,0,0,1,1.052-2.058c.929-1.208,
                        2.4-1.834,3.68-2.665a25.452,25.452,0,0,0,5.256-5.164,8.924,8.924,0,0,1,2.617-2.36A1.045,
                        1.045,0,0,0,123.523,493.668Z"
                        transform="translate(0 -267.733)"
                    />
                    <path
                        id="Path_29"
                        data-name="Path 29"
                        d="M223.789,202.247c-2.783,2.809-6.792,3.994-10.677,4.731s-7.9,1.152-11.482,2.831c6.215.616,
                        12.522,1.253,18.338,3.53a29.182,29.182,0,0,0,3.488,1.268,20.426,20.426,0,0,0,
                        3.8.458l6.961.4A72.917,72.917,0,0,1,223.789,202.247Z"
                        transform="translate(-57.305 -90.001)"
                    />
                    <path
                        id="Path_30"
                        data-name="Path 30"
                        d="M262.3,174.169l-.22,7.541a8.761,8.761,0,0,0,.1,2.028,8.463,8.463,0,0,0,1.492,2.978,121.1,
                        121.1,0,0,0,7.782,10.149,9.379,9.379,0,0,0-.4-2.767l-2.969-11.985a17.431,17.431,0,0,
                        0-1.544-4.458,8.773,8.773,0,0,0-4.168-3.722C262.218,174.018,262.173,174.252,262.3,174.169Z"
                        transform="translate(-94.218 -72.708)"
                    />
                    <path
                        id="Path_32"
                        data-name="Path 32"
                        d="M284.112,319.661l-.247,4.887a5.851,5.851,0,0,
                        0-3.578-1.087c-.47.016-1.052.032-1.277-.381a3.774,3.774,0,0,1,1.358-.63,7.692,
                        7.692,0,0,0,3.263-2.2A6.347,6.347,0,0,0,284.112,319.661Z"
                        transform="translate(-104.568 -161.717)"
                    />
                    <path
                        id="Path_47"
                        data-name="Path 47"
                        d="M191.183,891.835a32.293,32.293,0,0,1-2.751,18.959,44.176,44.176,0,0,1-11.962,
                        15.141c-.566.471-1.2,1.048-1.156,1.784a1.878,1.878,0,0,0,.556,1.109c1.652,1.823,
                        4.395,2.056,6.854,2.061q3.785.008,7.561-.31c2.385-.2,4.9-.556,6.757-2.069,3.766-3.073,
                        3.133-9.388,6.627-12.768,1.03-1,2.352-1.658,3.355-2.682s1.637-2.664.892-3.889a4.685,
                        4.685,0,0,0-1.745-1.429A40.87,40.87,0,0,1,191.183,891.835Z"
                        transform="translate(-41.23 -511.194)"
                    />
                    <path
                        id="Path_51"
                        data-name="Path 51"
                        d="M678.718,947.94c-.762-1.5-1.865-3.205-3.546-3.2a3.886,3.886,0,0,0-2.8,1.707,11.668,
                        11.668,0,0,1-2.24,2.523,1.744,1.744,0,0,1-1.094.366,2.028,2.028,0,0,1-1.274-.824,9.745,
                        9.745,0,0,1-1.583-2.547c-.418.033-.544.578-.551,1a39.178,39.178,0,0,0,1.514,11.46,8.161,
                        8.161,0,0,0,1.292,2.871c1.688,2.136,5.134,2.275,7.386.744a9.222,9.222,0,0,0,
                        3.6-7.027C679.622,952.3,679.479,949.438,678.718,947.94Z"
                        transform="translate(-340.71 -543.51)"
                    />

                    <path
                        id="Path_61"
                        data-name="Path 61"
                        d="M732.9,167.848a5.229,5.229,0,0,1,4.226,1.472,15.469,15.469,0,0,1,2.741,3.709L743.6,
                        179.3a168.361,168.361,0,0,0,.444-30.091,1.754,1.754,0,0,0-.225-.877,1.339,1.339,0,0,
                        0-.88-.492c-1.521-.287-2.956.729-4.128,1.739a51.215,51.215,0,0,0-3.915,3.753,7.427,7.427,
                        0,0,0-1.692,2.423,7.991,7.991,0,0,0-.357,2.377A62.353,62.353,0,0,0,732.9,167.848Z"
                        transform="translate(-381.667 -56.741)"
                    />
                    <path
                        id="Path_69"
                        data-name="Path 69"
                        d="M587.9,503.723a5.577,5.577,0,0,1-.924,3.906q-1.258,2.356-2.691,4.614c-1.105,
                        1.742-2.339,3.636-2.1,5.684a8.536,8.536,0,0,0,.678,2.209c1.747,4.274,3.592,8.692,
                        6.982,11.826a1.449,1.449,0,0,0,
                        1.235.528c.336-1.822-.557-3.619-1.409-5.265s-1.711-3.471-1.307-5.279a7.223,
                        7.223,0,0,1,1.964-3.183,32.019,32.019,0,0,1,2.934-2.786c.209-.175.5-.355.734-.211a.669.669,0,
                        0,1,.234.386,10.085,10.085,0,0,1,.646,3.053c0,1.379-.564,
                        2.766-.248,4.109.094.4.325.832.728.908.631.119,1.019-.659,
                        1.172-1.283q.778-3.182,1.271-6.426a8.446,8.446,0,0,0,.132-2.086,8.061,8.061,0,0,
                        0-.471-1.854,7.978,7.978,0,0,0-1.383-2.689,8.891,8.891,0,0,
                        0-2.179-1.641c-2.021-1.22-4.119-2.538-5.287-4.589a1.429,1.429,0,0,0-.356-.476.433.433,0,0,
                        0-.549,0C587.565,503.324,587.667,503.633,587.9,503.723Z"
                        transform="translate(-289.723 -273.749)"
                    />
                    <path
                        id="Path_75"
                        data-name="Path 75"
                        d="M434.681,493.668c.627,1.942,1.532,3.787,2.124,5.74a9.781,9.781,0,0,1,.181,6.017,12.41,
                        12.41,0,0,1-2.02,3.347,5.749,5.749,0,0,0-.934,1.513,4.757,4.757,0,0,0-.221,1.465,30.94,
                        30.94,0,0,0,1.128,7.857,4.291,4.291,0,0,0,.59,1.57,1.3,1.3,0,0,0,2.433-.417,4.615,4.615,0,
                        0,0,.072-1.513,17.934,17.934,0,0,1,.525-6.267,1,1,0,0,1,.51-.716,1.031,1.031,0,0,1,.923.321,
                        27.245,27.245,0,0,1,3.654,3.546,3.138,3.138,0,0,1,.856,3.892,34.2,34.2,0,0,1-3.493,6.832,
                        4.089,4.089,0,0,0-.885,2.022c-.025.746.6,1.56,1.332,1.423a1.875,1.875,0,0,0,
                        .95-.662c3.044-3.263,6.127-6.713,7.476-10.966a9.681,9.681,0,0,0,.513-3.023,
                        12.832,12.832,0,0,0-.609-3.331q-.986-3.471-2.3-6.836a8.228,8.228,0,0,
                        0-1.052-2.058c-.929-1.208-2.4-1.833-3.68-2.665a25.428,25.428,0,0,1-5.256-5.164,
                        8.934,8.934,0,0,0-2.617-2.36A1.039,1.039,0,0,1,434.681,493.668Z"
                        transform="translate(-199.113 -267.732)"
                    />
                    <path
                        id="Path_76"
                        data-name="Path 76"
                        d="M309.312,202.246c2.783,2.809,6.792,3.994,10.677,4.731s7.9,1.152,11.482,
                        2.832c-6.215.616-12.522,1.253-18.338,3.529a29.023,29.023,0,0,1-3.489,1.268,20.348,20.348,0,0,
                        1-3.8.458l-6.962.4A72.922,72.922,0,0,0,309.312,202.246Z"
                        transform="translate(-116.705 -90.001)"
                    />
                    <path
                        id="Path_77"
                        data-name="Path 77"
                        d="M307.2,174.168l.22,7.541a8.754,8.754,0,0,1-.1,2.027,8.475,8.475,0,0,1-1.492,2.978,
                        121.1,121.1,0,0,1-7.783,10.149,9.4,9.4,0,0,1,.4-2.767l2.969-11.985a17.442,17.442,0,0,1,
                        1.544-4.458,8.776,8.776,0,0,1,4.168-3.722C307.282,174.017,307.327,174.251,307.2,174.168Z"
                        transform="translate(-116.192 -72.707)"
                    />
                    <path
                        id="Path_78"
                        data-name="Path 78"
                        d="M289.148,185.72a110.048,110.048,0,0,0-3.265,13.249,13.659,13.659,0,0,1-1.553,4.966,
                        5.338,5.338,0,0,1-.409.564,4.319,4.319,0,0,1-.413-.56,13.66,13.66,0,0,1-1.557-4.97,
                        110.776,110.776,0,0,0-3.262-13.249,10.323,10.323,0,0,0,5.219,1.444h.019A10.334,10.334,0,0,0,
                        289.148,185.72Z"
                        transform="translate(-104.372 -79.907)"
                    />
                    <path
                        id="Path_80"
                        data-name="Path 80"
                        d="M292.124,319.661l.248,4.887a5.85,5.85,0,0,1,3.577-1.087c.47.015,1.052.032,1.277-.381a3.767,
                        3.767,0,0,0-1.358-.63,7.694,7.694,0,0,1-3.263-2.2A6.339,6.339,0,0,1,292.124,319.661Z"
                        transform="translate(-112.578 -161.717)"
                    />

                    <path
                        id="Path_95"
                        data-name="Path 95"
                        d="M341.385,891.834a32.294,32.294,0,0,0,2.751,18.959A44.167,44.167,0,0,0,356.1,
                        925.934c.566.472,1.2,1.049,1.156,1.784a1.88,1.88,0,0,1-.556,1.11c-1.651,1.823-4.394,
                        2.055-6.854,2.06q-3.785.008-7.561-.31c-2.385-.2-4.9-.556-6.757-2.069-3.766-3.073-3.134
                        -9.388-6.627-12.768-1.03-1-2.352-1.658-3.355-2.682s-1.637-2.664-.892-3.889a4.682,4.682,0,0,1,
                        1.745-1.429A40.871,40.871,0,0,0,341.385,891.834Z"
                        transform="translate(-132.247 -511.194)"
                    />
                    <path
                        id="Path_97"
                        data-name="Path 97"
                        d="M282.119,71.606c-.541-4.293-1.461-8.728-4.124-12.138a11.284,11.284,0,0,
                        0-7.457-4.275h0s-5.029-.654-7.766-.019h0a11.231,11.231,0,0,0-7.667,4.3c-2.664,
                        3.41-3.584,7.845-4.124,12.138a92.82,92.82,0,0,0-.247,20.989,4.143,4.143,0,0,0,
                        1.756,3.5,99.64,99.64,0,0,0,13.5,9.963,1.123,1.123,0,0,0,1.131,0,99.673,99.673,
                        0,0,0,13.5-9.963,4.145,4.145,0,0,0,1.756-3.5A92.839,92.839,0,0,0,282.119,71.606Z"
                        transform="translate(-87.002)"
                    />
                    <path
                        id="Path_101"
                        data-name="Path 101"
                        d="M842.981,945.967a9.736,9.736,0,0,1-1.583,2.547,2.027,2.027,0,0,1-1.273.824,
                        1.745,1.745,0,0,1-1.094-.366,11.666,11.666,0,0,1-2.24-2.523,3.885,3.885,0,0,
                        0-2.8-1.707c-1.681-.008-2.784,1.7-3.546,3.2s-.9,4.358-.7,7.073a9.221,9.221,0,0,
                        0,3.6,7.027c2.252,1.531,5.7,1.392,7.386-.744a8.172,8.172,0,0,0,1.292-2.871,
                        39.189,39.189,0,0,0,1.514-11.46C843.525,946.545,843.4,946,842.981,945.967Z"
                        transform="translate(-440.901 -543.509)"
                    />
                    <path
                        id="Path_111"
                        data-name="Path 111"
                        d="M772.5,173.028a15.494,15.494,0,0,1,2.741-3.709,5.232,5.232,0,0,1,4.226-1.472,
                        62.4,62.4,0,0,0,.057-9.714,7.991,7.991,0,0,0-.357-2.377,7.42,7.42,0,0,0-1.692-2.423,
                        51.209,51.209,0,0,0-3.915-3.753c-1.172-1.01-2.608-2.026-4.129-1.739a1.342,1.342,0,0,
                        0-.88.492,1.754,1.754,0,0,0-.225.878,168.293,168.293,0,0,0,.444,30.091Z"
                        transform="translate(-403.15 -56.74)"
                    />
                    <path
                        id="Path_119"
                        data-name="Path 119"
                        d="M921.862,512.242q-1.432-2.255-2.691-4.614a5.578,5.578,0,0,
                        1-.925-3.906c.229-.091.332-.4.192-.543a.433.433,0,0,0-.549,0,1.43,1.43,0,0,
                        0-.357.476c-1.168,2.051-3.266,3.369-5.286,4.589a8.894,8.894,0,0,0-2.179,1.641,
                        7.973,7.973,0,0,0-1.384,2.689,8.094,8.094,0,0,0-.471,1.854,8.465,8.465,0,0,0,.132,
                        2.086q.492,3.239,1.271,6.426c.153.624.54,1.4,1.172,
                        1.283.4-.076.634-.507.728-.908.316-1.343-.249-2.73-.248-4.11a10.054,10.054,0,0,1,
                        .646-3.052.667.667,0,0,1,.234-.387c.232-.144.524.036.734.211a32.045,32.045,0,0,1,
                        2.934,2.786,7.218,7.218,0,0,1,1.964,3.183c.4,1.808-.455,3.633-1.306,5.279s-1.745,
                        3.443-1.408,5.264a1.448,1.448,0,0,0,1.235-.527c3.39-3.134,5.235-7.553,6.982-11.827a8.553,
                        8.553,0,0,0,.678-2.209C924.2,515.878,922.968,513.984,921.862,512.242Z"
                        transform="translate(-488.865 -273.748)"
                    />
                    <path
                        id="Path_120"
                        data-name="Path 120"
                        d="M744.679,93.249a11.413,11.413,0,0,1,2.554,1.746,27.425,27.425,0,0,1,4.721,
                        6.27c2.7-2.612,3.22-6.708,3.385-10.464a109.714,109.714,0,0,
                        0-.082-11.2c-.254-4.34-.811-8.8-2.972-12.577a12.692,12.692,0,0,0-7.6-6.055v0a31.322,31.322,
                        0,0,
                        0-13.025-.139c-.124.021-.246.051-.368.077-.1.021-.2.04-.3.062-.022.005-.043.013-.065.019a12.715,
                        12.715,0,0,0-7.539,6.04c-2.162,3.773-2.718,8.237-2.972,12.577a109.54,
                       109.54,0,0,0-.082,11.2c.165,3.756.681,7.852,3.385,10.464A27.433,27.433,0,0,1,728.439,
                        95a11.258,11.258,0,0,1,2.883-1.9h0Z"
                        transform="translate(-374.059 -3.253)"
                    />
                </g>
            </svg>
        </div>
    );
};

export default MuscleGroup;
