import React, { useContext } from "react";
import { useAuth } from "@fitneks-commons/hooks";
import { BaseComponentProps, Icon, IconName, Separator } from "@fitneks-component-library";
import AppRoutes from "@fitneks-routes";
import { Link, useLocation, useNavigate } from "react-router-dom";
import UserAvatarAndName from "@fitneks-pages/Dashboard/organisms/UserAvatarAndName/UserAvatarAndName";
import { ThemeContext } from "@fitneks-providers";
import { isLearner, isTrainer } from "@fitneks-commons/helpers/functions/UserRoles";
import classNames from "@fitneks-commons/classnames";
import "@fitneks-commons/styles/flex.scss";
import "@fitneks-commons/styles/display.scss";
import css from "./ProfileNavigation.module.scss";
import { getDeviceType } from "@fitneks-commons/getDeviceType";
import { useLogOutMutation } from "./ProfileNavigation.generated";
import Container from "../../../component-library/atoms/Container/Container";

export interface ProfileNavigationProps extends BaseComponentProps<HTMLButtonElement> {
    showAll: boolean;
}

export default function ProfileNavigation({ showAll, className }: ProfileNavigationProps) {
    const navigate = useNavigate();
    const location = useLocation();
    const { onLogout, role, userID } = useAuth();
    const [logOut] = useLogOutMutation();

    const isLiveStreamPage = location.pathname.includes("/streaming") || location.pathname.includes("/trainer/");

    const { updateOffCanvas } = useContext(ThemeContext);
    const handelLogOut: React.MouseEventHandler<HTMLSpanElement> = (e) => {
        e.preventDefault();
        updateOffCanvas("off");
        if (userID) {
            (async () => {
                await logOut({
                    variables: {
                        input: {
                            id: userID,
                        },
                    },
                });
            })();
        }
        onLogout();
        navigate(AppRoutes.login.route() + "?auth=true", { replace: true });
    };

    const handleClick = () => {
        updateOffCanvas("off");
    };

    const isDesktop = getDeviceType("desktop");
    const pathname = useLocation().pathname;
    const isMobile = !isDesktop;

    return (
        <Container className={classNames(className, "m-0")}>
            {isMobile && showAll && (
                <>
                    <button style={{
                            border: "0px",
                            cursor: "pointer",
                            padding: "25px",
                            position:"absolute",
                            right: "0px",
                            background:"none",
                            fontSize: "26px",
                            color:"white"
                        }}
                                onClick={() => updateOffCanvas("off")}>
                            ⛌
                        </button>
                    <UserAvatarAndName
                        orientation={"right"}
                        avatarSize={!isDesktop ? 50 : 90}
                        gap={!isDesktop ? 10 : 20}
                        fontSize={!isDesktop ? 1 : 1.5}
                        className={classNames(css["profile-navigation-avatar"], "mb-lg-3 px-lg-4 mb-3 mb-lg-6")}
                        isSharing={false}
                    />
                    <Separator className={classNames(css["profile-navigation-sep"], "d-none d-lg-block mb-5")} />
                </>
            )}

            <nav className={classNames(css["profile-navigation"])}>
                {showAll && (
                    <>
                        <Link
                            to={AppRoutes.dashboard.children.myProfile.route()}
                            className={classNames(
                                css["profile-navigation-item"],
                                pathname === AppRoutes.dashboard.children.myProfile.route() && css["is-active"],
                                "d-flex align-items-center"
                            )}
                            onClick={handleClick}
                        >
                            <Icon name={IconName.User} width={30} />
                            My Profile
                        </Link>
                        <Link
                            to={AppRoutes.dashboard.children.giftExchange.route()}
                            className={classNames(
                                css["profile-navigation-item"],
                                pathname === AppRoutes.dashboard.children.giftExchange.route() && css["is-active"],
                                "d-flex align-items-center"
                            )}
                            onClick={handleClick}
                        >
                            <Icon name={IconName.Arrows} width={30} />
                            Gift Exchange
                        </Link>
                        <Link
                            to={AppRoutes.dashboard.children.calendar.route()}
                            className={classNames(
                                css["profile-navigation-item"],
                                pathname === AppRoutes.dashboard.children.calendar.route() && css["is-active"],
                                "d-flex align-items-center"
                            )}
                            onClick={handleClick}
                        >
                            <Icon name={IconName.Calendar} width={30} />
                            Calendar
                        </Link>
                        <Link
                            to={AppRoutes.dashboard.children.friends.route()}
                            className={classNames(
                                css["profile-navigation-item"],
                                pathname === AppRoutes.dashboard.children.friends.route() && css["is-active"],
                                "d-flex align-items-center"
                            )}
                            onClick={handleClick}
                        >
                            <Icon name={IconName.Users} width={30} />
                            {isLearner(role) ? "Friends" : "Followers"}
                        </Link>
                        <Separator className={classNames(css["profile-navigation-sep"])} />
                        {isTrainer(role) && (
                            <>
                                <Link
                                    to={AppRoutes.dashboard.children.payment.route()}
                                    className={classNames(
                                        css["profile-navigation-item"],
                                        pathname === AppRoutes.dashboard.children.payment.route() && css["is-active"],
                                        "d-flex align-items-center"
                                    )}
                                    onClick={handleClick}
                                >
                                    <Icon name={IconName.Card} width={30} />
                                    Payment
                                </Link>
                                <Separator className={classNames(css["profile-navigation-sep"])} />
                            </>
                        )}
                    </>
                )}
                <Link
                    to={AppRoutes.dashboard.children.settings.route()}
                    className={classNames(
                        css["profile-navigation-item"],
                        pathname === AppRoutes.dashboard.children.settings.route() && css["is-active"],
                        "d-flex align-items-center"
                    )}
                    onClick={handleClick}
                    reloadDocument={isLiveStreamPage}
                >
                    <Icon name={IconName.Settings} width={30} />
                    Settings
                </Link>
                <Link
                    to={AppRoutes.dashboard.children.feedback.route()}
                    className={classNames(
                        css["profile-navigation-item"],
                        pathname === AppRoutes.dashboard.children.feedback.route() && css["is-active"],
                        "d-flex align-items-center"
                    )}
                    onClick={handleClick}
                    reloadDocument={isLiveStreamPage}
                >
                    <Icon name={IconName.Star} width={30} />
                    Feedback
                </Link>
                <Link
                    to={AppRoutes.dashboard.children.helpAndSupport.route()}
                    className={classNames(
                        css["profile-navigation-item"],
                        pathname === AppRoutes.dashboard.children.helpAndSupport.route() && css["is-active"],
                        "d-flex align-items-center"
                    )}
                    onClick={handleClick}
                    reloadDocument={isLiveStreamPage}
                >
                    <Icon name={IconName.Help} width={30} />
                    Help & Support
                </Link>
                {/*<Link*/}
                {/*    to={AppRoutes.termsAndConditions.route()}*/}
                {/*    className={classNames(*/}
                {/*        css["profile-navigation-item"],*/}
                {/*        pathname === AppRoutes.termsAndConditions.route() && css["is-active"],*/}
                {/*        "d-flex align-items-center"*/}
                {/*    )}*/}
                {/*    onClick={handleClick}*/}
                {/*    reloadDocument={isLiveStreamPage}*/}
                {/*>*/}
                {/*    <Icon name={IconName.User} width={30} />*/}
                {/*    Terms & Conditions*/}
                {/*</Link>*/}
                <Separator className={classNames(css["profile-navigation-sep"])} />
                <span
                    onClick={handelLogOut}
                    className={classNames(
                        css["profile-navigation-item"],
                        css[""],
                        "d-flex align-items-center cursor-pointer"
                    )}
                    aria-label={"Log Out"}
                >
                    <Icon name={IconName.Logout} width={30} />
                    Log Out
                </span>
            </nav>
        </Container>
    );
}
