/* eslint-disable */
/* tslint:disable */
/* THIS IS A GENERATED FILE, DO NOT EDIT. */
import * as Types from '@fitneks-types/Graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ShareStreamMutationVariables = Types.Exact<{
  input: Types.ShareVideoStreamInput;
}>;


export type ShareStreamMutation = { readonly shareVideoStream?: { readonly videoStream?: { readonly id: string } | null } | null };


export const ShareStreamDocument = gql`
    mutation ShareStream($input: shareVideoStreamInput!) {
  shareVideoStream(input: $input) {
    videoStream {
      id
    }
  }
}
    `;
export type ShareStreamMutationFn = Apollo.MutationFunction<ShareStreamMutation, ShareStreamMutationVariables>;

/**
 * __useShareStreamMutation__
 *
 * To run a mutation, you first call `useShareStreamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareStreamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareStreamMutation, { data, loading, error }] = useShareStreamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShareStreamMutation(baseOptions?: Apollo.MutationHookOptions<ShareStreamMutation, ShareStreamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShareStreamMutation, ShareStreamMutationVariables>(ShareStreamDocument, options);
      }
export type ShareStreamMutationHookResult = ReturnType<typeof useShareStreamMutation>;
export type ShareStreamMutationResult = Apollo.MutationResult<ShareStreamMutation>;
export type ShareStreamMutationOptions = Apollo.BaseMutationOptions<ShareStreamMutation, ShareStreamMutationVariables>;