import { useContext, useEffect, useState } from "react";
import { Button, Container, IconName, Modal, Separator, Typography } from "@fitneks-component-library";
import classNames from "@fitneks-commons/classnames";
import css from "./GoLiveBtn.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import AppRoutes from "@fitneks-routes";
import { GoLiveBtnProps, useGetProfileStatusQuery, useGetUpcomingLiveStreamsLazyQuery } from "@fitneks-commons/ui";
import { useAuth, useHandleClickOutside } from "@fitneks-commons/hooks";
import { getDeviceType } from "@fitneks-commons/getDeviceType";
import StreamTypes from "@fitneks-commons/constants/StreamTypes";
import { ThemeContext } from "@fitneks-providers";

const GoLiveBtn = ({ position, fullWidth, className }: GoLiveBtnProps) => {
    const { userID } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const { addNotification } = useContext(ThemeContext);
    const [goLiveOpen, setGoLiveOpen] = useState(false);
    const [stream, setStream] = useState<string | null>(null);

    const { data, loading, refetch } = useGetProfileStatusQuery({
        variables: {
            id: userID ?? "",
        },
        fetchPolicy: "network-only",
    });
    const hasLiveStream = !!data?.user?.hasLiveStream;
    const profileCompleted = !!data?.user?.completedAt;
    const isBoosted = !!data?.user?.profileBoosted || !!data?.user?.notifyBoosted;

    const [getUpcomingLiveStreams, { data: upcomingLiveStream, loading: upcomingLiveStreamLoading }] =
        useGetUpcomingLiveStreamsLazyQuery();

    const redirectTo = (videoStreamType: string) => {
        navigate(AppRoutes.createStream.route(videoStreamType));
    };

    useHandleClickOutside(() => {
        setGoLiveOpen(false);
    });

    useEffect(() => {
        const interval = setInterval(() => {
            refetch();
        }, 1000 * 60);
        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        if (!upcomingLiveStreamLoading && upcomingLiveStream) {
            const edges = upcomingLiveStream?.videoStreams?.edges;
            if (edges?.length) {
                const guid = edges[0]?.node?.guid ?? "";
                setStream(guid);
            } else {
                setGoLiveOpen((prev) => !prev);
            }
        }
    }, [upcomingLiveStream, upcomingLiveStreamLoading]);

    const onGoLive = () => {
        if (!profileCompleted) {
            addNotification({
                message: "Please complete your profile to go live.",
                close: "timeOut",
                type: "warning",
                duration: 3000,
            });
            navigate(AppRoutes.editProfileWithRedirect.route(AppRoutes.createStream.route("class")));
            return;
        }
        if (goLiveOpen) {
            setGoLiveOpen(false);
            return;
        }
        if (!hasLiveStream) {
            onBtnClick();
            return;
        }
        navigate(AppRoutes.doingStream.route());
    };

    const onBtnClick = () => {
        const after = new Date();
        const before = new Date(after.getTime() + 30 * 60000);
        after.setMinutes(after.getMinutes() - 10);

        getUpcomingLiveStreams({
            variables: {
                user: userID ?? "",
                startDatetime: [{ before: before.toUTCString(), after: after.toUTCString() }],
            },
        });
    };

    const isDesktop = getDeviceType("desktop");

    if (
        (!profileCompleted && location.pathname.includes(AppRoutes.editProfile.route())) ||
        location.pathname === AppRoutes.createStream.route(StreamTypes.CLASS) ||
        location.pathname === AppRoutes.editStream.route(StreamTypes.CLASS) ||
        location.pathname === AppRoutes.createStream.route(StreamTypes.CHALLENGE) ||
        location.pathname === AppRoutes.editStream.route(StreamTypes.CHALLENGE) ||
        location.pathname === AppRoutes.doingStream.path ||
        location.pathname.includes("streaming")
    ) {
        return <></>;
    }

    return (
        <div style={{ display: fullWidth ? "block" : "inline-block" }}>
            {fullWidth ? (
                <Button
                    title={"Go Live"}
                    onClick={(e) => {
                        e.stopPropagation();
                        onGoLive();
                    }}
                    disabled={loading}
                    fullWidth
                    className={classNames(className, "mb-3", isBoosted && "purple-gradient")}
                />
            ) : (
                <Button
                    className={classNames(className, "trainer-btn", isBoosted && "purple-gradient")}
                    title={"Go live"}
                    style={"rounded"}
                    size={"small"}
                    startIcon={IconName.F}
                    onClick={(e) => {
                        e.stopPropagation();
                        onGoLive();
                    }}
                    disabled={loading || upcomingLiveStreamLoading}
                />
            )}
            {!isDesktop && (
                <div className={classNames(css["goLive-popover"], !goLiveOpen && css["notVisible"], "pos-fixed")}>
                    <Container
                        className={classNames(css["goLive-popover_challenge"], "t-primary cursor-pointer")}
                        onClick={() => redirectTo("challenge")}
                    >
                        <Typography italic bold size={2.5} align={"center"} uppercase className={"mb-0"}>
                            Challenge
                        </Typography>
                    </Container>
                    <Container
                        className={classNames(css["goLive-popover_class"], "t-white cursor-pointer")}
                        onClick={() => redirectTo("class")}
                    >
                        <Typography italic bold size={2.5} align={"center"} uppercase className={"mb-0"}>
                            Class
                        </Typography>
                    </Container>
                    <Button
                        className={classNames(
                            className,
                            css["goback-btn"],
                            "pos-absolute",
                            isBoosted && "purple-gradient"
                        )}
                        title={"Go back"}
                        style={"rounded"}
                        size={"small"}
                        startIcon={IconName.F}
                        onClick={(e) => {
                            e.stopPropagation();
                            setGoLiveOpen(false);
                        }}
                    />
                </div>
            )}
            {isDesktop && (
                <div className={classNames(!goLiveOpen && css["notVisible"], css["goLiveSelect"])}>
                    <div
                        className={classNames(
                            css["content"],
                            fullWidth && css["full-content"],
                            position === "top" && css["top-pos"],
                            position === "bottom" && css["bottom-pos"]
                        )}
                    >
                        <Typography className={classNames(css["goLive-option"])} onClick={() => redirectTo("class")}>
                            Create class
                        </Typography>
                        <Separator />
                        <Typography
                            className={classNames(css["goLive-option"])}
                            onClick={() => redirectTo("challenge")}
                        >
                            Create challenge
                        </Typography>
                    </div>
                </div>
            )}

            <Modal
                title={"Start live"}
                isOpen={!!stream}
                onClose={() => {
                    setStream(null);
                    setTimeout(() => {
                        setGoLiveOpen(true);
                    }, 100);
                }}
                width={400}
            >
                <Container className={"p-4"}>
                    <Typography tag={"h2"} align={"center"} className={"mb-4"}>
                        You have upcoming live stream in less than 30 minutes
                    </Typography>
                    <Button
                        title={"Go live now"}
                        fullWidth
                        size={"small"}
                        onClick={() => {
                            navigate(AppRoutes.startStream.route(stream ?? ""));
                            setStream(null);
                        }}
                    />
                </Container>
            </Modal>
        </div>
    );
};

export default GoLiveBtn;
