import { useState } from "react";
import { useAuth } from "@fitneks-commons/hooks";
import { LoginAlert } from "@fitneks-commons/ui";

interface LoginAlertProps {
    onClose?: VoidFunction;
}

export const useLoginAlert = () => {
    const { isAuthorized } = useAuth();
    const [isOpen, setIsOpen] = useState(false);

    return {
        guard: (callback: () => void) => {
            isAuthorized ? callback() : setIsOpen(true);
        },
        LoginAlert: (props: LoginAlertProps) => (
            <LoginAlert
                isOpen={isOpen}
                onClose={() => {
                    setIsOpen(false);
                    typeof props.onClose === "function" && props.onClose();
                }}
            />
        ),
    };
};
