import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";
import AppRoutes from "@fitneks-routes";
import ProtectedRoute from "../helpers/ProtectedRoute";
import "./RootLayout";
import RootLayout from "./RootLayout";
import { ErrorBoundary } from "@fitneks-commons/ui";

const Root = (
    <Route element={<RootLayout />} errorElement={<ErrorBoundary />}>
        <Route path={AppRoutes.index.path} element={<AppRoutes.index.element />}>
            <Route path={AppRoutes.login.path} element={<AppRoutes.login.element />} />
            <Route path={AppRoutes.loginWithRedirect.path} element={<AppRoutes.loginWithRedirect.element />} />
            <Route path={AppRoutes.registerWithPromoCode.path} element={<AppRoutes.registerWithPromoCode.element />} />
            <Route path={AppRoutes.register.path} element={<AppRoutes.register.element />} />
            <Route path={AppRoutes.forgotPassword.path} element={<AppRoutes.forgotPassword.element />} />
            <Route path={AppRoutes.resetPassword.path} element={<AppRoutes.resetPassword.element />} />
        </Route>
        <Route path={AppRoutes.search.path} element={<AppRoutes.search.element />} />
        <Route path={AppRoutes.searchWithPhrase.path} element={<AppRoutes.searchWithPhrase.element />} />
        <Route element={<ProtectedRoute />}>
            <Route path={AppRoutes.onboarding.path} element={<AppRoutes.onboarding.element />} />
            <Route
                path={AppRoutes.onboardingWithRedirect.path}
                element={<AppRoutes.onboardingWithRedirect.element />}
            />
            <Route path={AppRoutes.editProfile.path} element={<AppRoutes.editProfile.element />} />
            <Route
                path={AppRoutes.editProfileWithRedirect.path}
                element={<AppRoutes.editProfileWithRedirect.element />}
            />
            <Route path={AppRoutes.dashboard.path} element={<AppRoutes.dashboard.element />}>
                <Route
                    path={AppRoutes.dashboard.children.myProfile.path}
                    element={<AppRoutes.dashboard.children.myProfile.element />}
                />
                <Route
                    path={AppRoutes.dashboard.children.giftExchange.path}
                    element={<AppRoutes.dashboard.children.giftExchange.element />}
                />
                <Route
                    path={AppRoutes.dashboard.children.calendar.path}
                    element={<AppRoutes.dashboard.children.calendar.element />}
                />
                <Route
                    path={AppRoutes.dashboard.children.friends.path}
                    element={<AppRoutes.dashboard.children.friends.element />}
                />
                <Route
                    path={AppRoutes.dashboard.children.payment.path}
                    element={<AppRoutes.dashboard.children.payment.element />}
                />
                <Route
                    path={AppRoutes.dashboard.children.settings.path}
                    element={<AppRoutes.dashboard.children.settings.element />}
                />
                <Route
                    path={AppRoutes.dashboard.children.feedback.path}
                    element={<AppRoutes.dashboard.children.feedback.element />}
                />
                <Route
                    path={AppRoutes.dashboard.children.helpAndSupport.path}
                    element={<AppRoutes.dashboard.children.helpAndSupport.element />}
                />
            </Route>
            <Route path={AppRoutes.createStream.path} element={<AppRoutes.createStream.element />} />
            <Route path={AppRoutes.editStream.path} element={<AppRoutes.editStream.element />} />
            <Route
                path={AppRoutes.editStreamWithRedirect.path}
                element={<AppRoutes.editStreamWithRedirect.element />}
            />
            <Route path={AppRoutes.doingStream.path} element={<AppRoutes.doingStream.element />} />
            <Route path={AppRoutes.startStream.path} element={<AppRoutes.startStream.element />} />
        </Route>
        <Route path={AppRoutes.liveChallenges.path} element={<AppRoutes.liveChallenges.element />} />
        <Route path={AppRoutes.viewStream.path} element={<AppRoutes.viewStream.element />} />
        <Route path={AppRoutes.userProfile.path} element={<AppRoutes.userProfile.element />} />
        <Route path={AppRoutes.feedback.path} element={<AppRoutes.feedback.element />} />
        <Route path={AppRoutes.privacyPolicy.path} element={<AppRoutes.privacyPolicy.element />} />
        <Route path={AppRoutes.termsOfSale.path} element={<AppRoutes.termsOfSale.element />} />
        <Route path={AppRoutes.termsOfUse.path} element={<AppRoutes.termsOfUse.element />} />
        <Route path={AppRoutes.helpAndSupport.path} element={<AppRoutes.helpAndSupport.element />} />
        <Route path={AppRoutes.feedback.path} element={<AppRoutes.feedback.element />} />
        <Route path={AppRoutes.verification.path} element={<AppRoutes.verification.element />} />
        <Route path={AppRoutes.noMatch.path} element={<AppRoutes.noMatch.element />} />
    </Route>
);

export const router = createBrowserRouter(createRoutesFromElements(Root));
