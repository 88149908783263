/* eslint-disable */
/* tslint:disable */
/* THIS IS A GENERATED FILE, DO NOT EDIT. */
import * as Types from '@fitneks-types/Graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EventMuscleRatesQueryVariables = Types.Exact<{
  first: Types.Scalars['Int'];
  videoStream: Types.Scalars['String'];
  after?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type EventMuscleRatesQuery = { readonly videoStreamTrainingMuscleRates?: { readonly edges?: ReadonlyArray<{ readonly node?: { readonly id: string, readonly rate: number, readonly trainingMuscle?: { readonly id: string, readonly name: string } | null } | null } | null> | null, readonly pageInfo: { readonly hasNextPage: boolean, readonly endCursor?: string | null } } | null };

export type GetEarnedPointsQueryVariables = Types.Exact<{
  user: Types.Scalars['String'];
  videoStream: Types.Scalars['String'];
}>;


export type GetEarnedPointsQuery = { readonly trainingEarnedPoints?: { readonly edges?: ReadonlyArray<{ readonly node?: { readonly id: string, readonly points: number, readonly trainingMuscle?: { readonly id: string, readonly name: string } | null } | null } | null> | null } | null };


export const EventMuscleRatesDocument = gql`
    query EventMuscleRates($first: Int!, $videoStream: String!, $after: String) {
  videoStreamTrainingMuscleRates(
    first: $first
    videoStream_guid: $videoStream
    after: $after
  ) {
    edges {
      node {
        id
        rate
        trainingMuscle {
          id
          name
        }
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    `;

/**
 * __useEventMuscleRatesQuery__
 *
 * To run a query within a React component, call `useEventMuscleRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventMuscleRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventMuscleRatesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      videoStream: // value for 'videoStream'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useEventMuscleRatesQuery(baseOptions: Apollo.QueryHookOptions<EventMuscleRatesQuery, EventMuscleRatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventMuscleRatesQuery, EventMuscleRatesQueryVariables>(EventMuscleRatesDocument, options);
      }
export function useEventMuscleRatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventMuscleRatesQuery, EventMuscleRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventMuscleRatesQuery, EventMuscleRatesQueryVariables>(EventMuscleRatesDocument, options);
        }
export type EventMuscleRatesQueryHookResult = ReturnType<typeof useEventMuscleRatesQuery>;
export type EventMuscleRatesLazyQueryHookResult = ReturnType<typeof useEventMuscleRatesLazyQuery>;
export type EventMuscleRatesQueryResult = Apollo.QueryResult<EventMuscleRatesQuery, EventMuscleRatesQueryVariables>;
export const GetEarnedPointsDocument = gql`
    query GetEarnedPoints($user: String!, $videoStream: String!) {
  trainingEarnedPoints(user: $user, videoStream_guid: $videoStream) {
    edges {
      node {
        id
        trainingMuscle {
          id
          name
        }
        points
      }
    }
  }
}
    `;

/**
 * __useGetEarnedPointsQuery__
 *
 * To run a query within a React component, call `useGetEarnedPointsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEarnedPointsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEarnedPointsQuery({
 *   variables: {
 *      user: // value for 'user'
 *      videoStream: // value for 'videoStream'
 *   },
 * });
 */
export function useGetEarnedPointsQuery(baseOptions: Apollo.QueryHookOptions<GetEarnedPointsQuery, GetEarnedPointsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEarnedPointsQuery, GetEarnedPointsQueryVariables>(GetEarnedPointsDocument, options);
      }
export function useGetEarnedPointsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEarnedPointsQuery, GetEarnedPointsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEarnedPointsQuery, GetEarnedPointsQueryVariables>(GetEarnedPointsDocument, options);
        }
export type GetEarnedPointsQueryHookResult = ReturnType<typeof useGetEarnedPointsQuery>;
export type GetEarnedPointsLazyQueryHookResult = ReturnType<typeof useGetEarnedPointsLazyQuery>;
export type GetEarnedPointsQueryResult = Apollo.QueryResult<GetEarnedPointsQuery, GetEarnedPointsQueryVariables>;