import { useContext, useEffect, useState } from "react";
import { Notification, Portal } from "@fitneks-component-library";
import { NotificationType, ThemeContext } from "@fitneks-providers";
import classNames from "@fitneks-commons/classnames";
import css from "./Notification.module.scss";

export default function Notifications() {
    const { notifications } = useContext(ThemeContext);

    const [newNotifications, setNewNotifications] = useState<NotificationType[]>(notifications);

    useEffect(() => {
        setNewNotifications(notifications);
    }, [notifications]);

    const removeNotification = (index: number) => {
        notifications.splice(index, 1);
        setNewNotifications([...notifications]);
    };

    return (
        <Portal id={"notifications-wrapper"}>
            <div className={classNames(css["wrapper"])}>
                {newNotifications &&
                    newNotifications.map((el, index) => (
                        <Notification
                            key={index}
                            {...el}
                            message={el?.message || ""}
                            onClose={() => removeNotification(index)}
                            className={classNames("p-2", css["notification"])}
                        />
                    ))}
            </div>
        </Portal>
    );
}
