/* eslint-disable */
/* tslint:disable */
/* THIS IS A GENERATED FILE, DO NOT EDIT. */
import * as Types from '@fitneks-types/Graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetStreamByGuidQueryVariables = Types.Exact<{
  streamGuid: Types.Scalars['String'];
}>;


export type GetStreamByGuidQuery = { readonly videoStreams?: { readonly edges?: ReadonlyArray<{ readonly node?: { readonly id: string, readonly guid: string, readonly trainingRate?: number | null, readonly ratePoints: number, readonly videoStreamType?: { readonly id: string, readonly name: string } | null } | null } | null> | null } | null };

export type GradeLearnerMutationVariables = Types.Exact<{
  input: Types.GradeLearnerVideoStreamInput;
}>;


export type GradeLearnerMutation = { readonly gradeLearnerVideoStream?: { readonly videoStream?: { readonly id: string } | null } | null };


export const GetStreamByGuidDocument = gql`
    query GetStreamByGuid($streamGuid: String!) {
  videoStreams(guid: $streamGuid) {
    edges {
      node {
        id
        guid
        videoStreamType {
          id
          name
        }
        trainingRate
        ratePoints
      }
    }
  }
}
    `;

/**
 * __useGetStreamByGuidQuery__
 *
 * To run a query within a React component, call `useGetStreamByGuidQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStreamByGuidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStreamByGuidQuery({
 *   variables: {
 *      streamGuid: // value for 'streamGuid'
 *   },
 * });
 */
export function useGetStreamByGuidQuery(baseOptions: Apollo.QueryHookOptions<GetStreamByGuidQuery, GetStreamByGuidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStreamByGuidQuery, GetStreamByGuidQueryVariables>(GetStreamByGuidDocument, options);
      }
export function useGetStreamByGuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStreamByGuidQuery, GetStreamByGuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStreamByGuidQuery, GetStreamByGuidQueryVariables>(GetStreamByGuidDocument, options);
        }
export type GetStreamByGuidQueryHookResult = ReturnType<typeof useGetStreamByGuidQuery>;
export type GetStreamByGuidLazyQueryHookResult = ReturnType<typeof useGetStreamByGuidLazyQuery>;
export type GetStreamByGuidQueryResult = Apollo.QueryResult<GetStreamByGuidQuery, GetStreamByGuidQueryVariables>;
export const GradeLearnerDocument = gql`
    mutation GradeLearner($input: gradeLearnerVideoStreamInput!) {
  gradeLearnerVideoStream(input: $input) {
    videoStream {
      id
    }
  }
}
    `;
export type GradeLearnerMutationFn = Apollo.MutationFunction<GradeLearnerMutation, GradeLearnerMutationVariables>;

/**
 * __useGradeLearnerMutation__
 *
 * To run a mutation, you first call `useGradeLearnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGradeLearnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gradeLearnerMutation, { data, loading, error }] = useGradeLearnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGradeLearnerMutation(baseOptions?: Apollo.MutationHookOptions<GradeLearnerMutation, GradeLearnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GradeLearnerMutation, GradeLearnerMutationVariables>(GradeLearnerDocument, options);
      }
export type GradeLearnerMutationHookResult = ReturnType<typeof useGradeLearnerMutation>;
export type GradeLearnerMutationResult = Apollo.MutationResult<GradeLearnerMutation>;
export type GradeLearnerMutationOptions = Apollo.BaseMutationOptions<GradeLearnerMutation, GradeLearnerMutationVariables>;