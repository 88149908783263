// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Pubnub from "pubnub";

export interface PubNubService {
    init: (pubnub: Pubnub) => void;
    reset: () => void;
    pubnub: Pubnub | undefined;
}

export function usePubNub() {
    const pubnubService: PubNubService = {
        init: (pubnub: Pubnub) => {
            window.pubnub = pubnub;
        },
        reset: () => {
            delete window.pubnub;
        },
        pubnub: window.pubnub,
    };

    return pubnubService;
}
