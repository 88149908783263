/**
 * Helper function to test predefined match queries against current device
 * @param {string} wrapperId Wrapper ID
 *
 * @return HTMLDivElement
 */
export function createWrapperAndAppendToBody(wrapperId: string) {
    const wrapperElement = document.createElement("div");
    wrapperElement.setAttribute("id", wrapperId);
    document.body.appendChild(wrapperElement);
    return wrapperElement;
}
