import { InsufficientFundsModalProps } from "@fitneks-exchange/Exchange.types";
import { Button, Container, Modal, Typography } from "@fitneks-component-library";
import { RubyPurchaseModal } from "@fitneks-commons/ui";
import { useState } from "react";
import { useAuth } from "@fitneks-commons/hooks";
import { isLearner, isTrainer } from "@fitneks-commons/helpers/functions/UserRoles";
import classNames from "@fitneks-commons/classnames";

export const InsufficientFundsModal = ({
    isOpen,
    setIsOpen,
    onPurchase,
    isStreamPage,
}: InsufficientFundsModalProps) => {
    const { role } = useAuth();
    const [rubiesModal, setRubiesModal] = useState(false);
    return (
        <>
            <Modal
                isOpen={isOpen}
                title={"Insufficient Funds"}
                width={640}
                overlay={isStreamPage ? "dark" : "light"}
                shadow
                onClose={() => setIsOpen(false)}
            >
                <Container className={classNames("bg-white py-4", isStreamPage && "bg-black t-white")}>
                    <Typography bold size={1.4} align={"center"}>
                        Insufficient funds
                    </Typography>
                    <Typography align={"center"} bold>
                        {isTrainer(role) && "Insufficient funds. Top up Rubies or start a class to earn Protein!"}
                        {isLearner(role) && "Get more Rubies or join a class to collect FITNEKS Tokens"}
                    </Typography>
                    <Container width={200}>
                        <Button
                            title={"Top Up Rubies"}
                            onClick={() => {
                                setRubiesModal(true);
                                setIsOpen(false);
                            }}
                            fullWidth
                        />
                    </Container>
                </Container>
            </Modal>
            <RubyPurchaseModal
                isOpen={rubiesModal}
                setIsOpen={(e) => setRubiesModal(e)}
                onPurchase={onPurchase}
                isStreamPage={isStreamPage || false}
            />
        </>
    );
};
