import { NotDefined } from "@fitneks-types/primitives";

export function decodeJWT(token: string | NotDefined) {
    if (!token) return null;
    const parts = token.split(".");

    if (parts.length !== 3) {
        console.error("Invalid token structure");
        return null;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const decodedHeader = JSON.parse(atob(parts[0]));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const decodedPayload = JSON.parse(atob(parts[1]));

    return {
        header: decodedHeader,
        payload: decodedPayload,
        signature: parts[2],
    };
}
