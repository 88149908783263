/* eslint-disable */
/* tslint:disable */
/* THIS IS A GENERATED FILE, DO NOT EDIT. */
import * as Types from '@fitneks-types/Graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VideoStreamsQueryVariables = Types.Exact<{
  first: Types.Scalars['Int'];
  after?: Types.InputMaybe<Types.Scalars['String']>;
  TrainingType?: Types.InputMaybe<Types.Scalars['String']>;
  TrainingEquipment?: Types.InputMaybe<Types.Scalars['String']>;
  TrainingMuscle?: Types.InputMaybe<Types.Scalars['String']>;
  videoStreamType?: Types.InputMaybe<Types.Scalars['String']>;
  isLive?: Types.InputMaybe<Types.Scalars['Boolean']>;
  isUpcoming?: Types.InputMaybe<Types.Scalars['Boolean']>;
  private?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type VideoStreamsQuery = { readonly videoStreams?: { readonly edges?: ReadonlyArray<{ readonly node?: { readonly id: string, readonly guid: string, readonly title: string, readonly description?: string | null, readonly isLive: boolean, readonly isUpcoming: boolean, readonly videoStreamViewersCount: number, readonly signedUpUsersCount: number, readonly pointsForComplete: number, readonly startDatetime?: string | null, readonly currentUserSignedUp: boolean, readonly videoStreamType?: { readonly id: string, readonly name: string } | null, readonly user?: { readonly id: string, readonly username: string, readonly displayName: string, readonly OAuthAvatar?: string | null, readonly avatarMediaObject?: { readonly id: string, readonly filePath?: string | null } | null } | null, readonly trainingTypes?: { readonly edges?: ReadonlyArray<{ readonly node?: { readonly id: string, readonly name: string, readonly iconMediaObject?: { readonly filePath?: string | null } | null } | null } | null> | null } | null } | null } | null> | null, readonly pageInfo: { readonly endCursor?: string | null, readonly hasNextPage: boolean } } | null };

export type SortedLiveStreamsQueryVariables = Types.Exact<{
  first: Types.Scalars['Int'];
  page?: Types.InputMaybe<Types.Scalars['Int']>;
  isUpcoming?: Types.InputMaybe<Types.Scalars['Boolean']>;
  streamTypes?: Types.InputMaybe<ReadonlyArray<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
  trainingTypes?: Types.InputMaybe<ReadonlyArray<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
  trainingEquipments?: Types.InputMaybe<ReadonlyArray<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
  trainingMuscle?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type SortedLiveStreamsQuery = { readonly liveVideoStreams?: { readonly edges?: ReadonlyArray<{ readonly node?: { readonly id: string, readonly guid: string, readonly title: string, readonly description?: string | null, readonly isLive: boolean, readonly isUpcoming: boolean, readonly videoStreamViewersCount: number, readonly signedUpUsersCount: number, readonly pointsForComplete: number, readonly startDatetime?: string | null, readonly currentUserSignedUp: boolean, readonly videoStreamType?: { readonly id: string, readonly name: string } | null, readonly user?: { readonly id: string, readonly username: string, readonly displayName: string, readonly OAuthAvatar?: string | null, readonly avatarMediaObject?: { readonly id: string, readonly filePath?: string | null } | null } | null } | null } | null> | null, readonly pageInfo: { readonly endCursor?: string | null, readonly hasNextPage: boolean } } | null };

export type GetFilterOptionsQueryVariables = Types.Exact<{
  first: Types.Scalars['Int'];
}>;


export type GetFilterOptionsQuery = { readonly trainingTypes?: { readonly edges?: ReadonlyArray<{ readonly node?: { readonly __typename: 'TrainingType', readonly id: string, readonly name: string } | null } | null> | null } | null, readonly trainingEquipments?: { readonly edges?: ReadonlyArray<{ readonly node?: { readonly __typename: 'TrainingEquipment', readonly id: string, readonly name: string } | null } | null> | null } | null, readonly trainingMuscles?: { readonly edges?: ReadonlyArray<{ readonly node?: { readonly __typename: 'TrainingMuscle', readonly id: string, readonly name: string } | null } | null> | null } | null };

export type SignUpStreamMutationVariables = Types.Exact<{
  input: Types.SignUpVideoStreamInput;
}>;


export type SignUpStreamMutation = { readonly signUpVideoStream?: { readonly videoStream?: { readonly id: string } | null } | null };


export const VideoStreamsDocument = gql`
    query VideoStreams($first: Int!, $after: String, $TrainingType: String, $TrainingEquipment: String, $TrainingMuscle: String, $videoStreamType: String, $isLive: Boolean, $isUpcoming: Boolean, $private: Boolean) {
  videoStreams(
    first: $first
    after: $after
    trainingTypes: $TrainingType
    trainingEquipments: $TrainingEquipment
    trainingMuscleRates_trainingMuscle: $TrainingMuscle
    videoStreamType_name: $videoStreamType
    isLive: $isLive
    isUpcoming: $isUpcoming
    private: $private
  ) {
    edges {
      node {
        id
        guid
        title
        description
        isLive
        isUpcoming
        videoStreamViewersCount
        signedUpUsersCount
        pointsForComplete
        startDatetime
        currentUserSignedUp
        videoStreamType {
          id
          name
        }
        user {
          id
          username
          displayName
          OAuthAvatar
          avatarMediaObject {
            id
            filePath
          }
        }
        trainingTypes {
          edges {
            node {
              id
              name
              iconMediaObject {
                filePath
              }
            }
          }
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;

/**
 * __useVideoStreamsQuery__
 *
 * To run a query within a React component, call `useVideoStreamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideoStreamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideoStreamsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      TrainingType: // value for 'TrainingType'
 *      TrainingEquipment: // value for 'TrainingEquipment'
 *      TrainingMuscle: // value for 'TrainingMuscle'
 *      videoStreamType: // value for 'videoStreamType'
 *      isLive: // value for 'isLive'
 *      isUpcoming: // value for 'isUpcoming'
 *      private: // value for 'private'
 *   },
 * });
 */
export function useVideoStreamsQuery(baseOptions: Apollo.QueryHookOptions<VideoStreamsQuery, VideoStreamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VideoStreamsQuery, VideoStreamsQueryVariables>(VideoStreamsDocument, options);
      }
export function useVideoStreamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VideoStreamsQuery, VideoStreamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VideoStreamsQuery, VideoStreamsQueryVariables>(VideoStreamsDocument, options);
        }
export type VideoStreamsQueryHookResult = ReturnType<typeof useVideoStreamsQuery>;
export type VideoStreamsLazyQueryHookResult = ReturnType<typeof useVideoStreamsLazyQuery>;
export type VideoStreamsQueryResult = Apollo.QueryResult<VideoStreamsQuery, VideoStreamsQueryVariables>;
export const SortedLiveStreamsDocument = gql`
    query SortedLiveStreams($first: Int!, $page: Int, $isUpcoming: Boolean, $streamTypes: [String], $trainingTypes: [String], $trainingEquipments: [String], $trainingMuscle: String) {
  liveVideoStreams(
    per_page: $first
    page: $page
    stream_types: $streamTypes
    training_types: $trainingTypes
    training_equipments: $trainingEquipments
    training_muscle: $trainingMuscle
    is_upcoming: $isUpcoming
  ) {
    edges {
      node {
        id
        guid
        title
        description
        isLive
        isUpcoming
        videoStreamViewersCount
        signedUpUsersCount
        pointsForComplete
        startDatetime
        currentUserSignedUp
        videoStreamType {
          id
          name
        }
        user {
          id
          username
          displayName
          OAuthAvatar
          avatarMediaObject {
            id
            filePath
          }
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;

/**
 * __useSortedLiveStreamsQuery__
 *
 * To run a query within a React component, call `useSortedLiveStreamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSortedLiveStreamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSortedLiveStreamsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      page: // value for 'page'
 *      isUpcoming: // value for 'isUpcoming'
 *      streamTypes: // value for 'streamTypes'
 *      trainingTypes: // value for 'trainingTypes'
 *      trainingEquipments: // value for 'trainingEquipments'
 *      trainingMuscle: // value for 'trainingMuscle'
 *   },
 * });
 */
export function useSortedLiveStreamsQuery(baseOptions: Apollo.QueryHookOptions<SortedLiveStreamsQuery, SortedLiveStreamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SortedLiveStreamsQuery, SortedLiveStreamsQueryVariables>(SortedLiveStreamsDocument, options);
      }
export function useSortedLiveStreamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SortedLiveStreamsQuery, SortedLiveStreamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SortedLiveStreamsQuery, SortedLiveStreamsQueryVariables>(SortedLiveStreamsDocument, options);
        }
export type SortedLiveStreamsQueryHookResult = ReturnType<typeof useSortedLiveStreamsQuery>;
export type SortedLiveStreamsLazyQueryHookResult = ReturnType<typeof useSortedLiveStreamsLazyQuery>;
export type SortedLiveStreamsQueryResult = Apollo.QueryResult<SortedLiveStreamsQuery, SortedLiveStreamsQueryVariables>;
export const GetFilterOptionsDocument = gql`
    query GetFilterOptions($first: Int!) {
  trainingTypes(first: $first) {
    edges {
      node {
        id
        name
        __typename
      }
    }
  }
  trainingEquipments(first: $first) {
    edges {
      node {
        id
        name
        __typename
      }
    }
  }
  trainingMuscles(first: $first) {
    edges {
      node {
        id
        name
        __typename
      }
    }
  }
}
    `;

/**
 * __useGetFilterOptionsQuery__
 *
 * To run a query within a React component, call `useGetFilterOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilterOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilterOptionsQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetFilterOptionsQuery(baseOptions: Apollo.QueryHookOptions<GetFilterOptionsQuery, GetFilterOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFilterOptionsQuery, GetFilterOptionsQueryVariables>(GetFilterOptionsDocument, options);
      }
export function useGetFilterOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFilterOptionsQuery, GetFilterOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFilterOptionsQuery, GetFilterOptionsQueryVariables>(GetFilterOptionsDocument, options);
        }
export type GetFilterOptionsQueryHookResult = ReturnType<typeof useGetFilterOptionsQuery>;
export type GetFilterOptionsLazyQueryHookResult = ReturnType<typeof useGetFilterOptionsLazyQuery>;
export type GetFilterOptionsQueryResult = Apollo.QueryResult<GetFilterOptionsQuery, GetFilterOptionsQueryVariables>;
export const SignUpStreamDocument = gql`
    mutation SignUpStream($input: signUpVideoStreamInput!) {
  signUpVideoStream(input: $input) {
    videoStream {
      id
    }
  }
}
    `;
export type SignUpStreamMutationFn = Apollo.MutationFunction<SignUpStreamMutation, SignUpStreamMutationVariables>;

/**
 * __useSignUpStreamMutation__
 *
 * To run a mutation, you first call `useSignUpStreamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpStreamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpStreamMutation, { data, loading, error }] = useSignUpStreamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUpStreamMutation(baseOptions?: Apollo.MutationHookOptions<SignUpStreamMutation, SignUpStreamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignUpStreamMutation, SignUpStreamMutationVariables>(SignUpStreamDocument, options);
      }
export type SignUpStreamMutationHookResult = ReturnType<typeof useSignUpStreamMutation>;
export type SignUpStreamMutationResult = Apollo.MutationResult<SignUpStreamMutation>;
export type SignUpStreamMutationOptions = Apollo.BaseMutationOptions<SignUpStreamMutation, SignUpStreamMutationVariables>;