import { CheckboxProps, CheckboxSize } from "./Checkbox.types";
import css from "./Checkbox.module.scss";
import classNames from "@fitneks-commons/classnames";
import { Icon, IconName } from "@fitneks-component-library";
import { getValidationClass } from "@fitneks-commons/getValidationClass";

/**
 * Checkbox component
 *
 * @param {CheckboxProps} props Component props
 * @param {string} props.label - checkbox label
 * @param {string} props.name - checkbox name
 * @param {boolean} props.checked - checkbox checked state
 * @param {boolean} props.required - checkbox required
 * @param {boolean} props.isValid - checkbox valid state
 * @param {string} props.value - checkbox default value
 * @param {boolean} props.disabled - checkbox disabled
 * @param {string} props.size - checkbox size small | medium | large
 * @param {function} props.onChange -  checkbox value change
 *
 */

const Checkbox = (props: CheckboxProps) => {
    const isValidClass = getValidationClass(props.isValid);
    const checked = props.checked || false;
    const size: CheckboxSize = props.size || "medium";

    function handleChange() {
        if (typeof props.onChange != "undefined") {
            props.onChange(!checked);
        }
    }

    return (
        <div
            ref={props.htmlRef}
            className={classNames(
                css["checkboxWrapper"],
                checked && css["is-checked"],
                css[size],
                isValidClass && css[isValidClass],
                props.className
            )}
        >
            <input
                type="checkbox"
                name={props.name}
                disabled={props.disabled}
                checked={checked}
                className={classNames(css["checkbox"])}
                value={props.value}
                onChange={handleChange}
                aria-label={props.label}
            />

            <span className={classNames(css["checkboxToggle"])}>
                {checked && (
                    <Icon name={IconName.Check} width={18} color="#fff" className={classNames(css["checkboxIcon"])} />
                )}
            </span>
            <label className={classNames(css["checkboxLabel"])}>{props.label}</label>
        </div>
    );
};

export default Checkbox;
