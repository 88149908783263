import { createContext } from "react";
import { ComponentWithChildren } from "@fitneks-commons/types/ComponentWithChildren";
import { CloseOption, NotificationType as NoteType } from "@fitneks-component-library";
import { ReactNode } from "react";

export type ThemeColorType = "light" | "dark";
export type OffCanvasType = "on" | "off";
export type OffCanvasDirectionType = "left" | "right";
export type NotificationType = {
    duration?: number;
    message: ReactNode | string;
    title?: string;
    close?: CloseOption;
    type?: NoteType;
};

export interface ThemeContextProps extends ComponentWithChildren {
    bodyClass: string | null;
    page: string;
    offCanvas: OffCanvasType;
    offCanvasDirection: OffCanvasDirectionType;
    themeColor: ThemeColorType;
    updateBodyClass: (value: string | null) => void;
    updatePage: (value: string) => void;
    updateThemeColor: (value: ThemeColorType) => void;
    updateOffCanvas: (value: OffCanvasType) => void;
    updateOffCanvasDirection: (value: OffCanvasDirectionType) => void;
    addNotification: (value: NotificationType) => void;
    notifications: NotificationType[];
    resetBodyClass: (value: string | null) => void;
}

export const ThemeContext = createContext<ThemeContextProps>({
    bodyClass: null,
    page: "initial",
    offCanvas: "off",
    themeColor: "light",
    offCanvasDirection: "left",
    notifications: [],

    /* c8 ignore next 9 */
    updateBodyClass: (_: string | null) => {
        /* Stub! */
    },
    updatePage: (_: string) => {
        /* Stub! */
    },
    updateThemeColor: (_: ThemeColorType) => {
        /* Stub! */
    },
    updateOffCanvas: (_: OffCanvasType) => {
        /* Stub! */
    },
    updateOffCanvasDirection: (_: OffCanvasDirectionType) => {
        /* Stub! */
    },

    addNotification: (_: NotificationType) => {
        /* Stub! */
    },

    resetBodyClass: (_: string | null) => {
        /* Stub! */
    },
});
