import { ImageMaskProps } from "./ImageMask.types";
import css from "./ImageMask.module.scss";
import classNames from "@fitneks-commons/classnames";

const ImageMask = (props: ImageMaskProps) => {
    const size = props.size || 100;

    return (
        <div
            ref={props.htmlRef}
            role="graphics-document"
            className={classNames(props.className, css["image-mask"])}
            style={{
                maskImage: `url(${props.src})`,
                backgroundImage: `url(${props.src})`,
                WebkitMaskImage: `url(${props.src})`,
                width: `${size}px`,
            }}
        ></div>
    );
};

export default ImageMask;
