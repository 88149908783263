/* eslint-disable */
/* tslint:disable */
/* THIS IS A GENERATED FILE, DO NOT EDIT. */
import * as Types from '@fitneks-types/Graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MuteParticipantMutationVariables = Types.Exact<{
  input: Types.MuteParticipantVideoStreamInput;
}>;


export type MuteParticipantMutation = { readonly muteParticipantVideoStream?: { readonly videoStream?: { readonly id: string } | null } | null };

export type RemoveParticipantMutationVariables = Types.Exact<{
  input: Types.ToggleParticipantVideoStreamInput;
}>;


export type RemoveParticipantMutation = { readonly toggleParticipantVideoStream?: { readonly videoStream?: { readonly id: string } | null } | null };


export const MuteParticipantDocument = gql`
    mutation MuteParticipant($input: muteParticipantVideoStreamInput!) {
  muteParticipantVideoStream(input: $input) {
    videoStream {
      id
    }
  }
}
    `;
export type MuteParticipantMutationFn = Apollo.MutationFunction<MuteParticipantMutation, MuteParticipantMutationVariables>;

/**
 * __useMuteParticipantMutation__
 *
 * To run a mutation, you first call `useMuteParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMuteParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [muteParticipantMutation, { data, loading, error }] = useMuteParticipantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMuteParticipantMutation(baseOptions?: Apollo.MutationHookOptions<MuteParticipantMutation, MuteParticipantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MuteParticipantMutation, MuteParticipantMutationVariables>(MuteParticipantDocument, options);
      }
export type MuteParticipantMutationHookResult = ReturnType<typeof useMuteParticipantMutation>;
export type MuteParticipantMutationResult = Apollo.MutationResult<MuteParticipantMutation>;
export type MuteParticipantMutationOptions = Apollo.BaseMutationOptions<MuteParticipantMutation, MuteParticipantMutationVariables>;
export const RemoveParticipantDocument = gql`
    mutation RemoveParticipant($input: toggleParticipantVideoStreamInput!) {
  toggleParticipantVideoStream(input: $input) {
    videoStream {
      id
    }
  }
}
    `;
export type RemoveParticipantMutationFn = Apollo.MutationFunction<RemoveParticipantMutation, RemoveParticipantMutationVariables>;

/**
 * __useRemoveParticipantMutation__
 *
 * To run a mutation, you first call `useRemoveParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeParticipantMutation, { data, loading, error }] = useRemoveParticipantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveParticipantMutation(baseOptions?: Apollo.MutationHookOptions<RemoveParticipantMutation, RemoveParticipantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveParticipantMutation, RemoveParticipantMutationVariables>(RemoveParticipantDocument, options);
      }
export type RemoveParticipantMutationHookResult = ReturnType<typeof useRemoveParticipantMutation>;
export type RemoveParticipantMutationResult = Apollo.MutationResult<RemoveParticipantMutation>;
export type RemoveParticipantMutationOptions = Apollo.BaseMutationOptions<RemoveParticipantMutation, RemoveParticipantMutationVariables>;