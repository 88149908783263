/**
 * Get validation CSS className
 *
 * @param {boolean} arg - whether to return valid or non-valid class
 * @returns {string} "isValid" | "isInValid" | ""
 *
 */
export function getValidationClass(arg: boolean | undefined) {
    switch (arg) {
        case true:
            return "isValid";
        case false:
            return "isInValid";
        default:
            return "";
    }
}
