import {
    NotificationType,
    OffCanvasDirectionType,
    OffCanvasType,
    ThemeColorType,
    ThemeContext,
} from "./context/ThemeContext";
import { useEffect, useState } from "react";
import { ComponentWithChildren } from "@fitneks-types/ComponentWithChildren";
import css from "./ThemeProvider.module.scss";
import classNames from "@fitneks-commons/classnames";

export const ThemeProvider = (props: ComponentWithChildren) => {
    const [bodyClass, setBodyClass] = useState<string | null>("");
    const [page, setPage] = useState<string>("initial");
    const [themeColor, setThemeColor] = useState<ThemeColorType>("light");
    const [offCanvas, setOffCanvas] = useState<OffCanvasType>("off");
    const [offCanvasDirection, setOffCanvasDirection] = useState<OffCanvasDirectionType>("left");
    const [notifications, setNotifications] = useState<NotificationType[]>([]);

    const updateBodyClass = (value: string | null) => {
        setBodyClass(value);
    };
    const updatePage = (value: string) => {
        setPage(value);
    };

    const updateThemeColor = (value: ThemeColorType) => {
        setThemeColor(value);
    };

    const updateOffCanvas = (value: OffCanvasType) => {
        setOffCanvas(value);
    };

    useEffect(() => {
        if (offCanvas === "on" && !document.body.classList.contains(css["is-overflow-hidden"] as string)) {
            document.body.classList.add(css["is-overflow-hidden"] as string);
        } else {
            if (document.body.classList.contains(css["is-overflow-hidden"] as string)) {
                document.body.classList.remove(css["is-overflow-hidden"] as string);
            }
        }
    }, [offCanvas]);

    const updateOffCanvasDirection = (value: OffCanvasDirectionType) => {
        setOffCanvasDirection(value);
    };

    const addNotification = (value: NotificationType) => {
        setNotifications((prev) => [...prev, value]);
    };

    const resetBodyClass = (value: string | null) => {
        value === bodyClass && setBodyClass("");
    };

    return (
        <ThemeContext.Provider
            value={{
                bodyClass,
                page,
                themeColor,
                offCanvas,
                offCanvasDirection,
                notifications,
                updateBodyClass,
                updatePage,
                updateThemeColor,
                updateOffCanvas,
                updateOffCanvasDirection,
                addNotification,
                resetBodyClass,
            }}
        >
            <div
                className={classNames(css["page-wrapper"], !!bodyClass && css[bodyClass])}
                data-color={themeColor}
                data-offcanvas={offCanvas}
                data-offcanvasdir={offCanvasDirection}
                onClick={() => updateOffCanvas("off")}
            >
                {props.children}
            </div>
        </ThemeContext.Provider>
    );
};
