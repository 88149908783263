import { GraphQLErrors, NetworkError } from "@apollo/client/errors";
import customAlert from "./customAlert";

export default function errorNotification(networkError: NetworkError | undefined, errors: GraphQLErrors | undefined) {
    const FETCH_FAILED = "Failed to fetch";
    const INTERNAL_ERROR = "Internal server error";

    if (networkError?.message === FETCH_FAILED) {
        customAlert("Something wrong with network");
        return;
    }

    errors?.every((error) => {
        if (error.message === INTERNAL_ERROR) {
            customAlert("Something went wrong, please refresh the page and contact us");
            return false;
        }
        error.message.split("\n").forEach((message) => console.error(message));
        return true;
    });
}
