import { SeparatorProps } from "./Separator.types";
import css from "./Separator.module.scss";
import classNames from "@fitneks-commons/classnames";

/**
 * Separator component
 *
 * @param {SeparatorProps} props Component props
 * @param {number} props.height - separator height
 * @param {string} props.color - #0000 separator color
 * @param {string} props.title -  "some text" separator inner text
 *
 */

const Separator = (props: SeparatorProps) => {
    const height = props.height || 1;

    return (
        <div ref={props.htmlRef} className={classNames(css["separator"], props.className)} role="presentation">
            <hr
                className={classNames(css["separator-hr"])}
                style={{ height: `${height}px`, backgroundColor: props.color }}
            />
            {props.title && <span className={classNames(css["separatorText"])}>{props.title}</span>}
        </div>
    );
};

export default Separator;
