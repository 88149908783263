import { IconProps } from "./Icon.types";
import css from "./Icon.module.scss";
import classNames from "@fitneks-commons/classnames";
import { MouseEventHandler } from "react";

/**
 * Icon component
 *
 * @param {IconProps} props Component props
 * @param {string} props.name - icon name
 * @param {number} props.width - icon width
 * @param {number} props.height - icon height = width by default
 * @param {string} props.color - icon color inherit by default
 *
 */

const Icon = (props: IconProps) => {
    const width = props.width || 40;
    const height = props.height ? props.height : width;
    const color = props.color ? props.color : "currentColor";

    const handleClick: MouseEventHandler<SVGSVGElement> = (e) => {
        if (typeof props.onClick === "function") {
            props.onClick(e);
        }
    };

    return (
        <svg
            ref={props.htmlRef}
            className={classNames(css["icon"], props.className)}
            style={{ color: props.color }}
            fill={color}
            width={width}
            height={height}
            role="graphics-document"
            onClick={handleClick}
        >
            <use href={`#${props.name}`} />
        </svg>
    );
};

export default Icon;
