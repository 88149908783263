import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { ComponentWithChildren } from "@fitneks-types/ComponentWithChildren";
import { useAuth } from "@fitneks-commons/hooks";

export function PayPalProvider({ children }: ComponentWithChildren) {
    const { country } = useAuth();

    return (
        <PayPalScriptProvider
            options={{
                "client-id": import.meta.env.FITNEKS_PAYPAL_CLIENT_ID,
                currency: country === "Canada" ? "CAD" : "USD",
            }}
        >
            {children}
        </PayPalScriptProvider>
    );
}
