import { ChangeEvent } from "react";
import { SwitcherProps, SwitcherSize } from "./Switcher.types";
import css from "./Switcher.module.scss";
import classNames from "@fitneks-commons/classnames";

/**
 * Switcher component
 *
 * @param {SwitcherProps} props Component props
 * @param {string} props.name - input name
 * @param {string} props.value1 - 1st input value
 * @param {string} props.label1 - 1st input label
 * @param {string} props.value2 - 2nd input value
 * @param {string} props.label2 - 2nd input label
 * @param {string} props.defaultChecked - default checked value1|value2
 * @param {string} props.onChange - checked input value - return string
 *
 * */

const Switcher = (props: SwitcherProps) => {
    const size: SwitcherSize = props.size || "medium";

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (props.disabled) return;
        props.onChange(event.target.value);
    };

    return (
        <div
            ref={props.htmlRef}
            className={classNames(css["switcher"], css[size], props.disabled && css["isDisabled"], props.className)}
        >
            <div className={classNames(css["switcherItem"])}>
                <input
                    type="radio"
                    name={props.name}
                    value={props.first.value}
                    checked={props.selectedValue === props.first.value}
                    className={classNames(css["switcherInput"])}
                    onChange={handleChange}
                    aria-label={props.first.label}
                />
                <label className={classNames(css["switcherLabel"])}>{props.first.label}</label>
            </div>
            <div className={classNames(css["switcherItem"])}>
                <input
                    type="radio"
                    name={props.name}
                    value={props.second.value}
                    checked={props.selectedValue === props.second.value}
                    className={classNames(css["switcherInput"])}
                    onChange={handleChange}
                    aria-label={props.second.label}
                />
                <label className={classNames(css["switcherLabel"])}>{props.second.label}</label>
            </div>
        </div>
    );
};

export default Switcher;
