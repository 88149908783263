import { CoverLoading, CoverProps } from "./Cover.types";
import css from "./Cover.module.scss";
import classNames from "@fitneks-commons/classnames";

/**
 * Cover component
 *
 * @param {CoverProps} props Component props
 * @param {string} props.src - cover image src
 * @param {string} props.title - cover image alt attr
 * @param {boolean} props.overlay - overlay on cover
 * @param {number} props.minHeight - cover min height
 * @param {CoverLoading} props.loading -  cover image loading eager|lazy
 * @param {string} props.string -  cover image  aspectRatio 1/1 | 9/4 | 16/9
 * @param {function} props.onClick -  cover onClick
 *
 */

const Cover = (props: CoverProps) => {
    //Don't use minHeight with ration!!!
    if (!!props.minHeight && !!props.ratio) {
        throw Error("Specifying the ratio and minHeight together is not supported!");
    }
    //Don't use minHeight with rounded!!!
    if (!!props.minHeight && props.rounded) {
        throw Error("Specifying the rounded and minHeight together is not supported!");
    }

    const minHeight = props.minHeight || "0px";
    const loading: CoverLoading = props.loading || "eager";
    let ratio = props.ratio;

    if (props.rounded) ratio = "1/1";

    const handleClick = () => {
        if (typeof props.onClick != "undefined") {
            props.onClick();
        }
    };

    return (
        <div
            ref={props.htmlRef}
            className={classNames(css["cover"], props.rounded && css["is-rounded"], props.className)}
            style={{ minHeight: `${minHeight}`, aspectRatio: `${ratio}` }}
            onClick={handleClick}
        >
            {props.overlay && <span className={classNames(css["coverOverlay"])}></span>}
            {props.src && (
                <img className={classNames(css["coverImage"])} src={props.src} alt={props.title} loading={loading} />
            )}
            <div className={classNames(css["coverContent"])}>{props.children}</div>
        </div>
    );
};

export default Cover;
