export default function customAlert(message: string) {
    const element = document.getElementById("notifications-wrapper");
    const child = document.getElementById("internal-notification");

    if (element && !element.contains(child)) {
        element.innerHTML += `
        <div id="internal-notification" class="hide">
            <p class="message">${message}</p>
            <a href="#" class="close" tabindex="0" role="button" 
            onclick="document.getElementById('internal-notification').outerHTML=''"></a>
            <button onclick="window.location.reload()" class="refresh-btn">Refresh</button>
            <style>
                #internal-notification {
                      color: #ffffff;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      border-radius: 10px;
                      position: fixed;
                      padding: 15px;
                      background: #C02A1F;
                      z-index: 99999;
                      top: 10px;
                      left: 50%;
                      transform: translate(-50%, 0);
                      transition: opacity 0.5s;
                }
                
                 .close {
                    position: absolute;
                    top: 0;
                    right: 0;
                    display: block;
                    width: 50px;
                    height: 50px;
                    font-size: 0;
                }
                .close:before, 
                .close:after {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 5px;
                    height: 20px;
                    background-color: #F0F0F0;
                    transform: rotate(45deg) translate(-50%, -50%);
                    transform-origin: top left;
                    transition: all 420ms;
                    content: '';
                }
                .close:after {
                    transform: rotate(-45deg) translate(-50%, -50%);
                }
                .close:before, 
                .close:after {
                    width: 2px;
                }
                
                .message {
                   font-size: 1.2rem;
                   margin-right: 30px;
                   line-height: 1.3;
                }
                .refresh-btn {
                  border: none;
                  filter: brightness(90%);
                  background-color: inherit;
                  color: inherit;
                  font-size: 15px;
                  padding: 5px 10px;
                  cursor: pointer;
                  margin: 15px auto 0 auto;
                }
                .hide { visibility: hidden; opacity: 0 }
            </style>
        </div>
    `;
        setTimeout(() => {
            const element = document.getElementById("internal-notification");
            if (element) {
                element.classList.toggle("hide");
            }
        }, 0);
    }
}
