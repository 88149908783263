import { getLocalStorageItem, removeLocalStorageItem } from "@fitneks-commons/helpers/localstorage";
import { useAuth } from "@fitneks-commons/hooks";
import { useLeaveStreamMutation } from "@fitneks-pages/VideoStream/pages/Learner/LearnerViewStream.generated";

export function useOnStreamLeave() {
    const { username } = useAuth();
    const [leaveStream] = useLeaveStreamMutation();

    const onStreamLeave = async (streamId?: string, onlyReset?: boolean) => {
        if (streamId) {
            const prefix = ["cm", "st", "ll", "ss", "qc", "cp", "guest"];
            const guestName = getLocalStorageItem(`guest_${streamId}`) ?? "";
            Object.keys(localStorage).forEach(function (key) {
                prefix.forEach((el) => {
                    if (key === `${el}_${streamId}`) {
                        removeLocalStorageItem(key);
                    }
                });
            });
            if (!onlyReset) {
                const id = `api/video_streams/${streamId}`;
                await leaveStream({
                    variables: {
                        input: {
                            id,
                            participant: username ? username : guestName,
                        },
                    },
                });
            }
        }
    };

    return { onStreamLeave };
}
