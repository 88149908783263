import { CalendarEvent } from "../Calendar.types";

export const getNextMonth = ($month: Date): Date => new Date($month.getFullYear(), $month.getMonth() + 1, 1);

export const getPrevMonth = ($month: Date): Date => new Date($month.getFullYear(), $month.getMonth() - 1, 1);

export const getStartOfWeek = (date: Date): Date => {
    let newDate = new Date(date);
    newDate = newDate ? new Date(+newDate) : new Date();
    newDate.setHours(0, 0, 0, 0);
    newDate.setDate(newDate.getDate() - newDate.getDay());

    return newDate;
};

export const getEndOfWeek = (date: Date): Date => {
    let newDate = new Date(date);
    newDate = getStartOfWeek(newDate);
    newDate.setDate(newDate.getDate() + 6);
    return newDate;
};

export const getStartOfMonth = (date: Date): Date => {
    const newDate = new Date(date);
    newDate.setDate(1);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
};

export function getEndOfMonth(date: Date): Date {
    const newDate = new Date(date);
    const month = newDate.getMonth();
    newDate.setFullYear(newDate.getFullYear(), month + 1, 0);
    newDate.setHours(23, 59, 59, 999);
    return newDate;
}

export const addDays = (date: Date, amount: number): Date => {
    const newDate = new Date(date);
    if (!amount) {
        return newDate;
    }
    newDate.setDate(newDate.getDate() + amount);
    return newDate;
};

export const isSameMonth = (leftDate: Date, rightDate: Date): boolean =>
    leftDate.getFullYear() === rightDate.getFullYear() && leftDate.getMonth() === rightDate.getMonth();

export const getFormattedMonthAndYear = (date: Date): string =>
    date.toLocaleString("en-US", {
        month: "long",
        year: "numeric",
    });

export const getFormattedWeekDay = (date: Date): string => date.toLocaleString("en-US", { weekday: "short" });

export const getFormattedDay = (date: Date): string => date.toLocaleString("en-US", { day: "numeric" });

export const startOfDay = (date: Date): Date => {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
};

export const isSameDay = (dateLeft: Date, dateRight: Date): boolean => {
    const dateLeftStartOfDay = startOfDay(dateLeft);
    const dateRightStartOfDay = startOfDay(dateRight);

    return dateLeftStartOfDay.getTime() === dateRightStartOfDay.getTime();
};

export const isSameWeek = (dateLeftStart: Date, dateRightStart: Date): boolean => {
    const dateLeftStartOfWeek = getStartOfWeek(dateLeftStart);
    const dateRightStartOfWeek = getStartOfWeek(dateRightStart);

    return dateLeftStartOfWeek.getTime() === dateRightStartOfWeek.getTime();
};

export const checkDayEvents = (events: CalendarEvent[], day: Date): CalendarEvent[] | false => {
    let dayEvents: CalendarEvent[] | false = false;
    events.forEach((item) => {
        if (isSameDay(item.date, day)) {
            if (!dayEvents) {
                dayEvents = [];
            }
            dayEvents = [...dayEvents, item];
        }
    });

    return dayEvents;
};
