import { BaseComponentProps } from "@fitneks-component-library";
import { ReactNode } from "react";

export type Position = "left" | "right" | "center" | "unset";

export enum SizeType {
    MatchParent = "MatchParent",
    WrapContent = "WrapContent",
}

export class Size {
    width: number | SizeType | undefined;
    height: number | SizeType | undefined;

    constructor(width: number | SizeType, height: number | SizeType) {
        this.width = width;
        this.height = height;
    }
}

export interface ShimmerProps extends BaseComponentProps<HTMLElement> {
    circle?: boolean;
    borderRadius?: boolean;
    propSize?: Size | number;
    position?: Position;
    visible: boolean;
    children: ReactNode;
}
