import { LoginAlertProps } from "./LoginAlert.types";
import { Button, Container, Modal, Typography } from "@fitneks-component-library";
import AppRoutes from "@fitneks-routes";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const LoginAlert = ({ isOpen, onClose }: LoginAlertProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();

    const getRedirectPath = () => {
        let redirectPath = location.pathname;
        const params = searchParams.toString();
        if (params) {
            redirectPath += "?" + params;
        }
        return redirectPath;
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} title={"Login Alert Modal"} width={400}>
            <Container className={"bg-white p-4 rounded-lg"}>
                <Typography tag={"h2"} align={"center"}>
                    Login to continue.
                </Typography>
                <Button
                    title={"Sign in"}
                    size={"small"}
                    fullWidth
                    className={"mt-3 mb-0"}
                    onClick={() => {
                        navigate(AppRoutes.loginWithRedirect.route(getRedirectPath()));
                        onClose();
                    }}
                />
            </Container>
        </Modal>
    );
};

export default LoginAlert;
