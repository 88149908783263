/* eslint-disable */
/* tslint:disable */
/* THIS IS A GENERATED FILE, DO NOT EDIT. */
import * as Types from '@fitneks-types/Graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUserAvatarQueryVariables = Types.Exact<{
  input: Types.Scalars['ID'];
}>;


export type GetUserAvatarQuery = { readonly user?: { readonly id: string, readonly OAuthAvatar?: string | null, readonly avatarMediaObject?: { readonly id: string, readonly filePath?: string | null } | null } | null };

export type UpdateUserMutationVariables = Types.Exact<{
  input: Types.UpdateUserInput;
}>;


export type UpdateUserMutation = { readonly updateUser?: { readonly user?: { readonly id: string, readonly token?: string | null, readonly refreshToken?: string | null } | null } | null };

export type UploadUserAvatarMutationVariables = Types.Exact<{
  input: Types.UploadMediaObjectInput;
}>;


export type UploadUserAvatarMutation = { readonly uploadMediaObject?: { readonly mediaObject?: { readonly id: string } | null } | null };


export const GetUserAvatarDocument = gql`
    query getUserAvatar($input: ID!) {
  user(id: $input) {
    id
    avatarMediaObject {
      id
      filePath
    }
    OAuthAvatar
  }
}
    `;

/**
 * __useGetUserAvatarQuery__
 *
 * To run a query within a React component, call `useGetUserAvatarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAvatarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAvatarQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUserAvatarQuery(baseOptions: Apollo.QueryHookOptions<GetUserAvatarQuery, GetUserAvatarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserAvatarQuery, GetUserAvatarQueryVariables>(GetUserAvatarDocument, options);
      }
export function useGetUserAvatarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserAvatarQuery, GetUserAvatarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserAvatarQuery, GetUserAvatarQueryVariables>(GetUserAvatarDocument, options);
        }
export type GetUserAvatarQueryHookResult = ReturnType<typeof useGetUserAvatarQuery>;
export type GetUserAvatarLazyQueryHookResult = ReturnType<typeof useGetUserAvatarLazyQuery>;
export type GetUserAvatarQueryResult = Apollo.QueryResult<GetUserAvatarQuery, GetUserAvatarQueryVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($input: updateUserInput!) {
  updateUser(input: $input) {
    user {
      id
      token
      refreshToken
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UploadUserAvatarDocument = gql`
    mutation uploadUserAvatar($input: uploadMediaObjectInput!) {
  uploadMediaObject(input: $input) {
    mediaObject {
      id
    }
  }
}
    `;
export type UploadUserAvatarMutationFn = Apollo.MutationFunction<UploadUserAvatarMutation, UploadUserAvatarMutationVariables>;

/**
 * __useUploadUserAvatarMutation__
 *
 * To run a mutation, you first call `useUploadUserAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadUserAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadUserAvatarMutation, { data, loading, error }] = useUploadUserAvatarMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadUserAvatarMutation(baseOptions?: Apollo.MutationHookOptions<UploadUserAvatarMutation, UploadUserAvatarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadUserAvatarMutation, UploadUserAvatarMutationVariables>(UploadUserAvatarDocument, options);
      }
export type UploadUserAvatarMutationHookResult = ReturnType<typeof useUploadUserAvatarMutation>;
export type UploadUserAvatarMutationResult = Apollo.MutationResult<UploadUserAvatarMutation>;
export type UploadUserAvatarMutationOptions = Apollo.BaseMutationOptions<UploadUserAvatarMutation, UploadUserAvatarMutationVariables>;