import { TypographyProps, TypographyType } from "./Typography.types";
import css from "./Typography.module.scss";
import classNames from "@fitneks-commons/classnames";
import { MouseEventHandler } from "react";

/**
 * Typography component
 *
 * @param {TypographyProps} props Component props
 * @param {TypographyTag} props.tag - typography tag name p | span | h1 | h2 | h3| h4 | h5 | h6
 * @param {boolean} props.bold - bold style
 * @param {boolean} props.italic - italic style
 * @param {boolean} props.uppercase - uppercase style
 * @param {boolean} props.primary - primary color
 * @param {number} props.size -  font size
 * @param {number} props.lineHeight -  font line-height
 * @param {TypographyAlign} props.align -  text align  "center" | "left" | "right"
 * @param {TypographyWeight} props.weight -  font weight  300 | 400 | 500 | 700
 *
 */

function Typography({ tag: Tag = "p", ...props }: TypographyProps) {
    const handleClick: MouseEventHandler<TypographyType> = (event) => {
        if (typeof props.onClick != "undefined") {
            props.onClick(event);
        }
    };
    return (
        <Tag
            className={classNames(
                css["typography"],
                props.bold && css["is-bold"],
                props.italic && css["is-italic"],
                props.uppercase && css["is-uppercase"],
                props.primary && css["is-primary"],
                props.className
            )}
            style={{
                fontSize: `${props.size}rem`,
                lineHeight: `${props.lineHeight}rem`,
                textAlign: props.align,
                color: props.color,
                fontWeight: props.weight,
            }}
            data-id={props.ID}
            onClick={handleClick}
        >
            {props.children}
        </Tag>
    );
}

export default Typography;
