import { TooltipProps } from "./Tooltip.types";
import css from "./Tooltip.module.scss";
import classNames from "@fitneks-commons/classnames";
import { Icon } from "@fitneks-component-library";
import { IconName } from "@fitneks-component-library";
import { useEffect, useState } from "react";

/**
 * Tooltip component
 *
 * @param {TooltipProps} props Component props
 * @param {number} props.width - tooltip content width
 * @param {TooltipPosition} props.position - tooltip content position left | right
 * @param {string} props.text - tooltip content
 * @param {boolean} props.noWrap - tooltip content no wrap
 *
 */
const Tooltip = (props: TooltipProps) => {
    const pos = props.position || "top-right";
    const width = props.width || 200;

    const [isOpen, setIsOpen] = useState(false);

    const openOnClick = (e: { stopPropagation: () => void }) => {
        e.stopPropagation();
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const closeOnClick = () => {
            setIsOpen(false);
        };

        document.body.addEventListener("click", closeOnClick);
        return () => {
            document.body.removeEventListener("click", closeOnClick);
        };
    }, []);

    return (
        <div
            className={classNames(
                props.className,
                css["tooltip"],
                css[`pos-${pos}`],
                props.noWrap && css["is-nowrap"],
                isOpen && css["is-open"]
            )}
        >
            <span className={css["tooltip-icon"]} onClick={openOnClick}>
                <Icon name={IconName.Info} width={20} />
            </span>

            <div className={css["tooltip-content"]} style={{ width: `${width}px` }}>
                {props.text}
            </div>
        </div>
    );
};

export default Tooltip;
