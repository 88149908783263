/**
 * Get single item from localStorage
 *
 * @param {string} key Key of requested item.
 * @param {string} defaultValue Optional default value to return retrieved if item is null.
 *
 * @return {(string | null)} Item, null if not exists.
 */
export const getLocalStorageItem = (key: string, defaultValue: string | null = null): string | null => {
    return localStorage.getItem(key) || defaultValue;
};

/**
 * Set/update item in localStorage.
 *
 * Note: This method accepts only string type values.
 * The caller side must handle possible serialization/deserialization by itself.
 *
 * @param {string} key Key of item.
 * @param {string} value Value of item.
 */
export const setLocalStorageItem = (key: string, value: string): void => {
    localStorage.setItem(key, value);
};

/**
 * Set/update multiple items in localStorage.
 *
 * Note: This method accepts only string type values.
 * The caller side must handle possible serialization/deserialization by itself.
 *
 * @param items Vararg items to be set/updated.
 */
export const setLocalStorageItems = (...items: { key: string; value: string }[]): void => {
    for (const { key, value } of items) {
        setLocalStorageItem(key, value);
    }
};

/**
 * Remove single item from localStorage
 *
 * @param {string} key Key of item to be removed.
 */
export const removeLocalStorageItem = (key: string): void => {
    localStorage.removeItem(key);
};

/**
 * Remove multiple items from localStorage
 *
 * @param {string[]} keys Vararg keys of items to be removed.
 */
export const removeLocalStorageItems = (...keys: string[]): void => {
    for (const key of keys) {
        removeLocalStorageItem(key);
    }
};
