import { Button, Container, Modal, Switcher, Tooltip, Typography } from "@fitneks-component-library";
import { RoleSwitcherModalProps } from "@fitneks-commons/ui";
import UserRoles from "@fitneks-commons/constants/UserRoles";
import { useState } from "react";
import { getDeviceType } from "@fitneks-commons/getDeviceType";

const RoleSwitcherModal = ({ isOpen, setIsOpen, onRoleSwitch, registerLoading }: RoleSwitcherModalProps) => {
    const [roles, setRoles] = useState<string[]>([UserRoles.learner]);

    const isDesktop = getDeviceType("desktop");
    return (
        <Modal
            isOpen={isOpen}
            title={"Role Switcher"}
            width={360}
            shadow
            onClose={() => {
                setIsOpen(false);
            }}
        >
            <Container className={"bg-grey px-5 py-4"}>
                <Typography tag={"h4"} align={"center"} bold className={"mb-3"}>
                    What type of user are you?
                    <Tooltip
                        text={
                            <span className={"t-left"}>
                                Trainer: Pick Trainer if you will be streaming workouts
                                <br />
                                Learner: Pick Learner if you will be joining workouts
                            </span>
                        }
                        position={"bottom-left"}
                        width={isDesktop ? 350 : 260}
                    />
                </Typography>
                <Switcher
                    name={"user_role"}
                    first={{
                        label: "Learner",
                        value: UserRoles.learner,
                    }}
                    second={{
                        label: "Trainer",
                        value: UserRoles.trainer,
                    }}
                    selectedValue={roles[0]}
                    onChange={(value) => setRoles([value])}
                    className={"mb-3"}
                />

                <Button
                    className={"justify-content-center"}
                    onClick={(e) => {
                        e.preventDefault();
                        onRoleSwitch(roles);
                    }}
                    size={"small"}
                    title={registerLoading ? "IN PROGRESS..." : "Continue"}
                    style={"rounded"}
                    disabled={registerLoading}
                    fullWidth
                />
            </Container>
        </Modal>
    );
};

export default RoleSwitcherModal;
