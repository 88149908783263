import { ColProps } from "./Col.types";
import css from "./Col.module.scss";
import classNames from "@fitneks-commons/classnames";

/**
 * Column component
 *
 * @param {ColProps} props Component props
 * @param {number} props.sm - column width
 * @param {number} props.md - column width
 * @param {number} props.lg - column width
 *
 */
const Col = (props: ColProps) => {
    return (
        <div
            ref={props.htmlRef}
            className={classNames(
                css["col"],
                props.xs && css[`col-${props.xs}`],
                props.sm && css[`col-sm-${props.sm}`],
                props.md && css[`col-md-${props.md}`],
                props.lg && css[`col-lg-${props.lg}`],
                props.xl && css[`col-xl-${props.xl}`],
                props.className
            )}
        >
            {props.children}
        </div>
    );
};

export default Col;
