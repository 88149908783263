import { useEffect, useState } from "react";
import { Search } from "@fitneks-component-library";
import AppRoutes from "@fitneks-routes";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { SearchForStreamsProps } from "./SearchForStreams.types";

const SearchForStreams = ({ onSubmit }: SearchForStreamsProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const [search, setSearch] = useState("");
    const liveStreamPage = location.pathname.includes("/streaming") || location.pathname.includes("/trainer/");

    useEffect(() => {
        if (location.pathname.includes(AppRoutes.search.path)) {
            const term = searchParams.get("term");
            term && setSearch(term);
        } else {
            setSearch("");
        }
    }, [location]);

    const onSearchSubmit = () => {
        if (search) {
            liveStreamPage
                ? (window.location.href = `${import.meta.env.FITNEKS_APP_URL}${AppRoutes.searchWithPhrase.route(
                      search
                  )}`)
                : navigate(AppRoutes.searchWithPhrase.route(search));
        }
        typeof onSubmit === "function" && onSubmit(search);
    };

    return (
        <Search
            value={search}
            style={"inside"}
            height={40}
            onChange={(value) => setSearch(value)}
            onSubmit={onSearchSubmit}
        />
    );
};

export default SearchForStreams;
