import { RowProps } from "./Row.types";
import css from "./Row.module.scss";
import classNames from "@fitneks-commons/classnames";
import React from "react";

/**
 * Row component
 *
 * @param {RowProps} props Component props
 * @param {number} props.gapX - row gap x
 * @param {number} props.gapY - row gap y
 *
 */

const Row = (props: RowProps) => {
    return (
        <div
            ref={props.htmlRef}
            className={classNames(css["row"], props.className)}
            style={{ "--fx-gutter-x": `${props.gapX}px`, "--fx-gutter-y": `${props.gapY}px` } as React.CSSProperties}
        >
            {props.children}
        </div>
    );
};

export default Row;
