import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import createCustomFetch from "./helpers/createCustomFetch";
import { AuthContextProps } from "@fitneks-providers";
import { RestLink } from "apollo-link-rest";
import { onError } from "@apollo/client/link/error";
import { isServerError } from "./helpers/typeguards";
import { createUploadLink } from "apollo-upload-client";
import { relayStylePagination } from "@apollo/client/utilities";
import errorNotification from "./helpers/error-notification/errorNotification";

/**
 * Creates Apollo Client for GraphQL requests.
 * It will also configure REST link for making requests to /auth endpoint
 *
 * @param {AuthContextProps} authState Auth state from AuthProvider
 */
export const createApolloClient = (authState: AuthContextProps) => {
    return new ApolloClient({
        cache: new InMemoryCache({
            typePolicies: {
                Query: {
                    fields: {
                        videoStreams: relayStylePagination([
                            "first",
                            "isLive",
                            "isUpcoming",
                            "startDatetime",
                            "user",
                            "videoStreamViewers_user",
                        ]),
                        trainingTypes: relayStylePagination(["first", "users", "videoStreams_guid"]),
                        trainingGoals: relayStylePagination(["first", "users"]),
                        trainingMuscles: relayStylePagination(["first", "users"]),
                        trainingEquipments: relayStylePagination(["first", "videoStreams_guid"]),
                        gamificationItemLogs: relayStylePagination([
                            "first",
                            "user",
                            "datetime",
                            "videoStream_guid",
                            "from",
                        ]),
                        userConnections: relayStylePagination(["first", "user", "followingUser"]),
                        videoStreamViewers: relayStylePagination(["first", "videoStream_guid", "user_displayName"]),
                        searchVideoStreams: {
                            keyArgs: ["search"],
                            merge(existing = {}, incoming) {
                                return {
                                    ...incoming,
                                    edges: [...(existing.edges || []), ...incoming.edges],
                                };
                            },
                        },
                        liveWaitLists: relayStylePagination(["first", "videoStream_guid"]),
                        notifications: relayStylePagination(["first", "user"]),
                        trainingEarnedPoints: relayStylePagination(["first", "user", "videoStream_guid", "createdAt"]),
                        withdraws: relayStylePagination(["first", "user"]),
                    },
                },
            },
        }),
        link: ApolloLink.from([
            onError(({ networkError, graphQLErrors }) => {
                if (isServerError(networkError)) {
                    networkError.message = networkError.result.message;
                }
                errorNotification(networkError, graphQLErrors);
            }),
            new RestLink({ uri: import.meta.env.FITNEKS_API_URL }),
            createUploadLink({
                uri: import.meta.env.FITNEKS_GRAPHQL_URL,
                fetch: createCustomFetch(authState),
            }),
        ]),
    });
};
