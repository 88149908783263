// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import md5 from "md5";
import { useGoogleLogin } from "@react-oauth/google";
import { useLoginMutation } from "@fitneks-pages/Login/Login.generated";
import { useContext, useEffect, useState } from "react";
import { useAuth } from "@fitneks-commons/hooks";
import { FAILED_LOGIN_MESSAGE } from "@fitneks-pages/Login/Login.types";
import { useRegisterMutation } from "@fitneks-pages/Register/Register.generated";
import { currentTimezone } from "@fitneks-commons/helpers/functions/Date";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GoogleAuthCredential, GoogleAuthProps, RoleSwitcherModal } from "@fitneks-commons/ui";
import { Query } from "@fitneks-types/Graphql.generated";
import { Container, Icon, IconName, Typography } from "@fitneks-component-library";
import css from "./GoogleAuth.module.scss";
import classNames from "@fitneks-commons/classnames";
import { ThemeContext } from "@fitneks-providers";
import AppRoutes from "@fitneks-routes";

const GoogleAuth = ({ className }: GoogleAuthProps) => {
    const { onLogin } = useAuth();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { addNotification } = useContext(ThemeContext);
    const [authorize, { client }] = useLoginMutation({
        onCompleted: (data) => {
            handleLogin(data?.loginUser?.user as Query["user"]);
        },
        onError: (e) => {
            if (e.message === FAILED_LOGIN_MESSAGE) {
                setIsOpen(true);
            } else {
                addNotification({
                    message: "Something went wrong, please try again.",
                    type: "error",
                    close: "timeOut",
                    duration: 3000,
                });
            }
        },
    });
    const [register, { loading: registerLoading }] = useRegisterMutation({
        onCompleted: (data) => {
            navigate(AppRoutes.onboardingWithRedirect.route(AppRoutes.index.route()), { replace: true });
            handleLogin(data?.createUser?.user as Query["user"]);
        },
        onError: () => {
            addNotification({
                message: "Something went wrong, please try again.",
                type: "error",
                close: "timeOut",
                duration: 3000,
            });
        },
    });

    const [authCreds, setAuthCreds] = useState<GoogleAuthCredential>();
    const [isOpen, setIsOpen] = useState(false);
    const [roles, setRoles] = useState<string[]>([]);

    useEffect(() => {
        if (roles.length > 0 && authCreds?.email != undefined) {
            const authPassword = md5(`fit${authCreds?.email}neks`);
            (async () => {
                await register({
                    variables: {
                        input: {
                            username: authCreds.email.split("@")[0] || "",
                            email: authCreds.email,
                            firstName: authCreds.given_name,
                            lastName: authCreds.family_name,
                            password: authPassword,
                            displayName: authCreds.name,
                            roles: roles,
                            registerCode: searchParams.get("promotion_code"),
                            timezone: currentTimezone(),
                            isPublic: true,
                            OAuthAvatar: authCreds.picture,
                            warning: false
                        },
                    },
                });
            })();
        }
    }, [roles]);

    const handleGoogleLogin = useGoogleLogin({
        onSuccess: (codeResponse) => {
            fetch(`https://www.googleapis.com/oauth2/v2/userinfo?access_token=${codeResponse.access_token}`, {
                headers: {
                    Authorization: `Bearer ${codeResponse.access_token}`,
                    Accept: "application/json",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    setAuthCreds(data);
                    if (data?.email != undefined) {
                        const authPassword = md5(`fit${data?.email}neks`);
                        (async () => {
                            await authorize({
                                variables: {
                                    input: {
                                        username: data.email,
                                        password: authPassword,
                                        OAuthAvatar: data.picture,
                                    },
                                },
                            });
                        })();
                    }
                });
        },
    });

    function handleLogin(user: Query["user"]) {
        if (user?.token && user?.refreshToken && user?.id && user?.username && user?.roles && user?.timezone) {
            onLogin(
                user.token,
                user.refreshToken,
                user.id,
                user.username,
                user.roles,
                user.timezone,
                user.guid,
                user.country,
                client
            );
        }
    }

    return (
        <>
            <Container className={classNames(css["google-btn"], className)} onClick={() => handleGoogleLogin()}>
                <Icon name={IconName.GoogleIcon} />
                <Typography className={"m-0 ml-2"} bold>
                    Continue with Google
                </Typography>
            </Container>
            <RoleSwitcherModal
                isOpen={isOpen}
                setIsOpen={(e) => setIsOpen(e)}
                onRoleSwitch={(e) => setRoles(e)}
                registerLoading={registerLoading}
            />
        </>
    );
};

export default GoogleAuth;
