import { Container } from "@fitneks-component-library";
import classNames from "@fitneks-commons/classnames";
import css from "./OffCanvas.module.scss";
import { ReactNode } from "react";

interface OffCanvasProps {
    className?: string;
    width?: number;
    direction?: "left" | "right";
    show?: "on" | "off";
    children?: ReactNode;
}

export default function OffCanvas(props: OffCanvasProps) {
    const width = props.width ? props.width : 300;

    return (
        <Container
            tag={"section"}
            width={width}
            className={classNames(
                css["offCanvas"],
                props.show === "on" && css["offCanvas-on"],
                props.direction && css[`offCanvas-${props.direction}`],
                "bg-white",
                props.className
            )}
            onClick={(e) => e.stopPropagation()}
        >
            {props.children}
        </Container>
    );
}
