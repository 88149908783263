import classNames from "@fitneks-commons/classnames";
import { Container, Icon, IconName, Typography } from "@fitneks-component-library";
import css from "./FacebookAuth.module.scss";
import { FacebookButtonProps } from "./FacebookAuth.types";

const FacebookButton = ({ onClick }: FacebookButtonProps) => {
    return (
        <Container className={classNames(css["facebook-btn"])} onClick={onClick}>
            <Icon name={IconName.FacebookIcon} color={"#fff"} />
            <Typography className={"m-0 ml-2"} bold color={"#fff"}>
                Continue with Facebook
            </Typography>
        </Container>
    );
};

export default FacebookButton;
