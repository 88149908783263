/* eslint-disable */
/* tslint:disable */
/* THIS IS A GENERATED FILE, DO NOT EDIT. */
import * as Types from '@fitneks-types/Graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PaymentLogsQueryVariables = Types.Exact<{
  first: Types.Scalars['Int'];
  user: Types.Scalars['String'];
  after?: Types.InputMaybe<Types.Scalars['String']>;
  datetime?: Types.InputMaybe<ReadonlyArray<Types.InputMaybe<Types.GamificationItemLogFilter_Datetime>> | Types.InputMaybe<Types.GamificationItemLogFilter_Datetime>>;
}>;


export type PaymentLogsQuery = { readonly gamificationItemLogs?: { readonly edges?: ReadonlyArray<{ readonly node?: { readonly id: string, readonly type: string, readonly count: number, readonly datetime: string, readonly gamificationItem?: { readonly id: string, readonly name: string, readonly title: string, readonly color: string, readonly iconMediaObject?: { readonly filePath?: string | null } | null } | null, readonly withdraw?: { readonly id: string, readonly completed: boolean } | null } | null } | null> | null, readonly pageInfo: { readonly endCursor?: string | null, readonly hasNextPage: boolean } } | null };


export const PaymentLogsDocument = gql`
    query PaymentLogs($first: Int!, $user: String!, $after: String, $datetime: [GamificationItemLogFilter_datetime]) {
  gamificationItemLogs(
    first: $first
    user: $user
    after: $after
    datetime: $datetime
    gamificationItem_name_list: ["ruby", "withdraw"]
  ) {
    edges {
      node {
        id
        type
        gamificationItem {
          id
          name
          title
          color
          iconMediaObject {
            filePath
          }
        }
        withdraw {
          id
          completed
        }
        count
        datetime
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;

/**
 * __usePaymentLogsQuery__
 *
 * To run a query within a React component, call `usePaymentLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentLogsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      user: // value for 'user'
 *      after: // value for 'after'
 *      datetime: // value for 'datetime'
 *   },
 * });
 */
export function usePaymentLogsQuery(baseOptions: Apollo.QueryHookOptions<PaymentLogsQuery, PaymentLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentLogsQuery, PaymentLogsQueryVariables>(PaymentLogsDocument, options);
      }
export function usePaymentLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentLogsQuery, PaymentLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentLogsQuery, PaymentLogsQueryVariables>(PaymentLogsDocument, options);
        }
export type PaymentLogsQueryHookResult = ReturnType<typeof usePaymentLogsQuery>;
export type PaymentLogsLazyQueryHookResult = ReturnType<typeof usePaymentLogsLazyQuery>;
export type PaymentLogsQueryResult = Apollo.QueryResult<PaymentLogsQuery, PaymentLogsQueryVariables>;