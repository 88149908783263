/* eslint-disable */
/* tslint:disable */
/* THIS IS A GENERATED FILE, DO NOT EDIT. */
import * as Types from '@fitneks-types/Graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LiveWaitListQueryVariables = Types.Exact<{
  first: Types.Scalars['Int'];
  after?: Types.InputMaybe<Types.Scalars['String']>;
  videoStream: Types.Scalars['String'];
}>;


export type LiveWaitListQuery = { readonly liveWaitLists?: { readonly edges?: ReadonlyArray<{ readonly node?: { readonly id: string, readonly user?: { readonly id: string, readonly username: string, readonly displayName: string, readonly OAuthAvatar?: string | null, readonly avatarMediaObject?: { readonly id: string, readonly filePath?: string | null } | null } | null, readonly gamificationItem?: { readonly id: string, readonly name: string, readonly iconMediaObject?: { readonly filePath?: string | null } | null } | null } | null } | null> | null, readonly pageInfo: { readonly endCursor?: string | null, readonly hasNextPage: boolean } } | null };

export type RemoveFromWaitListMutationVariables = Types.Exact<{
  input: Types.DeleteLiveWaitListInput;
}>;


export type RemoveFromWaitListMutation = { readonly deleteLiveWaitList?: { readonly liveWaitList?: { readonly id: string } | null } | null };

export type AddParticipantMutationVariables = Types.Exact<{
  input: Types.ToggleParticipantVideoStreamInput;
}>;


export type AddParticipantMutation = { readonly toggleParticipantVideoStream?: { readonly videoStream?: { readonly id: string } | null } | null };


export const LiveWaitListDocument = gql`
    query LiveWaitList($first: Int!, $after: String, $videoStream: String!) {
  liveWaitLists(first: $first, after: $after, videoStream_guid: $videoStream) {
    edges {
      node {
        id
        user {
          id
          username
          displayName
          avatarMediaObject {
            id
            filePath
          }
          OAuthAvatar
        }
        gamificationItem {
          id
          name
          iconMediaObject {
            filePath
          }
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;

/**
 * __useLiveWaitListQuery__
 *
 * To run a query within a React component, call `useLiveWaitListQuery` and pass it any options that fit your needs.
 * When your component renders, `useLiveWaitListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiveWaitListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      videoStream: // value for 'videoStream'
 *   },
 * });
 */
export function useLiveWaitListQuery(baseOptions: Apollo.QueryHookOptions<LiveWaitListQuery, LiveWaitListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LiveWaitListQuery, LiveWaitListQueryVariables>(LiveWaitListDocument, options);
      }
export function useLiveWaitListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LiveWaitListQuery, LiveWaitListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LiveWaitListQuery, LiveWaitListQueryVariables>(LiveWaitListDocument, options);
        }
export type LiveWaitListQueryHookResult = ReturnType<typeof useLiveWaitListQuery>;
export type LiveWaitListLazyQueryHookResult = ReturnType<typeof useLiveWaitListLazyQuery>;
export type LiveWaitListQueryResult = Apollo.QueryResult<LiveWaitListQuery, LiveWaitListQueryVariables>;
export const RemoveFromWaitListDocument = gql`
    mutation RemoveFromWaitList($input: deleteLiveWaitListInput!) {
  deleteLiveWaitList(input: $input) {
    liveWaitList {
      id
    }
  }
}
    `;
export type RemoveFromWaitListMutationFn = Apollo.MutationFunction<RemoveFromWaitListMutation, RemoveFromWaitListMutationVariables>;

/**
 * __useRemoveFromWaitListMutation__
 *
 * To run a mutation, you first call `useRemoveFromWaitListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFromWaitListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFromWaitListMutation, { data, loading, error }] = useRemoveFromWaitListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveFromWaitListMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFromWaitListMutation, RemoveFromWaitListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveFromWaitListMutation, RemoveFromWaitListMutationVariables>(RemoveFromWaitListDocument, options);
      }
export type RemoveFromWaitListMutationHookResult = ReturnType<typeof useRemoveFromWaitListMutation>;
export type RemoveFromWaitListMutationResult = Apollo.MutationResult<RemoveFromWaitListMutation>;
export type RemoveFromWaitListMutationOptions = Apollo.BaseMutationOptions<RemoveFromWaitListMutation, RemoveFromWaitListMutationVariables>;
export const AddParticipantDocument = gql`
    mutation AddParticipant($input: toggleParticipantVideoStreamInput!) {
  toggleParticipantVideoStream(input: $input) {
    videoStream {
      id
    }
  }
}
    `;
export type AddParticipantMutationFn = Apollo.MutationFunction<AddParticipantMutation, AddParticipantMutationVariables>;

/**
 * __useAddParticipantMutation__
 *
 * To run a mutation, you first call `useAddParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addParticipantMutation, { data, loading, error }] = useAddParticipantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddParticipantMutation(baseOptions?: Apollo.MutationHookOptions<AddParticipantMutation, AddParticipantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddParticipantMutation, AddParticipantMutationVariables>(AddParticipantDocument, options);
      }
export type AddParticipantMutationHookResult = ReturnType<typeof useAddParticipantMutation>;
export type AddParticipantMutationResult = Apollo.MutationResult<AddParticipantMutation>;
export type AddParticipantMutationOptions = Apollo.BaseMutationOptions<AddParticipantMutation, AddParticipantMutationVariables>;