/* eslint-disable */
/* tslint:disable */
/* THIS IS A GENERATED FILE, DO NOT EDIT. */
import * as Types from '@fitneks-types/Graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GiftListQueryVariables = Types.Exact<{
  input: Types.Scalars['String'];
}>;


export type GiftListQuery = { readonly gamificationItemLogCounts?: ReadonlyArray<{ readonly id: string, readonly count: number, readonly gamificationItem?: { readonly id: string, readonly name: string, readonly title: string, readonly description: string, readonly color: string, readonly enabled: boolean, readonly withdrawPrice: number, readonly isToken: boolean, readonly isProtein: boolean, readonly isRuby: boolean, readonly iconMediaObject?: { readonly filePath?: string | null } | null } | null } | null> | null };

export type GiftRubyPriceQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<ReadonlyArray<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
}>;


export type GiftRubyPriceQuery = { readonly gamificationItemCosts?: ReadonlyArray<{ readonly id: string, readonly cost: string, readonly count: number, readonly gamificationItem?: { readonly id: string, readonly name: string, readonly title: string } | null } | null> | null };

export type BuyGiftMutationVariables = Types.Exact<{
  input: Types.CreateGamificationItemLogInput;
}>;


export type BuyGiftMutation = { readonly createGamificationItemLog?: { readonly gamificationItemLog?: { readonly id: string, readonly type: string, readonly count: number, readonly datetime: string, readonly gamificationItem?: { readonly id: string, readonly name: string, readonly title: string, readonly color: string, readonly iconMediaObject?: { readonly filePath?: string | null } | null } | null } | null } | null };

export type SendGiftMutationVariables = Types.Exact<{
  input: Types.SendGiftVideoStreamInput;
}>;


export type SendGiftMutation = { readonly sendGiftVideoStream?: { readonly videoStream?: { readonly id: string } | null } | null };


export const GiftListDocument = gql`
    query GiftList($input: String!) {
  gamificationItemLogCounts(user: $input) {
    id
    gamificationItem {
      id
      name
      title
      description
      iconMediaObject {
        filePath
      }
      color
      enabled
      withdrawPrice
      isToken
      isProtein
      isRuby
    }
    count
  }
}
    `;

/**
 * __useGiftListQuery__
 *
 * To run a query within a React component, call `useGiftListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGiftListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGiftListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGiftListQuery(baseOptions: Apollo.QueryHookOptions<GiftListQuery, GiftListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GiftListQuery, GiftListQueryVariables>(GiftListDocument, options);
      }
export function useGiftListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GiftListQuery, GiftListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GiftListQuery, GiftListQueryVariables>(GiftListDocument, options);
        }
export type GiftListQueryHookResult = ReturnType<typeof useGiftListQuery>;
export type GiftListLazyQueryHookResult = ReturnType<typeof useGiftListLazyQuery>;
export type GiftListQueryResult = Apollo.QueryResult<GiftListQuery, GiftListQueryVariables>;
export const GiftRubyPriceDocument = gql`
    query GiftRubyPrice($input: [String]) {
  gamificationItemCosts(
    gamificationItem_name_list: $input
    byGamificationItem_name: "ruby"
  ) {
    id
    cost
    count
    gamificationItem {
      id
      name
      title
    }
  }
}
    `;

/**
 * __useGiftRubyPriceQuery__
 *
 * To run a query within a React component, call `useGiftRubyPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGiftRubyPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGiftRubyPriceQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGiftRubyPriceQuery(baseOptions?: Apollo.QueryHookOptions<GiftRubyPriceQuery, GiftRubyPriceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GiftRubyPriceQuery, GiftRubyPriceQueryVariables>(GiftRubyPriceDocument, options);
      }
export function useGiftRubyPriceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GiftRubyPriceQuery, GiftRubyPriceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GiftRubyPriceQuery, GiftRubyPriceQueryVariables>(GiftRubyPriceDocument, options);
        }
export type GiftRubyPriceQueryHookResult = ReturnType<typeof useGiftRubyPriceQuery>;
export type GiftRubyPriceLazyQueryHookResult = ReturnType<typeof useGiftRubyPriceLazyQuery>;
export type GiftRubyPriceQueryResult = Apollo.QueryResult<GiftRubyPriceQuery, GiftRubyPriceQueryVariables>;
export const BuyGiftDocument = gql`
    mutation BuyGift($input: createGamificationItemLogInput!) {
  createGamificationItemLog(input: $input) {
    gamificationItemLog {
      id
      type
      gamificationItem {
        id
        name
        title
        color
        iconMediaObject {
          filePath
        }
      }
      count
      datetime
    }
  }
}
    `;
export type BuyGiftMutationFn = Apollo.MutationFunction<BuyGiftMutation, BuyGiftMutationVariables>;

/**
 * __useBuyGiftMutation__
 *
 * To run a mutation, you first call `useBuyGiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuyGiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buyGiftMutation, { data, loading, error }] = useBuyGiftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuyGiftMutation(baseOptions?: Apollo.MutationHookOptions<BuyGiftMutation, BuyGiftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BuyGiftMutation, BuyGiftMutationVariables>(BuyGiftDocument, options);
      }
export type BuyGiftMutationHookResult = ReturnType<typeof useBuyGiftMutation>;
export type BuyGiftMutationResult = Apollo.MutationResult<BuyGiftMutation>;
export type BuyGiftMutationOptions = Apollo.BaseMutationOptions<BuyGiftMutation, BuyGiftMutationVariables>;
export const SendGiftDocument = gql`
    mutation SendGift($input: sendGiftVideoStreamInput!) {
  sendGiftVideoStream(input: $input) {
    videoStream {
      id
    }
  }
}
    `;
export type SendGiftMutationFn = Apollo.MutationFunction<SendGiftMutation, SendGiftMutationVariables>;

/**
 * __useSendGiftMutation__
 *
 * To run a mutation, you first call `useSendGiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendGiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendGiftMutation, { data, loading, error }] = useSendGiftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendGiftMutation(baseOptions?: Apollo.MutationHookOptions<SendGiftMutation, SendGiftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendGiftMutation, SendGiftMutationVariables>(SendGiftDocument, options);
      }
export type SendGiftMutationHookResult = ReturnType<typeof useSendGiftMutation>;
export type SendGiftMutationResult = Apollo.MutationResult<SendGiftMutation>;
export type SendGiftMutationOptions = Apollo.BaseMutationOptions<SendGiftMutation, SendGiftMutationVariables>;