/* eslint-disable */
/* tslint:disable */
/* THIS IS A GENERATED FILE, DO NOT EDIT. */
import * as Types from '@fitneks-types/Graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetWarningAccountQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetWarningAccountQuery = { readonly user?: { readonly warning: boolean } | null };


export const GetWarningAccountDocument = gql`
    query getWarningAccount($id: ID!) {
  user(id: $id) {
    warning
  }
}
    `;

/**
 * __useGetWarningAccountQuery__
 *
 * To run a query within a React component, call `useGetWarningAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWarningAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWarningAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWarningAccountQuery(baseOptions: Apollo.QueryHookOptions<GetWarningAccountQuery, GetWarningAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWarningAccountQuery, GetWarningAccountQueryVariables>(GetWarningAccountDocument, options);
      }
export function useGetWarningAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWarningAccountQuery, GetWarningAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWarningAccountQuery, GetWarningAccountQueryVariables>(GetWarningAccountDocument, options);
        }
export type GetWarningAccountQueryHookResult = ReturnType<typeof useGetWarningAccountQuery>;
export type GetWarningAccountLazyQueryHookResult = ReturnType<typeof useGetWarningAccountLazyQuery>;
export type GetWarningAccountQueryResult = Apollo.QueryResult<GetWarningAccountQuery, GetWarningAccountQueryVariables>;