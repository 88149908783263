import { ContainerProps } from "./Container.types";
import css from "./Container.module.scss";
import classNames from "@fitneks-commons/classnames";
import { MouseEventHandler } from "react";

/**
 * Container component
 *
 * @param {ContainerProps} props Component props
 * @param {ElementType} props.tag - container tagname
 * @param {number} props.width - container width
 * @param {string} props.bg - container background color
 * @param {boolean} props.gradient -  primary gradient
 * @param {number} props.border - container border width
 * @param {boolean} props.shadow - container shadow
 * @param {boolean} props.radius - container border radius  10px
 * @param {number} props.innerWidth - container inner components width
 *
 */

function Container({ tag: Tag = "div", ...props }: ContainerProps) {
    const border = props.border || 0;

    const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
        if (typeof props.onClick != "undefined") {
            props.onClick(event);
        }
    };

    return (
        <Tag
            className={classNames(
                css["container"],
                props.gradient && css["has-gradient"],
                border > 0 && css["has-border"],
                props.shadow && css["has-shadow"],
                props.radius && css["has-radius"],
                props.className
            )}
            ref={props.htmlRef}
            style={{
                width: `${props.width}px`,
                background: props.bg,
                color: props.color,
                borderWidth: `${props.border}px`,
            }}
            data-id={props.ID}
            onClick={handleClick}
        >
            {props.innerWidth && (
                <div className={classNames(css["container-inner"])} style={{ width: `${props.innerWidth}px` }}>
                    {props.children}
                </div>
            )}
            {!props.innerWidth && props.children}
        </Tag>
    );
}

export default Container;
