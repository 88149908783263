import { ComponentWithChildren } from "@fitneks-commons/types/ComponentWithChildren";
import { useLocalStorage } from "@fitneks-commons/hooks";
import { AuthContext, AuthEditableData, AuthEditableKey } from "./context/AuthContext";
import UserRoles from "@fitneks-commons/constants/UserRoles";
import { ApolloClient } from "@apollo/client";
import { useState } from "react";
import { clearStreamData } from "@fitneks-commons/helpers/functions";
import { setLocalStorageItem } from "@fitneks-commons/helpers/localstorage";

export const AuthProvider = ({ children }: ComponentWithChildren) => {
    const [accessToken, setAccessToken] = useLocalStorage("access_token");
    const [refreshToken, setRefreshToken] = useLocalStorage("refresh_token");
    const [userID, setUserID] = useLocalStorage("user_id");
    const [userGUID, setUserGUID] = useLocalStorage("user_guid");
    const [username, setUsername] = useLocalStorage("username");
    const [role, setRole] = useLocalStorage("role");
    const [client, setClient] = useState<ApolloClient<object>>();
    const [timezone, setTimezone] = useLocalStorage("time_zone");
    const [country, setCountry] = useLocalStorage("country");

    const onLogin = (
        accessToken: string,
        refreshToken: string,
        userID?: string,
        username?: string,
        roles?: string[],
        timezone?: string,
        userGUID?: string,
        country?: string | null,
        client?: ApolloClient<object>
    ) => {
        setAccessToken(accessToken);
        setRefreshToken(refreshToken);
        if (userID) {
            setUserID(userID);
        }
        if (username) {
            setUsername(username);
        }
        if (roles) {
            if (roles.includes(UserRoles.learner)) {
                setRole(UserRoles.learner);
            }
            if (roles.includes(UserRoles.trainer)) {
                setRole(UserRoles.trainer);
            }
        }
        if (timezone) {
            setTimezone(timezone);
        }
        if (client) {
            setClient(client);
        }
        if (userGUID) {
            setUserGUID(userGUID);
        }
        if (country) {
            setCountry(country);
        }
    };

    const onLogout = () => {
        setAccessToken(null);
        setRefreshToken(null);
        setUserID(null);
        setUsername(null);
        setRole(null);
        setTimezone(null);
        setUserGUID(null);
        setCountry(null);
        clearStreamData();
        client?.resetStore();
    };

    const updateEditableData = (data: AuthEditableData[]) => {
        data.forEach(({ key, value }) => {
            if (key === AuthEditableKey.USERNAME) {
                setLocalStorageItem("username", value);
                setUsername(value);
                return;
            }
            if (key === AuthEditableKey.TIMEZONE) {
                setLocalStorageItem("time_zone", value);
                setTimezone(value);
                return;
            }
            if (key === AuthEditableKey.COUNTRY) {
                setLocalStorageItem("country", value);
                setCountry(value);
                return;
            }
        });
    };

    return (
        <AuthContext.Provider
            value={{
                isAuthorized: accessToken !== null && userID !== null && username !== null,
                accessToken,
                refreshToken,
                userID,
                username,
                role,
                timezone,
                userGUID,
                country,
                onLogin,
                onLogout,
                updateEditableData,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
