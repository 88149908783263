/* eslint-disable */
/* tslint:disable */
/* THIS IS A GENERATED FILE, DO NOT EDIT. */
import * as Types from '@fitneks-types/Graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetRubyPurchaseModalItemsQueryVariables = Types.Exact<{
  input: Types.Scalars['String'];
}>;


export type GetRubyPurchaseModalItemsQuery = { readonly gamificationItemLogCounts?: ReadonlyArray<{ readonly count: number, readonly gamificationItem?: { readonly id: string, readonly name: string, readonly title: string, readonly description: string, readonly enabled: boolean, readonly isRuby: boolean, readonly iconMediaObject?: { readonly filePath?: string | null } | null } | null } | null> | null };

export type GetRubyExchangeRatesQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<ReadonlyArray<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
}>;


export type GetRubyExchangeRatesQuery = { readonly gamificationItemCosts?: ReadonlyArray<{ readonly id: string, readonly count: number, readonly cost: string } | null> | null };

export type PurchaseRubyMutationVariables = Types.Exact<{
  input: Types.CreatePurchaseRubyOrderInput;
}>;


export type PurchaseRubyMutation = { readonly createPurchaseRubyOrder?: { readonly order?: { readonly gamificationItemLogs?: { readonly edges?: ReadonlyArray<{ readonly node?: { readonly id: string, readonly type: string, readonly count: number, readonly datetime: string, readonly gamificationItem?: { readonly id: string, readonly name: string, readonly title: string, readonly color: string, readonly isRuby: boolean, readonly iconMediaObject?: { readonly filePath?: string | null } | null } | null } | null } | null> | null } | null } | null } | null };


export const GetRubyPurchaseModalItemsDocument = gql`
    query getRubyPurchaseModalItems($input: String!) {
  gamificationItemLogCounts(user: $input) {
    gamificationItem {
      id
      name
      title
      description
      iconMediaObject {
        filePath
      }
      enabled
      isRuby
    }
    count
  }
}
    `;

/**
 * __useGetRubyPurchaseModalItemsQuery__
 *
 * To run a query within a React component, call `useGetRubyPurchaseModalItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRubyPurchaseModalItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRubyPurchaseModalItemsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRubyPurchaseModalItemsQuery(baseOptions: Apollo.QueryHookOptions<GetRubyPurchaseModalItemsQuery, GetRubyPurchaseModalItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRubyPurchaseModalItemsQuery, GetRubyPurchaseModalItemsQueryVariables>(GetRubyPurchaseModalItemsDocument, options);
      }
export function useGetRubyPurchaseModalItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRubyPurchaseModalItemsQuery, GetRubyPurchaseModalItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRubyPurchaseModalItemsQuery, GetRubyPurchaseModalItemsQueryVariables>(GetRubyPurchaseModalItemsDocument, options);
        }
export type GetRubyPurchaseModalItemsQueryHookResult = ReturnType<typeof useGetRubyPurchaseModalItemsQuery>;
export type GetRubyPurchaseModalItemsLazyQueryHookResult = ReturnType<typeof useGetRubyPurchaseModalItemsLazyQuery>;
export type GetRubyPurchaseModalItemsQueryResult = Apollo.QueryResult<GetRubyPurchaseModalItemsQuery, GetRubyPurchaseModalItemsQueryVariables>;
export const GetRubyExchangeRatesDocument = gql`
    query getRubyExchangeRates($input: [String]) {
  gamificationItemCosts(gamificationItem_name_list: $input) {
    id
    count
    cost
  }
}
    `;

/**
 * __useGetRubyExchangeRatesQuery__
 *
 * To run a query within a React component, call `useGetRubyExchangeRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRubyExchangeRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRubyExchangeRatesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRubyExchangeRatesQuery(baseOptions?: Apollo.QueryHookOptions<GetRubyExchangeRatesQuery, GetRubyExchangeRatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRubyExchangeRatesQuery, GetRubyExchangeRatesQueryVariables>(GetRubyExchangeRatesDocument, options);
      }
export function useGetRubyExchangeRatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRubyExchangeRatesQuery, GetRubyExchangeRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRubyExchangeRatesQuery, GetRubyExchangeRatesQueryVariables>(GetRubyExchangeRatesDocument, options);
        }
export type GetRubyExchangeRatesQueryHookResult = ReturnType<typeof useGetRubyExchangeRatesQuery>;
export type GetRubyExchangeRatesLazyQueryHookResult = ReturnType<typeof useGetRubyExchangeRatesLazyQuery>;
export type GetRubyExchangeRatesQueryResult = Apollo.QueryResult<GetRubyExchangeRatesQuery, GetRubyExchangeRatesQueryVariables>;
export const PurchaseRubyDocument = gql`
    mutation purchaseRuby($input: createPurchaseRubyOrderInput!) {
  createPurchaseRubyOrder(input: $input) {
    order {
      gamificationItemLogs {
        edges {
          node {
            id
            type
            gamificationItem {
              id
              name
              title
              color
              iconMediaObject {
                filePath
              }
              isRuby
            }
            count
            datetime
          }
        }
      }
    }
  }
}
    `;
export type PurchaseRubyMutationFn = Apollo.MutationFunction<PurchaseRubyMutation, PurchaseRubyMutationVariables>;

/**
 * __usePurchaseRubyMutation__
 *
 * To run a mutation, you first call `usePurchaseRubyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePurchaseRubyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [purchaseRubyMutation, { data, loading, error }] = usePurchaseRubyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePurchaseRubyMutation(baseOptions?: Apollo.MutationHookOptions<PurchaseRubyMutation, PurchaseRubyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PurchaseRubyMutation, PurchaseRubyMutationVariables>(PurchaseRubyDocument, options);
      }
export type PurchaseRubyMutationHookResult = ReturnType<typeof usePurchaseRubyMutation>;
export type PurchaseRubyMutationResult = Apollo.MutationResult<PurchaseRubyMutation>;
export type PurchaseRubyMutationOptions = Apollo.BaseMutationOptions<PurchaseRubyMutation, PurchaseRubyMutationVariables>;