import {isRouteErrorResponse, useNavigate, useRouteError} from "react-router-dom";

function ErrorBoundary() {
    const navigate = useNavigate();
    const error = useRouteError();
    const message = isRouteErrorResponse(error)
        ? error.status + " " + error.statusText
        : error instanceof Error ? error.message : JSON.stringify(error);

    if (error) {
        console.error(error);
    }

    return (
        <div
            role="alert"
            style={{
                width: "100vw",
                height: "100vh",
                background: "#9D9D9D",
                padding: "24px",
            }}
        >
            <div
                style={{
                    background: "#F2E6E7",
                    border: "4px solid #E64D48",
                    borderRadius: "10px",
                    padding: "24px",
                    color: "#E60B00",
                    width: "100%",
                    letterSpacing: "0.02rem",
                    boxShadow: "0px 6px 12px #00000029",
                }}
            >
                <p style={{ fontWeight: "bold", fontSize: "1.5rem" }}>OOPS! Something went wrong...</p>
                <pre style={{ margin: "36px 0" }}>{message}</pre>
                <button
                    style={{
                        background: "#E64D48",
                        padding: "12px 16px",
                        color: "#FFFFFF",
                        border: "none",
                        borderRadius: "10px",
                        cursor: "pointer",
                        fontSize: "0.8rem",
                    }}
                    role="button"
                    onClick={() => navigate(0)}
                >
                    TRY AGAIN
                </button>
            </div>
        </div>
    );
}

export default ErrorBoundary;
