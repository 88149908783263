import { Suspense } from "react";
import { RouterProvider } from "react-router-dom";
import { PageLoading } from "@fitneks-commons/ui";
import { router } from "./router";

export default function AppRouter() {
    return (
        <Suspense fallback={/*TODO: Replace with normal fallback*/ <PageLoading />}>
            <RouterProvider router={router} />
        </Suspense>
    );
}
