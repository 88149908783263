import { Container, Icon, IconName, Modal, Typography, Button } from "@fitneks-component-library";
import { useEffect, useRef, useState } from "react";
import { useAuth, useHandleClickOutside, usePubNub } from "@fitneks-commons/hooks";
import { isLearner, isTrainer } from "@fitneks-commons/helpers/functions/UserRoles";
import {
    useGetNotificationsQuery,
    useGetUserConnectionsLazyQuery,
    useRemoveNotificationMutation,
    NotificationIconProps,
    NotificationType,
} from "@fitneks-commons/ui";
import PubNub, { MessageEvent } from "pubnub";
import perPage from "@fitneks-commons/constants/PerPage";
import css from "./NotificationIcon.module.scss";
import classNames from "@fitneks-commons/classnames";
import { getDeviceType } from "@fitneks-commons/getDeviceType";
import { PubNubChannels } from "@fitneks-commons/constants/PubNubChannels";
import UserRoles from "@fitneks-commons/constants/UserRoles";
import { NotificationMessage } from "@fitneks-types/NotificationMessage";
import { isJson } from "@fitneks-commons/helpers/functions";
import { useNavigate } from "react-router-dom";
import { defaultUTCToTimezone } from "@fitneks-commons/helpers/functions/Date";

const NotificationIcon = ({ className }: NotificationIconProps) => {
    const PER_PAGE = perPage.max;
    const navigate = useNavigate();
    const { userID, role, userGUID, timezone } = useAuth();
    const [cursor, setCursor] = useState("");
    const [hasNextPage, setHasNextPage] = useState(false);
    const pubnub = useRef<PubNub>();
    const [notifications, setNotifications] = useState<NotificationType[]>([]);
    const [showNotifications, setShowNotifications] = useState(false);
    const [unReedCount, setUnReedCount] = useState<number>();
    const [notificationsCursor, setNotificationsCursor] = useState("");
    const [notificationsHasNextPage, setNotificationsHasNextPage] = useState(false);
    const { init, reset } = usePubNub();

    const {
        data: notificationsData,
        loading: notificationsLoading,
        refetch: refetchNotifications,
        fetchMore: fetchMoreNotifications,
    } = useGetNotificationsQuery({
        variables: {
            user: userID ?? "",
            first: PER_PAGE,
        },
    });
    const [getConnectionsInfo, { data, loading, fetchMore }] = useGetUserConnectionsLazyQuery();
    const [removeNotification] = useRemoveNotificationMutation();

    useHandleClickOutside(() => {
        setShowNotifications(false);
    });

    useEffect(() => {
        if (userGUID) {
            pubnub.current = new PubNub({
                publishKey: import.meta.env.FITNEKS_PUBNUB_PUBLISH_KEY,
                subscribeKey: import.meta.env.FITNEKS_PUBNUB_SUBSCRIBE_KEY,
                uuid: userGUID,
            });
            init(pubnub.current);

            pubnub.current.addListener({ message: handleMessage });

            (async () => {
                if (isLearner(role) && userID) {
                    await getConnectionsInfo({
                        variables: {
                            user: userID,
                            first: PER_PAGE,
                        },
                    });
                }
                if (isTrainer(role)) {
                    pubnub.current?.subscribe({
                        channels: [`${PubNubChannels.TO_TRAINER_CHANNEL}${userGUID}`],
                    });
                }
            })();
        }

        return () => {
            pubnub.current?.removeListener({ message: handleMessage });
            pubnub.current?.unsubscribeAll();
            reset();
        };
    }, []);

    useEffect(() => {
        if (!loading && data) {
            const pageInfo = data?.userConnections?.pageInfo;
            setCursor(pageInfo?.endCursor ?? "");
            setHasNextPage(pageInfo?.hasNextPage ?? false);
            if (!pageInfo?.hasNextPage) {
                const channels: string[] = [`${PubNubChannels.TO_LEARNER_CHANNEL}${userGUID}`];
                data?.userConnections?.edges?.forEach((el) => {
                    const user = el?.node?.followingUser;
                    if (user?.roles?.includes(UserRoles.trainer)) {
                        channels.push(`${PubNubChannels.FROM_TRAINER_TO_LEARNER_CHANNEL}${user?.guid}`);
                    }
                    if (user?.roles?.includes(UserRoles.learner) && el?.node?.mutualConnection !== null) {
                        channels.push(`${PubNubChannels.FROM_LEARNER_TO_LEARNER_CHANNEL}${user?.guid}`);
                    }
                });

                pubnub.current?.subscribe({ channels });
            }
        }
    }, [data, loading]);

    useEffect(() => {
        (async () => {
            if (hasNextPage && userID) {
                await fetchMore({
                    variables: {
                        first: PER_PAGE,
                        user: userID,
                        after: cursor,
                    },
                });
            }
        })();
    }, [cursor, hasNextPage]);

    useEffect(() => {
        if (!notificationsLoading && notificationsData) {
            let count = 0;
            const newNotifications = notificationsData?.notifications?.edges?.map((el) => {
                count = el?.node?.unReedCount ?? 0;
                return {
                    id: el?.node?.id ?? "",
                    message: el?.node?.message ?? "",
                    isSeen: el?.node?.isSeen ?? false,
                    date: getNotificationTime(el?.node?.createdAt),
                };
            });
            setNotifications(newNotifications ?? []);
            setUnReedCount(count);
            setNotificationsCursor(notificationsData?.notifications?.pageInfo?.endCursor ?? "");
            setNotificationsHasNextPage(notificationsData?.notifications?.pageInfo?.hasNextPage ?? false);
        }
    }, [notificationsLoading, notificationsData]);

    useEffect(() => {
        if (notificationsHasNextPage) {
            (async () => {
                await fetchMoreNotifications({
                    variables: {
                        first: PER_PAGE,
                        user: userID,
                        after: notificationsCursor,
                    },
                });
            })();
        }
    }, [notificationsCursor, notificationsHasNextPage]);

    function getNotificationTime(date: string | undefined) {
        if (!date) return null;
        const dateTime = new Date(defaultUTCToTimezone(date, timezone)).getTime();
        const now = new Date(defaultUTCToTimezone(new Date().toString(), timezone)).getTime();
        const milliseconds = now - dateTime;
        const days = Math.floor(milliseconds / (24 * 60 * 60 * 1000));
        if (days > 0) return `${days}d`;

        const daysMs = milliseconds % (24 * 60 * 60 * 1000);
        const hours = Math.floor(daysMs / (60 * 60 * 1000));
        if (hours > 0) return `${hours}hr`;

        const hoursMs = milliseconds % (60 * 60 * 1000);
        const minutes = Math.floor(hoursMs / (60 * 1000));
        if (minutes > 0) return `${minutes}m`;

        return "now";
    }

    function handleMessage(event: MessageEvent) {
        const message = event.message;
        if (typeof message === "string" || (message?.text && !message?.onlyForLoggedOut)) {
            refetchNotifications();
        }
    }

    const onNotificationClick = (notification: NotificationType) => {
        if (!notification.isSeen) {
            const newNotifications = notifications.filter((el) => {
                return el.id !== notification.id;
            });
            setNotifications(newNotifications);
            setUnReedCount((prev) => (prev ? prev - 1 : prev));
            removeNotification({
                variables: {
                    input: {
                        id: notification.id,
                    },
                },
            });
        }
    };

    const onNotificationRemove = (id: string) => {
        const newNotifications = notifications.filter((el) => el.id !== id);
        if (newNotifications.length === 0) {
            setShowNotifications(false);
        }
        setNotifications(newNotifications);
        setUnReedCount((prev) => (prev ? prev - 1 : prev));
        removeNotification({
            variables: {
                input: {
                    id,
                },
            },
        });
    };

    const clearAllNotifications = () => {
        notifications.forEach((notification) => {
            const id = notification.id;
            removeNotification({
                variables: {
                    input: {
                        id,
                    },
                },
            });
        });
        setNotifications([]);
        setUnReedCount(0);
    };

    const isDesktop = getDeviceType("desktop");

    const NotificationsPopup = () => {
        return (
            <Container
                className={classNames(css["notifications"], isDesktop && css["notifications-desktop"])}
                width={isDesktop ? 500 : document.body.offsetWidth - 32}
            >
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Button className={"m-2 fs-1"} size={"small"} title="Clear all" onClick={clearAllNotifications} />
                    <Typography bold size={1.2} align="center" className={"m-0"}>
                        Notifications
                    </Typography>
                </div>

                <Container className={classNames(css["notification-items"])}>
                    {notifications.map((el) => {
                        let notification: NotificationMessage | null = null;
                        if (isJson(el.message)) {
                            notification = JSON.parse(el.message);
                        }
                        return (
                            <Container
                                key={`notification-item-${el.id}`}
                                className={classNames(
                                    css["notification-item"],
                                    (!el.isSeen || notification?.url) && "cursor-pointer",
                                    el.isSeen && css["notification-item-seen"]
                                )}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onNotificationClick(el);
                                    if (notification?.url) {
                                        navigate(notification.url);
                                        setShowNotifications(false);
                                    }
                                }}
                            >
                                <Container className={"m-0"}>
                                    {isJson(el.message) ? (
                                        <>
                                            {notification?.title && <Typography bold>{notification?.title}</Typography>}
                                            <Typography className={"m-0"} lineHeight={1.3}>
                                                {notification?.text}
                                                {el.date && (
                                                    <Typography primary size={0.8} tag={"span"} className={"pl-1"}>
                                                        {el.date}
                                                    </Typography>
                                                )}
                                            </Typography>
                                        </>
                                    ) : (
                                        <Typography className={"m-0"} lineHeight={1.3}>
                                            {el.message}
                                            {el.date && (
                                                <Typography primary size={0.8} tag={"span"} className={"pl-1"}>
                                                    {el.date}
                                                </Typography>
                                            )}
                                        </Typography>
                                    )}
                                </Container>

                                <Icon
                                    name={IconName.Cross}
                                    width={16}
                                    height={16}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onNotificationRemove(el.id);
                                    }}
                                    className={"cursor-pointer"}
                                />
                            </Container>
                        );
                    })}
                </Container>
            </Container>
        );
    };

    return (
        <Container className={classNames(css["notification-container"], className)}>
            <Container
                className={classNames(
                    !isDesktop && notifications.length > 0 && "fx-hover",
                    notifications.length > 0 && "cursor-pointer",
                    "m-0"
                )}
                onClick={(e) => {
                    e.stopPropagation();
                    notifications.length > 0 && setShowNotifications((prev) => !prev);
                }}
            >
                <Icon
                    name={IconName.Bell}
                    width={isDesktop ? 30 : 22}
                    height={isDesktop ? 32 : 25}
                    className={classNames()}
                />
                {!isDesktop && (
                    <Typography align={"center"} size={0.7} className={classNames("mb-0 mt-1")}>
                        Inbox
                    </Typography>
                )}
            </Container>
            {!!unReedCount && (
                <Container className={classNames(css["notification-count"])}>
                    <Typography color={"#fff"} className={"m-0 p-0"} size={0.8}>
                        {unReedCount}
                    </Typography>
                </Container>
            )}
            {showNotifications && isDesktop && <NotificationsPopup />}
            <Modal
                title={"Notifications modal"}
                isOpen={showNotifications && !isDesktop}
                onClose={() => setShowNotifications(false)}
                width={document.body.offsetWidth}
            >
                <NotificationsPopup />
            </Modal>
        </Container>
    );
};

export default NotificationIcon;
