import { createContext } from "react";

export interface LiveKitContextProps {
    mediaEnable: boolean;
    updateMediaEnable: (isEnable: boolean) => void;
}

export const LiveKitContext = createContext<LiveKitContextProps>({
    mediaEnable: false,
    updateMediaEnable: (_: boolean) => {
        /* Stub! */
    },
});
