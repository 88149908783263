import { removeLocalStorageItem } from "@fitneks-commons/helpers/localstorage";

export const validateEmail = (email: string) => {
    const validRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return email.match(validRegex);
};

export function isGuidV6(value: string) {
    const regex = new RegExp(/^([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})$/i);
    return regex.test(value);
}

export function isJson(value: string) {
    try {
        JSON.parse(value);
    } catch {
        return false;
    }
    return true;
}

export function clearStreamData() {
    Object.keys(localStorage).forEach(function (key) {
        if (
            key === "stream_token" ||
            key.startsWith("cm_") ||
            key.startsWith("st_") ||
            key.startsWith("ll_") ||
            key.startsWith("qc_") ||
            key.startsWith("ss_") ||
            key.startsWith("cp_")
        ) {
            removeLocalStorageItem(key);
        }
    });
}
