/* eslint-disable */
/* tslint:disable */
/* THIS IS A GENERATED FILE, DO NOT EDIT. */
import * as Types from '@fitneks-types/Graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPaymentDataQueryVariables = Types.Exact<{
  user: Types.Scalars['ID'];
}>;


export type GetPaymentDataQuery = { readonly user?: { readonly id: string, readonly payPalEmail?: string | null } | null };

export type UpdatePaymentDataMutationVariables = Types.Exact<{
  input: Types.UpdateUserInput;
}>;


export type UpdatePaymentDataMutation = { readonly updateUser?: { readonly user?: { readonly id: string } | null } | null };


export const GetPaymentDataDocument = gql`
    query GetPaymentData($user: ID!) {
  user(id: $user) {
    id
    payPalEmail
  }
}
    `;

/**
 * __useGetPaymentDataQuery__
 *
 * To run a query within a React component, call `useGetPaymentDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentDataQuery({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useGetPaymentDataQuery(baseOptions: Apollo.QueryHookOptions<GetPaymentDataQuery, GetPaymentDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentDataQuery, GetPaymentDataQueryVariables>(GetPaymentDataDocument, options);
      }
export function useGetPaymentDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentDataQuery, GetPaymentDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentDataQuery, GetPaymentDataQueryVariables>(GetPaymentDataDocument, options);
        }
export type GetPaymentDataQueryHookResult = ReturnType<typeof useGetPaymentDataQuery>;
export type GetPaymentDataLazyQueryHookResult = ReturnType<typeof useGetPaymentDataLazyQuery>;
export type GetPaymentDataQueryResult = Apollo.QueryResult<GetPaymentDataQuery, GetPaymentDataQueryVariables>;
export const UpdatePaymentDataDocument = gql`
    mutation updatePaymentData($input: updateUserInput!) {
  updateUser(input: $input) {
    user {
      id
    }
  }
}
    `;
export type UpdatePaymentDataMutationFn = Apollo.MutationFunction<UpdatePaymentDataMutation, UpdatePaymentDataMutationVariables>;

/**
 * __useUpdatePaymentDataMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentDataMutation, { data, loading, error }] = useUpdatePaymentDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePaymentDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePaymentDataMutation, UpdatePaymentDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePaymentDataMutation, UpdatePaymentDataMutationVariables>(UpdatePaymentDataDocument, options);
      }
export type UpdatePaymentDataMutationHookResult = ReturnType<typeof useUpdatePaymentDataMutation>;
export type UpdatePaymentDataMutationResult = Apollo.MutationResult<UpdatePaymentDataMutation>;
export type UpdatePaymentDataMutationOptions = Apollo.BaseMutationOptions<UpdatePaymentDataMutation, UpdatePaymentDataMutationVariables>;