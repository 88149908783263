import { AuthContextProps } from "@fitneks-providers";
import refreshToken from "./refreshToken";

/**
 * Creates custom fetch client for Apollo Client.
 * It will sniff requests made to /graphql endpoint
 * and will add Authorization: Bearer token to all requests.
 * In case of JWT expiration will try to renew it.
 * If token renewal completes successfully it will retry the latest
 * request with updated JWT.
 *
 * @param {AuthContextProps} authState Auth state from AuthProvider
 */
export default function createCustomFetch(authState: AuthContextProps) {
    return async (input: RequestInfo | URL, init?: RequestInit | undefined) => {
        /**
         * Check if Authorization Header needed/can be set.
         */
        if (init && input === import.meta.env.FITNEKS_GRAPHQL_URL && authState.accessToken) {
            init.headers = addAuthHeader(authState.accessToken, init.headers);
        }

        const bodyJson = await fetch(input, init)
            .then((response) => {
                if (!response.ok) throw response;
                return response.json();
            })
            .catch((error) => {
                //Here is still promise
                if (error.status === 401) {
                    authState.onLogout();
                }
            });

        if (bodyJson.code === 401 && bodyJson.message === "Expired JWT Token" && authState.refreshToken) {
            try {
                const { token, refresh_token } = await refreshToken(authState.refreshToken);
                authState.onLogin(token, refresh_token);
                return fetch(input, init);
            } catch (e) {
                authState.onLogout();
            }
        }

        return new Response(JSON.stringify(bodyJson), init);
    };
}

/**
 * Add provided Authorization header with provided token.
 *
 * @param {string} accessToken
 * @param {HeadersInit} headers
 */
function addAuthHeader(accessToken: string, headers: HeadersInit | undefined): HeadersInit {
    const authKey = "Authorization";
    const authValue = `Bearer ${accessToken}`;

    if (Array.isArray(headers)) {
        let added = false;
        headers.map(([key, value]) => {
            if (key === authKey) {
                value = authValue;
                added = true;
            }
            return [key, value];
        });
        if (!added) {
            headers.push([authKey, authValue]);
        }
        return headers;
    }
    if (typeof headers === "undefined") {
        return { [authKey]: authValue };
    }

    return { ...headers, [authKey]: authValue };
}
