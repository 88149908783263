import { lazy } from "react";
import { isTrainer } from "@fitneks-commons/helpers/functions/UserRoles";

const AppRoutes = {
    index: {
        path: "/",
        element: lazy(() => import("@fitneks-pages/Landing/Landing")),
        route() {
            if (isTrainer(localStorage.getItem("role"))) {
                return "/dashboard/my-profile";
            }
            return this.path;
        },
    },
    search: {
        path: "/search",
        element: lazy(() => import("@fitneks-pages/Search/Search")),
        route() {
            return this.path;
        },
    },
    searchWithPhrase: {
        path: "/search?term=:searchPhrase",
        element: lazy(() => import("@fitneks-pages/Search/Search")),
        route(searchPhrase: string) {
            return this.path.replace(":searchPhrase", searchPhrase);
        },
    },
    login: {
        path: "/login",
        element: lazy(() => import("@fitneks-pages/Login/Login")),
        route() {
            return this.path;
        },
    },
    loginWithRedirect: {
        path: "/login?redirectPath=:path",
        element: lazy(() => import("@fitneks-pages/Login/Login")),
        route(redirectTo: string) {
            return this.path.replace(":path", redirectTo);
        },
    },
    registerWithPromoCode: {
        path: "/register?promotion_code=:code",
        element: lazy(() => import("@fitneks-pages/Register/Register")),
        route(code: string) {
            return this.path.replace(":code", code);
        },
    },
    register: {
        path: "/register",
        element: lazy(() => import("@fitneks-pages/Register/Register")),
        route() {
            return this.path;
        },
    },
    forgotPassword: {
        path: "/forgot-password",
        element: lazy(() => import("@fitneks-pages/ForgotPassword/ForgotPassword")),
        route() {
            return this.path;
        },
    },
    resetPassword: {
        path: "/reset-password",
        element: lazy(() => import("@fitneks-pages/ResetPassword/ResetPassword")),
        route() {
            return this.path;
        },
    },
    dashboard: {
        path: "/dashboard",
        element: lazy(() => import("@fitneks-pages/Dashboard/Dashboard")),
        route() {
            return this.path;
        },
        children: {
            myProfile: {
                path: "/dashboard/my-profile",
                element: lazy(() => import("@fitneks-dashboard-pages/MyProfile/MyProfile")),
                route() {
                    return this.path;
                },
            },
            giftExchange: {
                path: "/dashboard/gift-exchange",
                element: lazy(() => import("@fitneks-dashboard-pages/GiftExchange/GiftExchange")),
                route() {
                    return this.path;
                },
            },
            calendar: {
                path: "/dashboard/calendar",
                element: lazy(() => import("@fitneks-dashboard-pages/Calendar/Calendar")),
                route() {
                    return this.path;
                },
            },
            friends: {
                path: "/dashboard/friends",
                element: lazy(() => import("@fitneks-dashboard-pages/Friends/Friends")),
                route() {
                    return this.path;
                },
            },
            payment: {
                path: "/dashboard/payment",
                element: lazy(() => import("@fitneks-dashboard-pages/Payment/Payment")),
                route() {
                    return this.path;
                },
            },
            settings: {
                path: "/dashboard/settings",
                element: lazy(() => import("@fitneks-dashboard-pages/Settings/Settings")),
                route() {
                    return this.path;
                },
            },
            feedback: {
                path: "/dashboard/feedback",
                element: lazy(() => import("@fitneks-dashboard-pages/Feedback/Feedback")),
                route() {
                    return this.path;
                },
            },
            helpAndSupport: {
                path: "/dashboard/help-and-support",
                element: lazy(() => import("@fitneks-dashboard-pages/HelpAndSupport/HelpAndSupport")),
                route() {
                    return this.path;
                },
            },
        },
    },
    feedback: {
        path: "/feedback",
        element: lazy(() => import("@fitneks-pages/FeedbackPublic/FeedbackPublic")),
        route() {
            return this.path;
        },
    },
    privacyPolicy: {
        path: "/privacy-policy",
        element: lazy(() => import("@fitneks-pages/PrivacyPolicy/PrivacyPolicy")),
        route() {
            return this.path;
        },
    },
    termsOfSale: {
        path: "/terms-of-sale",
        element: lazy(() => import("@fitneks-pages/TermsOfSale/TermsOfSale")),
        route() {
            return this.path;
        },
    },
    termsOfUse: {
        path: "/terms-and-conditions",
        element: lazy(() => import("@fitneks-pages/TermsOfUse/TermsOfUse")),
        route() {
            return this.path;
        },
    },
    onboarding: {
        path: "/onboarding",
        element: lazy(() => import("@fitneks-pages/Onboarding/Onboarding")),
        route() {
            return this.path;
        },
    },
    helpAndSupport: {
        path: "/help-and-support",
        element: lazy(() => import("@fitneks-pages/HelpAndSupportPublic/HelpAndSupportPublic")),
        route() {
            return this.path;
        },
    },
    onboardingWithRedirect: {
        path: "/onboarding?redirectPath=:path",
        element: lazy(() => import("@fitneks-pages/Onboarding/Onboarding")),
        route(redirectTo: string) {
            return this.path.replace(":path", redirectTo);
        },
    },
    editProfile: {
        path: "/edit-profile",
        element: lazy(() => import("@fitneks-pages/EditProfile/EditProfile")),
        route() {
            return this.path;
        },
    },
    editProfileWithRedirect: {
        path: "/edit-profile?redirectPath=:path",
        element: lazy(() => import("@fitneks-pages/EditProfile/EditProfile")),
        route(redirectTo: string) {
            return this.path.replace(":path", redirectTo);
        },
    },
    userProfile: {
        path: "/user/:username",
        element: lazy(() => import("@fitneks-pages/UserProfile/UserProfile")),
        route(username: string) {
            return this.path.replace(":username", username);
        },
    },
    createStream: {
        path: "/create/:videoStreamType",
        element: lazy(() => import("@fitneks-pages/VideoStream/pages/Create/Create")),
        route(videoStreamType: string) {
            return this.path.replace(":videoStreamType", videoStreamType);
        },
    },
    editStream: {
        path: "/edit/:guid",
        element: lazy(() => import("@fitneks-pages/VideoStream/pages/Edit/Edit")),
        route(guid: string) {
            return this.path.replace(":guid", guid);
        },
    },
    editStreamWithRedirect: {
        path: "/edit/:guid?redirectPath=:path",
        element: lazy(() => import("@fitneks-pages/VideoStream/pages/Edit/Edit")),
        route(guid: string, redirectTo: string) {
            return this.path.replace(":guid", guid).replace(":path", redirectTo);
        },
    },
    viewStream: {
        path: "/trainer/:username",
        element: lazy(() => import("@fitneks-pages/VideoStream/pages/Learner")),
        route(username: string) {
            return this.path.replace(":username", username);
        },
    },
    doingStream: {
        path: "/streaming",
        element: lazy(() => import("@fitneks-pages/VideoStream/pages/Trainer/Trainer")),
        route() {
            return this.path;
        },
    },
    startStream: {
        path: "/streaming/:guid",
        element: lazy(() => import("@fitneks-pages/VideoStream/pages/Trainer/Trainer")),
        route(guid: string) {
            return this.path.replace(":guid", guid);
        },
    },
    liveChallenges: {
        path: "/live-challenges",
        element: lazy(() => import("@fitneks-pages/VideoStream/pages/LiveChallenges/LiveChallenges")),
        route() {
            return this.path;
        },
    },
    verification: {
        path: "/verification",
        element: lazy(() => import("@fitneks-pages/Verification/Verification")),
        route() {
            return this.path;
        },
    },
    noMatch: {
        path: "*",
        element: lazy(() => import("@fitneks-pages/NoMatch/NoMatch")),
        route() {
            return "/404";
        },
    },
};

export default AppRoutes;
