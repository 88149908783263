/* eslint-disable */
/* tslint:disable */
/* THIS IS A GENERATED FILE, DO NOT EDIT. */
import * as Types from '@fitneks-types/Graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SendAbuseMutationVariables = Types.Exact<{
  input: Types.CreateFeedbackInput;
}>;


export type SendAbuseMutation = { readonly createFeedback?: { readonly feedback?: { readonly id: string } | null } | null };


export const SendAbuseDocument = gql`
    mutation SendAbuse($input: createFeedbackInput!) {
  createFeedback(input: $input) {
    feedback {
      id
    }
  }
}
    `;
export type SendAbuseMutationFn = Apollo.MutationFunction<SendAbuseMutation, SendAbuseMutationVariables>;

/**
 * __useSendAbuseMutation__
 *
 * To run a mutation, you first call `useSendAbuseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendAbuseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendAbuseMutation, { data, loading, error }] = useSendAbuseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendAbuseMutation(baseOptions?: Apollo.MutationHookOptions<SendAbuseMutation, SendAbuseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendAbuseMutation, SendAbuseMutationVariables>(SendAbuseDocument, options);
      }
export type SendAbuseMutationHookResult = ReturnType<typeof useSendAbuseMutation>;
export type SendAbuseMutationResult = Apollo.MutationResult<SendAbuseMutation>;
export type SendAbuseMutationOptions = Apollo.BaseMutationOptions<SendAbuseMutation, SendAbuseMutationVariables>;