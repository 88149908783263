import { Button, Col, Container, Modal, Row, Separator, Shimmer, Typography } from "@fitneks-component-library";
import {
    GamificationItem,
    GamificationItemCost,
    GamificationItemCosts,
    GetRubyPurchaseModalItemsDocument,
    GetRubyPurchaseModalItemsQuery,
    RubyPurchaseModalProps,
    useGetRubyExchangeRatesLazyQuery,
    useGetRubyPurchaseModalItemsLazyQuery,
    usePurchaseRubyMutation,
} from "@fitneks-commons/ui";
import css from "./RubyPurchaseModal.module.scss";
import classNames from "@fitneks-commons/classnames";
import { useAuth } from "@fitneks-commons/hooks";
import { useContext, useEffect, useState } from "react";
import { Query } from "@fitneks-types/Graphql.generated";
import { ThemeContext } from "@fitneks-providers";
import { isTrainer } from "@fitneks-commons/helpers/functions/UserRoles";
import { PayPalButtons } from "@paypal/react-paypal-js";
import PerPage from "@fitneks-commons/constants/PerPage";
import { PaymentLogsDocument } from "@fitneks-dashboard-pages/Payment/organisms/PaymentTrainer/organisms/PaymentLogs/PaymentLogs.generated";
import { paymentLogsMonthRange } from "@fitneks-dashboard-pages/Payment/organisms/PaymentTrainer/organisms";

const RubyPurchaseModal = ({ isOpen, setIsOpen, onPurchase, isStreamPage }: RubyPurchaseModalProps) => {
    const { userID, role, country } = useAuth();
    const { addNotification } = useContext(ThemeContext);

    if (!userID) {
        return <></>;
    }

    const FAKE_RATES_COUNT = 8;

    const [ruby, setRuby] = useState<GamificationItem>();
    const [active, setActive] = useState<GamificationItemCost | null>(null);
    const [rates, setRates] = useState<GamificationItemCosts>(Array(FAKE_RATES_COUNT).fill(undefined));
    const [paypalModal, setPaypalModal] = useState(false);

    const [getRubyPurchaseItems, { data: items, loading: itemsLoading, client }] =
        useGetRubyPurchaseModalItemsLazyQuery({
            variables: {
                input: userID,
            },
        });

    const [purchaseRubyMutation, { loading: purchaseLoading }] = usePurchaseRubyMutation({
        onCompleted: (response) => {
            const newLogs = response?.createPurchaseRubyOrder?.order?.gamificationItemLogs;
            if (onPurchase) {
                onPurchase(newLogs as Query["gamificationItemLogs"]);
            }
            setIsOpen(false);
            setActive(null);
            setRuby(undefined);
            addNotification({
                message: "Purchased successfully.",
                type: "success",
                close: "timeOut",
                duration: 3000,
            });
            if (newLogs?.edges?.length && isTrainer(role)) {
                updatePaymentsLogs(newLogs.edges[0]);
            }
        },
        onError: () => {
            addNotification({
                message: "Something went wrong, please try again.",
                type: "error",
                close: "timeOut",
                duration: 3000,
            });
        },
    });

    useEffect(() => {
        if (isOpen) {
            (async () => {
                await getRubyPurchaseItems();
            })();
        }
    }, [isOpen]);

    const [fetchRates, { data: costs, loading: ratesLoading }] = useGetRubyExchangeRatesLazyQuery();

    useEffect(() => {
        if (!itemsLoading && isOpen) {
            items?.gamificationItemLogCounts
                ?.filter((item) => item?.gamificationItem?.isRuby && item?.gamificationItem?.enabled)
                ?.forEach((item) => {
                    setRuby(item);
                    if (item?.gamificationItem?.name) {
                        const name = item.gamificationItem.name;
                        (async () => {
                            await fetchRates({ variables: { input: name } });
                        })();
                    }
                });
        }
    }, [items?.toString(), itemsLoading, isOpen]);

    useEffect(() => {
        if (costs) {
            const newRates = [...(costs.gamificationItemCosts ?? [])];
            setRates(newRates);
        }
    }, [costs]);

    function updatePaymentsLogs(log: unknown) {
        client.cache.updateQuery(
            {
                query: PaymentLogsDocument,
                variables: {
                    user: userID,
                    first: PerPage.max,
                    datetime: paymentLogsMonthRange,
                },
            },
            (data) => {
                if (!data) return data;

                return {
                    gamificationItemLogs: {
                        ...data.gamificationItemLogs,
                        edges: [log, ...data.gamificationItemLogs.edges],
                    },
                };
            }
        );
    }

    const onCheckout = () => {
        if (active?.count) {
            setPaypalModal(true);
        }
    };

    // creates a PayPal order
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const createOrder = (data, actions) => {
        return actions.order
            .create({
                purchase_units: [
                    {
                        description: "ruby_purchase",
                        amount: {
                            currency_code: country === "Canada" ? "CAD" : "USD",
                            value: +(active?.cost as string),
                        },
                    },
                ],
                // not needed if a shipping address is actually needed
                application_context: {
                    shipping_preference: "NO_SHIPPING",
                },
            })
            .then((orderID: string) => {
                return orderID;
            });
    };

    // check Approval
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const onApprove = (data, actions) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return actions.order.capture().then(function (details) {
            setPaypalModal(false);
            purchaseRubyMutation({
                variables: {
                    input: {
                        orderId: details.id,
                        datetime: new Date().toUTCString(),
                    },
                },
            }).then((res) => {
                client.cache.updateQuery(
                    {
                        query: GetRubyPurchaseModalItemsDocument,
                        variables: {
                            input: userID,
                        },
                    },
                    (data) => {
                        let count = 0;
                        res.data?.createPurchaseRubyOrder?.order?.gamificationItemLogs?.edges?.forEach((el) => {
                            if (el?.node?.gamificationItem?.isRuby) {
                                count = el?.node?.count;
                            }
                        });

                        const gamificationItemLogCounts: GetRubyPurchaseModalItemsQuery["gamificationItemLogCounts"] =
                            data.gamificationItemLogCounts;

                        return {
                            gamificationItemLogCounts: gamificationItemLogCounts?.map((el) => {
                                if (el?.gamificationItem?.isRuby) {
                                    return {
                                        ...el,
                                        count: el.count + count,
                                    };
                                }
                                return el;
                            }),
                        };
                    }
                );
            });
        });
    };

    //capture likely error
    const onError = () => {
        setPaypalModal(false);
        addNotification({
            message: "An Error occurred with your payment.",
            type: "error",
            close: "timeOut",
            duration: 6000,
        });
    };

    return (
        <>
            <Modal
                isOpen={isOpen}
                title={"Rubies"}
                width={940}
                overlay={isStreamPage ? "dark" : "light"}
                shadow
                onClose={() => {
                    setIsOpen(false);
                    setActive(null);
                    setRuby(undefined);
                }}
                bottomFixed={!!isStreamPage}
                className={classNames(isStreamPage && css["is-stream-page"], isStreamPage && "t-white")}
            >
                <Container
                    border={isStreamPage ? 0 : 1}
                    className={classNames(
                        isStreamPage && "t-white",
                        !isStreamPage && "bg-gray",
                        isStreamPage && "bg-black",
                        "px-4 py-4"
                    )}
                >
                    <Typography tag={"h2"} align={"center"} className={"mb-1"}>
                        Purchase Rubies
                    </Typography>
                    <Typography align={"center"} className={"mb-3"}>
                        <img
                            className={"mr-1"}
                            width={25}
                            src={ruby?.gamificationItem?.iconMediaObject?.filePath || ""}
                            alt={ruby?.gamificationItem?.title}
                        />
                        Balance: {ruby?.count}
                    </Typography>
                    <Row gapX={15} gapY={15}>
                        {rates?.map((rate, idx) => {
                            return (
                                <Col key={rate?.count || idx.toString()} xs={isStreamPage ? 3 : 6} md={3}>
                                    <Shimmer visible={ratesLoading || !costs} position="unset" borderRadius>
                                        <Container
                                            shadow
                                            radius
                                            border={isStreamPage ? 1 : 3}
                                            className={classNames(
                                                css["selection-container"],
                                                rate?.id === active?.id && css["is-active"],
                                                "py-3 px-1 cursor-pointer",
                                                !isStreamPage && "bg-white py-5",
                                                isStreamPage && "t-muted"
                                            )}
                                            onClick={() => {
                                                setActive(rate);
                                            }}
                                        >
                                            <Container
                                                className={classNames(
                                                    isStreamPage && "t-white",
                                                    "d-flex align-items-center justify-content-center"
                                                )}
                                            >
                                                <img
                                                    className={"mr-1 mb-1"}
                                                    src={ruby?.gamificationItem?.iconMediaObject?.filePath || ""}
                                                    width={isStreamPage ? 20 : 51}
                                                    alt={ruby?.gamificationItem?.title}
                                                />
                                                <Typography className={"mb-0"} size={isStreamPage ? 1 : 2}>
                                                    {rate?.count}
                                                </Typography>
                                            </Container>
                                            <Typography
                                                align={"center"}
                                                className={classNames(isStreamPage && "t-white", "mb-0")}
                                            >
                                                ${rate?.cost}
                                            </Typography>
                                        </Container>
                                    </Shimmer>
                                </Col>
                            );
                        })}
                    </Row>
                    <Separator className={classNames(isStreamPage && "t-muted", "mt-4 mb-4")} />
                    {active && (
                        <Container width={500}>
                            <Row>
                                <Col>Qnt: {active?.count} </Col>
                                <Col className={"d-flex justify-content-between"}>
                                    <Typography bold size={1.1} className={"t-ruby"}>
                                        TOTAL:
                                    </Typography>
                                    <Typography bold size={1.1} className={"t-ruby"}>
                                        ${active?.cost}
                                    </Typography>
                                </Col>
                            </Row>
                        </Container>
                    )}
                    <Container width={isStreamPage ? 500 : 260}>
                        <Shimmer visible={ratesLoading || !costs} position="unset" borderRadius>
                            <Button
                                title={purchaseLoading ? "IN PROGRESS..." : "CHECK OUT"}
                                style={"primary"}
                                fullWidth
                                className={"red-gradient fw-700"}
                                onClick={onCheckout}
                                disabled={purchaseLoading}
                            />
                        </Shimmer>
                    </Container>
                </Container>
            </Modal>
            <Modal title={"paypal"} isOpen={paypalModal} onClose={() => setPaypalModal(false)} width={400}>
                <Container className={"p-5"}>
                    <Typography bold tag={"h3"} className={"mb-4"}>
                        Choose payment method
                    </Typography>
                    <PayPalButtons
                        style={{ layout: "horizontal" }}
                        createOrder={createOrder}
                        onApprove={onApprove}
                        onError={onError}
                        className={"mb-4"}
                    />
                    <PayPalButtons
                        style={{ layout: "horizontal" }}
                        createOrder={createOrder}
                        onApprove={onApprove}
                        onError={onError}
                        fundingSource={"card"}
                    />
                </Container>
            </Modal>
        </>
    );
};

export default RubyPurchaseModal;
