import { useContext } from "react";
import { Icon, IconName, Separator, Typography } from "@fitneks-component-library";
import AppRoutes from "@fitneks-routes";
import { Link, useLocation } from "react-router-dom";
import { ThemeContext } from "@fitneks-providers";
import classNames from "@fitneks-commons/classnames";
import "@fitneks-commons/styles/flex.scss";
import "@fitneks-commons/styles/display.scss";
import css from "./PublicNavigation.module.scss";

export default function PublicNavigation() {
    const { updateOffCanvas } = useContext(ThemeContext);
    const pathname = useLocation().pathname;

    return (
        <>
            <nav className={classNames(css["profile-navigation"])}>
                <div style={{
                    display: "flex",
                }}>
                    <Link
                        to={AppRoutes.dashboard.children.settings.route()}
                        className={classNames(
                            css["profile-navigation-item"],
                            pathname === AppRoutes.dashboard.children.settings.route() && css["is-active"],
                            "d-flex align-items-center"
                        )}
                        onClick={() => updateOffCanvas("off")}
                    >
                        <Icon name={IconName.Settings} width={30} />
                        Settings
                    </Link>
                    <button style={{
                        border: "0px",
                        cursor: "pointer",
                        paddingTop: "13px",
                        paddingRight: "25px",
                        position: "absolute",
                        right: "-10px",
                        background: "none",
                        fontSize: "26px",
                    }}
                            onClick={() => updateOffCanvas("off")}>
                        ⛌
                    </button>
                </div>
                <Separator className={classNames(css["profile-navigation-sep"])} />
                <Link
                    to={AppRoutes.feedback.route()}
                    className={classNames(
                        css["profile-navigation-item"],
                        pathname === AppRoutes.feedback.route() && css["is-active"],
                        "d-flex align-items-center"
                    )}
                    onClick={() => updateOffCanvas("off")}
                >
                    <Icon name={IconName.Star} width={30} />
                    Feedback
                </Link>
                <Link
                    to={AppRoutes.helpAndSupport.route()}
                    className={classNames(
                        css["profile-navigation-item"],
                        pathname === AppRoutes.helpAndSupport.route() && css["is-active"],
                        "d-flex align-items-center"
                    )}
                    onClick={() => updateOffCanvas("off")}
                >
                    <Icon name={IconName.Help} width={30} />
                    Help & Support
                </Link>
                {/*<Link*/}
                {/*    to={AppRoutes.termsAndConditions.route()}*/}
                {/*    className={classNames(*/}
                {/*        css["profile-navigation-item"],*/}
                {/*        pathname === AppRoutes.termsAndConditions.route() && css["is-active"],*/}
                {/*        "d-flex align-items-center"*/}
                {/*    )}*/}
                {/*    onClick={() => updateOffCanvas("off")}*/}
                {/*>*/}
                {/*    <Icon name={IconName.User} width={30} />*/}
                {/*    Terms & Conditions*/}
                {/*</Link>*/}
                <Separator className={classNames(css["profile-navigation-sep"])} />
                <Link
                    to={AppRoutes.register.route()}
                    className={classNames(
                        css["profile-navigation-item"],
                        pathname === AppRoutes.dashboard.children.helpAndSupport.route() && css["is-active"],
                        "d-flex align-items-center"
                    )}
                    onClick={() => updateOffCanvas("off")}
                >
                    SIGN UP
                </Link>
                <Link
                    to={AppRoutes.login.route()}
                    className={classNames(
                        css["profile-navigation-item"],
                        pathname === AppRoutes.dashboard.children.helpAndSupport.route() && css["is-active"],
                        "d-flex align-items-center"
                    )}
                    onClick={() => updateOffCanvas("off")}
                >
                    LOG IN
                </Link>
                <Separator className={classNames(css["profile-navigation-sep"], "d-block d-lg-none")} />
                <Typography className={"mb-1"}>
                    <Link
                        to={AppRoutes.privacyPolicy.path}
                        className={classNames(
                            css["profile-navigation-item"],
                            pathname === AppRoutes.dashboard.children.helpAndSupport.route() && css["is-active"],
                            "align-items-center d-flex d-lg-none"
                        )}
                        onClick={() => updateOffCanvas("off")}
                    >
                        Privacy Policy
                    </Link>
                </Typography>
                <Typography>
                    <Link
                        to={AppRoutes.termsOfUse.path}
                        className={classNames(
                            css["profile-navigation-item"],
                            pathname === AppRoutes.dashboard.children.helpAndSupport.route() && css["is-active"],
                            "align-items-center  d-flex d-lg-none"
                        )}
                        onClick={() => updateOffCanvas("off")}
                    >
                        Terms Of Service
                    </Link>
                </Typography>
            </nav>
        </>
    );
}
