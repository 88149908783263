export const getMuscleColor = (name: string | undefined) => {
    switch (name) {
        case "Arms":
            return "#EA6C6C";
        case "Chest":
            return "#6274FF";
        case "Back":
            return "#88D66B";
        case "Abs":
            return "#9E79F2";
        case "Legs":
            return "#FF9262";
        case "Chlng":
            return "#001BEA";
        default:
            return "#EA6C6C";
    }
};
